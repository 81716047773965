<template>
  <a-dialog-window :value="value">
    <template v-slot:title>Входящий звонок: {{ phone }}</template>
    <template v-slot:actions>
      <v-spacer></v-spacer>
      <v-btn @click="$emit('answer')" color="primary" class="ma-1"> Ответить </v-btn>
      <v-btn @click="closeWindow()" color="error" class="ma-1"> Отклонить </v-btn>
    </template>
    <div v-if="data.id">
      <a-form-view :value="data" :model="[model[0], model[1], model[2], model[3], model[4], model[5], model[6]]" :config="{}" />
      <div class="a-btn-group">
        <v-btn color="primary" @click="$router.push({ name: 'clients_view', params: { id: data.id } })"> Открыть </v-btn>
        <v-btn color="primary" @click="showEditDialogFun(data.id)"> Редактировать </v-btn>
      </div>
    </div>
    <div v-else>
      <div class="mb-10"><h2>Клиент не найден</h2></div>
      <div class="a-btn-group">
        <v-btn color="primary" @click="createNew()"> Создать нового клиента </v-btn>
      </div>
    </div>
    <s-edit-form
      v-model="showEditDialog"
      v-if="showEditDialog"
      :m="configH.m"
      :configForm="{ head: configH.head, title: configH.title, api: configH.api }"
      :id="configH.id"
      :opt="configH.opt"
      :initData="configH.initData || {}"
    />
  </a-dialog-window>
</template>

<script>
export default {
  components: {},
  props: {
    value: Boolean,
    canCall: Boolean,
    phone: String,
  },
  data() {
    return {
      configH: null,
      idEdit: 0,
      showEditDialog: false,
      loading: false,
      url: "/mechti/leads",
      m: this.$store.getters["config/get"].models.leads,
      model: this.$store.getters["config/get"].models.leads.form,
      data: {},
    };
  },
  watch: {
    phone: {
      immediate: true,
      handler() {
        this.fetchData();
      },
    },
  },
  computed: {},
  methods: {
    closeWindow() {
      this.$emit("input");
      this.$emit("end-call");
    },
    async fetchData() {
      this.data = {};
      if (!this.phone) return;
      this.loading = true;
      let res = await this.$axios.get(this.url, {
        params: {
          sort: { key: "id", order: "desc" },
          filters: {},
        },
      });
      console.log("income ", res, this.phone);
      if (res.data.data && res.data.data.length > 0) {
        this.data = res.data.data[0];
      }
      this.loading = false;
    },
    createNew() {
      this.showEditDialogFun(0);
    },
    showEditDialogFun(id) {
      const config = {};
      config.type = "lead";
      this.configH = null;
      config.head = this.m.config.default || {};
      config.title = this.m.config.default.title || {};
      config.api = this.url;
      config.id = id;
      config.opt = { delete: true };
      config.m = this.m;
      this.configH = config;
      this.showEditDialog = true;
    },
  },
};
</script>
