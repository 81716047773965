/** @format */
const autocomplete = true;
const accReportAccount = {
  title: 'Настройки для отчета по счетам',
  accessName: 'c',
  api: '',

  typeReport: [
    { value: '60', text: 'Расчеты с поставщиками' },
    { value: '71', text: 'Расчеты с подотчетными лицам' },
  ],
  form: [{ name: 'type', title: 'Счет для отчета', type: 'select', sourceList: 'typeReport', validator: ['req'] }],
  config: {
    60: {
      form: [
        { name: 'subconto_1', title: 'Поставщик', type: 'select', dir: 'vendor', autocomplete },
        { name: 'show_zero', title: 'Показывать 0', type: 'switcher' },
        { name: 'isSubconto2', title: 'В разрезе документов', type: 'switcher' },
        { name: 'date_begin', title: 'Начало', type: 'date' },
        { name: 'date_end', title: 'Конец', type: 'date' },
      ],
      title: 'Расчеты с поставщиками',
      fields: 'subconto_1,show_zero,isSubconto2,date_begin,date_end',
      fieldsRO: '',
      fieldsForm: 'subconto_1,show_zero,isSubconto2',
    },
    71: {
      form: [
        { name: 'subconto_1', title: 'Подотчетное лицо', type: 'select', dir: 'employee' },
        { name: 'show_zero', title: 'Показывать 0', type: 'switcher' },
        { name: 'date_begin', title: 'Начало', type: 'date' },
        { name: 'date_end', title: 'Конец', type: 'date' },
      ],
      title: 'Расчеты с подотчетными лицам',
      fields: 'subconto_1,show_zero,isSubconto2',
      fieldsRO: '',
      fieldsForm: 'subconto_1,show_zero,isSubconto2',
    },
  },
};
export default accReportAccount;
