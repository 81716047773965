import { render, staticRenderFns } from "./eduMenuItem.vue?vue&type=template&id=315088fc&scoped=true&"
import script from "./eduMenuItem.vue?vue&type=script&lang=js&"
export * from "./eduMenuItem.vue?vue&type=script&lang=js&"
import style0 from "./eduMenuItem.vue?vue&type=style&index=0&id=315088fc&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "315088fc",
  null
  
)

export default component.exports