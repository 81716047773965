/** @format */
const hidden = true;
const editable = true;
const lampObjects = {
  types: [
    { value: 2, text: 'Cайт' },
    { value: 3, text: 'Авито' },
    { value: 4, text: 'Циан' },
  ],
  list: [
    { name: 'id', title: '#', type: 'id', sortable: true, width: 50 },
    { name: 'source', title: 'Источник', type: 'string', sortable: true, width: 200, hidden },
    { name: 'name', title: 'Название', type: 'string', style: 'white-space: normal;', sortable: true, width: 200, link: { field: 'link_ref' } },
    { name: 'city', title: 'Город', type: 'string', sortable: true, width: 100 },
    { name: 'address', title: 'Адрес', type: 'string', sortable: true, width: 300 },
    { name: 'status', title: 'Статус', type: 'select', options: 'config.statuses.lamp_object', sortable: true, width: 100 },
    { name: 'type', title: 'Тип', type: 'select', sortable: true, width: 100, options: 'objectTypes' },
    { name: 'class', title: 'Класс', type: 'select', options: 'objectClasses', sortable: true, width: 100 },
    { name: 'ap_area', title: 'Площадь', type: 'string', sortable: true, width: 65 },
    { name: 'ap_rooms', title: 'Комнат', type: 'string', sortable: true, width: 65 },
    { name: 'ap_ceiling_height', title: 'Потолки', type: 'string', sortable: true, width: 65 },
    { name: 'status_site', title: 'Сайт', type: 'selectbit', sourceList: 'types', sortable: true, width: 100 },
    { name: 'actions', title: 'Действия', width: 100 },
  ],
};
export default lampObjects;
