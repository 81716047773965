/** @format */

const objectReports = {
  list: [ 
    { name: 'id', title: '#', sortable: true, width: 50 },
    { name: 'createdon', title: 'Дата', type: 'date', sortable: true, width: 100 },
    { name: 'description', title: 'Описание', type: 'text', width: 300 },

    // { name: 'name', title: 'Наименование', type: 'string', sortable: true, width: 250 },
    { name: 'photos', title: 'Фото', type: 'image', width: 240, count: 5 },
    { name: 'client_visible', title: 'Показать клиенту', type: 'select', options: 'yesNo', access: { role: [-1, -10] } },
  ],
  listDash: [
    { name: 'id', title: '#', sortable: true, width: 50 },
    { name: 'createdon', title: 'Дата', type: 'datetime', sortable: true, width: 100, style: 'white-space: normal;' },
    { name: 'object_id', title: 'Объект', width: 150, isMenu: true, style: 'white-space: normal;', hidden: true },
    { name: 'object_name', title: 'Объект', type: 'string', width: 120, isMenu: true, style: 'white-space: normal;' },
    { name: 'description', title: 'Описание', type: 'string', width: 200, style: 'white-space: normal;' },
    { name: 'photos', title: 'Фото', type: 'image', width: 240, count: 5, hidden: true },
  ],
  form: [
    //  { name: 'name', title: 'Наименование', validator: ['req'], type: 'string' },
    { name: 'client_visible', title: 'Показать клиенту', type: 'switcher', default: 0, hidden: true },
    { name: 'description', title: 'Описание', type: 'text', validator: ['req'] },
    { name: 'photos', title: 'Галерея', validator: ['req'], type: 'images' },
  ],
};

export default objectReports;
