<template>
  <div class="py-1 pb-3">
    <v-card>
      <v-card-title class="py-2">
        {{ model.title }}
        <v-spacer></v-spacer>
        <div class="text-right pa-1">
          <v-btn v-if="!readonly" class="ma-1" @click="add()" color="primary"> Добавить </v-btn>
        </div>
      </v-card-title>
      <v-card-text>
        <div>
          <v-row>
            <v-simple-table style="width: 100%">
              <tbody>
                <tr v-for="(el, key) in getSortItems" :key="key">
                  <td class="text-left">{{ key }}:{{ el.value }} [{{ el.text }}]</td>
                  <td width="10px">
                    <div class="d-flex">
                      <v-btn v-if="!readonly" class="mx-2" fab dark small color="error" title="Удалить" @click.prevent="remove(key)">
                        <v-icon dark> mdi-close </v-icon>
                      </v-btn>
                      <v-btn v-if="!readonly" class="mx-2" fab dark small color="info" title="Изменить" @click.prevent="edit(key)">
                        <v-icon dark> mdi-pencil </v-icon>
                      </v-btn>
                    </div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialogEdit" hide-overlay persistent max-width="600">
      <v-card>
        <v-card-title>Редактирование</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-8">
          <v-text-field v-model="editItem.value" label="value" :rules="[(value) => !!value || 'Required.']" hide-details="auto"></v-text-field>
          <v-text-field v-model="editItem.text" label="text" :rules="[(value) => !!value || 'Required.']" hide-details="auto"></v-text-field>
          <v-select
            v-if="values.type == 4"
            v-model="editItem.type"
            label="Тип"
            :rules="[(value) => !!value || 'Required.']"
            item-text="text"
            item-value="value"
            :items="[
              { value: 1, text: 'text' },
              { value: 2, text: 'images' },
              { value: 3, text: 'list' },
              { value: 5, text: 'number' },
            ]"
            hide-details="auto"
          />
          <v-text-field v-model="editItem.order" label="order" hide-details="auto"></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="save()" :disabled="!editItem.value || !editItem.text">Сохранить </v-btn>
          <v-btn @click="dialogEdit = false">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: String | Array,
    values: {
      type: Object,
      default: () => {
        return {};
      },
    },
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    config: { type: Object, default: {} },
  },
  data() {
    return {
      dialogEdit: false,
      editKey: 0,
      editItem: { value: "", text: "", order: 0 },
    };
  },
  computed: {
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
    items: {
      get() {
        let d;
        try {
          d = this.value;
        } catch (error) {
          d = [];
        }

        return d || [];
      },
      set(v) {
        this.$emit("input", v);
      },
    },
    getSortItems() {
      return this.items;
      return this.items.sort((a, b) => {
        return a.order > b.order - b.order > a.order;
      });
    },
  },
  methods: {
    remove(key) {
      let e = this.items;
      e.splice(key, 1);
      this.items = e;
    },
    edit(key) {
      this.editKey = key;
      this.editItem = this.items[key];
      this.dialogEdit = true;
    },

    add() {
      this.editKey = -1;
      this.editItem = { value: "", text: "", order: 0 };
      this.dialogEdit = true;
    },
    save() {
      let r = this.editItem;
      let el = this.items;
      //  if (!r?.order) r.order = 0;
      if (!Array.isArray(el)) el = [];
      if (this.editKey > -1) {
        el[this.editKey] = r;
      } else {
        el.push(r);
      }
      this.items = el;
      this.dialogEdit = false;
    },
  },
};
</script>
