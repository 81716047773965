/** @format */
const hidden = true;
const cryptedData = {
  api: '/mechti/crypt_data',

  list: [
    { name: 'id', title: '#', type: 'id', sortable: true, width: 50 },
    { name: 'name', title: 'Название', type: 'string', style: 'white-space: normal;', sortable: true, width: 200 },
    { name: 'createdon', title: 'Дата', type: 'datetime', sortable: true, width: 150 },
    { name: 'createdby_id', title: 'Автор', type: 'select', dir: 'user', width: 150 },
    { name: 'user_id', title: 'Владелец', type: 'select', dir: 'user', api: 'mechti/tasks/users/select', hidden },
    { name: 'status', title: 'Статус', type: 'select', options: 'status_object', sortable: true, width: 100, hidden },
  ],
  form: [
    { name: 'name', title: 'Название', type: 'string', validator: ['req'], autofocus: true },
    { name: 'crypt.content', title: 'Содержимое', type: 'text', hidden },
    { name: 'data' },
    { name: 'access', title: 'Доступ', type: 'selectAccess' },
    { name: 'status', title: 'Статус', type: 'select', options: 'status_object', hidden },
  ],
};
export default cryptedData;
