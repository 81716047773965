/** @format */

const rooms = {
  list: [
    { name: 'id', title: 'id', type: 'id', sortable: true, width: 50 },
    { name: 'name', title: 'Номер', type: 'string', sortable: true, width: 100 },
    { name: 'objects_city', title: 'Город', type: 'string', filter: { name: 'objects.city' }, sortable: true, width: 100 },
    { name: 'objects_address', title: 'Объект', type: 'string', filter: { name: 'objects.address' }, sortable: true, width: 200 },
    { name: 'area', title: 'Площадь', type: 'string', sortable: true, width: 100 },
    { name: 'price', title: 'Стоимость', type: 'string', sortable: true, width: 100 },
    {
      name: 'status',
      title: 'Статус',
      type: 'select',
      options: [
        { value: 1, text: 'продана' },
        { value: 2, text: 'не продана' },
      ],
      sortable: true,
      width: 100,
    },
  ],
  form: [
    { name: 'name', title: 'Номер', type: 'string', validator: ['req'] },
    { name: 'area', title: 'Площадь', type: 'string', validator: ['req'] },
    { name: 'price', title: 'Розничная стоимость', type: 'string', validator: ['req'] },
    { name: 'files', title: 'Проектная документация', type: 'files' },
    { name: 'decription', title: 'Описание', type: 'text' },
    { name: 'images', title: 'Фото галерея', type: 'images' },
    {
      name: 'status',
      title: 'Статус',
      type: 'select',
      options: [
        { value: 1, text: 'продана' },
        { value: 2, text: 'не продана' },
      ],
    },
    { name: 'objects_city', title: 'Город', type: 'string' },
    { name: 'objects_address', title: 'Адрес', type: 'text' },
  ],
};
export default rooms;
