<template>
  <v-icon v-if="link" @mousedown.left.stop="onClickLink"> {{ icon }}</v-icon>
</template>

<script>
export default {
  props: {
    data: { type: Object, default: () => {} },
    values: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data: () => ({}),
  computed: {
    icon() {
      return this.data.icon || "mdi-link";
    },
    link() {
      let res;
      if (this.data.value) return this.data.value;
      if (this.data.field) return this.values?.[this.data.field];
    },
  },
  methods: {
    onClickLink(e) {
      e.preventDefault();

      console.log("fffffffffff", this.data, this.values);
      if (this.link) window.open(this.link);
    },
  },
};
</script>
