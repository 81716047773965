<template>
  <div class="d-flex" style="position: relative">
    <v-combobox
      outlined
      :clearable="!readonly"
      :readonly="readonly"
      hide-selected
      :items="data"
      :value="value"
      :label="model.title"
      :error-messages="error"
      :disabled="disabled"
      :hide-details="config.hideDetails || false"
      :dense="config.dense || false"
      @focus="fetchData()"
      @input="$emit('input', $event)"
      :small-chips="model.chips || false"
      :multiple="model.chips || false"
    >
      <template v-slot:selection="data" v-if="model.chips || false">
        <v-chip v-bind="data.attrs" :input-value="data.selected" :close="config.readonly != true" small @click:close="data.parent.selectItem(data.item)" class="mt-1">
          {{ $t(data.item) }}
        </v-chip>
      </template>
    </v-combobox>

    <btn-rating v-if="(model.rating && config.rating) || false" v-model="rating"> </btn-rating>
  </div>
</template>

<script>
export default {
  props: {
    value: [Array, String, Number, Object],
    values: Object,
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    error: {
      type: String,
      default: "",
    },
    config: { type: Object, default: {} },
  },
  data() {
    return {
      data: [],
    };
  },
  // created() {
  //   this.fetchData();
  // },
  computed: {
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
    rating: {
      get() {
        let res = this.values.data?.rating?.[this.model.name] || "";
        return res;
      },
      set(v) {
        this.$emit("rating", { name: this.model.name, value: v });
      },
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      let response = await this.$axios.get(this.model.api);
      this.loading = false;
      let data = JSON.parse(JSON.stringify(response.data.data));
      let arr;
      const f = this.model?.fields?.value || "value";
      data.forEach((el) => {
        try {
          if (!Array.isArray(JSON.parse(el[f]))) arr = [el[f]];
          else arr = JSON.parse(el[f]);
        } catch (error) {
          arr = [el[f]];
        }
        arr.forEach((a) => {
          if (!this.data.includes(a)) this.data = [...this.data, a];
        });
      });
    },
    onChange(e) {
      console.log(e);
    },
  },
};
</script>
