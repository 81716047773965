/** @format */

export default {
  data() {
    return { counter: 0, clickRow: null, click: undefined, currentRow: null, selectedRows: [] };
  },
  created() {},
  beforeDestroy() {},
  methods: {
    onDetectClick(d) {
      d.event.preventDefault();
      if (this.counter == 1)
        this.click = setTimeout(() => {
          if (this.$root.typeClick > 1) {
            this.clickRow = d.row.id;
            if (this.selectedRows.includes(d.row.id)) {
              this.selectedRows = [];
            } else {
              this.selectedRows = [d.row.id];
            }
            this.onSingleClick(d);
          }
          if (this.$root.typeClick == 1) {
            this.selectedRows = [d.row.id];
            this.onDoubleClick(d);
          }
          this.clickRow = 0;
          this.click = undefined;
          this.counter = 0;
        }, 250);
      if (this.counter == 2) {
        this.counter = 0;
        clearTimeout(this.click);
        this.click = undefined;
        if (this.clickRow === d.row.id || this.$root.typeClick == 1) {
          this.selectedRows = [d.row.id];
          this.onDoubleClick(d);
        }
        this.clickRow = 0;
      }
      this.clickRow = d.row.id;
    },
    onSingleClick(d) {},
    onDoubleClick(d) {},
  },
};
