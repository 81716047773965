<template>
  <div>
    <v-row no-gutters class="mb-2 .flex-nowrap .flex-md-nowrap">
      <v-btn v-if="!isReadonly" icon @click="showLoanFind = !showLoanFind">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <v-col cols="1" class="flex-grow-0 flex-shrink-0" min-width="50">
        <a-input-string class="pr-2" :model="{ name: 'numb', title: '№' }" v-model="data.numb" :config="{ readonly: true, dense: true }" />
      </v-col>
      <v-col cols="0" class="flex-grow-1 flex-shrink-1">
        <a-input-number class="px-2" :model="{ name: 'value', title: 'Сумма (тело)' }" v-model="data.value" :config="{ readonly: true, dense: true }" />
      </v-col>
      <v-col cols="0" class="flex-grow-1 flex-shrink-1">
        <a-input-number class="pr-2" :model="{ name: 'value_percent', title: 'Сумма (%)' }" v-model="data.value_percent" :config="{ readonly: true, dense: true }" />
      </v-col>
      <v-col cols="0" class="flex-grow-1 flex-shrink-0">
        <a-input-number :model="{ name: 'value_commission', title: 'Комиссия' }" v-model="data.value_commission" :config="{ readonly: true, dense: true }" />
      </v-col>
    </v-row>
    <dialog-edit2 v-model="showLoanFind">
      <template v-slot:title> График выплат по кредиту</template>
      <a-loader v-if="loading" />
      <v-card-text style="height: 70vh; overflow: auto" class="pa-0">
        <a-table-f-data2
          ref="tableLoan"
          :dataTable="loan_schedule"
          :model="modelLoan"
          :searchable="false"
          :defaults="{
            filters: {},
            sort1: { key: 'numb', order: 'DESC' },
          }"
          @click="onClickRow($event)"
        />
      </v-card-text>
    </dialog-edit2>
  </div>
</template>

<script>
export default {
  mixins: [],
  props: {
    value: [Object],
    values: {
      type: Object,
      default: () => {
        return {};
      },
    },
    model: {
      type: Object,
      default: () => {
        return {};
      },
    },
    readonly: Boolean,
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    config: { type: Object, default: {}, disabled: false },
  },
  data() {
    return {
      showLoanFind: false,
      data: {},
      loading: false,
      loan_schedule: [],
      modelLoan: [
        { name: "numb", title: "№", type: "number" },
        { name: "date_plan", title: "дата (план)", type: "date" },
        { name: "value", title: "Сумма (тело)", type: "number", default: 0 },
        { name: "value_percent", title: "Сумма (%)", type: "number", type: "number", default: 0 },
        { name: "value_commission", title: "Комиссия", type: "number", default: 0 },
        { name: "date_real", title: "дата (факт)", type: "date" },
      ],
    };
  },
  watch: {
    showLoanFind(v) {
      if (v) {
        this.getLoanSchedule();
      }
    },

    value(v) {
      this.fillForm(v);
    },
  },
  computed: {
    isReadonly() {
      if (this.config?.readonly || this.model?.readonly || this.readonly) return true;
      return false;
    },
  },
  created() {
    this.fillForm(this.value);
  },
  methods: {
    fillForm(v) {
      for (let el of this.modelLoan) {
        let val = v?.[el.name];
        if ((val === undefined || val === null) && el?.default !== undefined) val = el.default;
        try {
          if (el.type == "number") val = parseFloat(val);
        } catch (error) {}
        this.$set(this.data, el.name, val || null);
      }
      //  this.data;
    },
    onClickRow(d) {
      let data = {};
      if (d.row.date_real || d.row?.disabled) {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Выбранна не подходящая запись....",
        });
        return;
      }
      for (let el of this.modelLoan) {
        let val = d.row?.[el.name];
        if ((val === undefined || val === null) && el?.default !== undefined) val = el.default;
        try {
          if (el.type == "number") val = parseFloat(val);
        } catch (error) {}
        this.$set(data, el.name, val || null);
      }
      this.showLoanFind = false;
      this.$emit("input", data);
    },
    async getLoanSchedule() {
      let id; //= this.values?.object_id;
      if (this.values?.parent_name == "AccountingDocGettingLoan") {
        await this.$axios
          .get("accounting/doc/getting_loan/" + this.values?.parent_id)
          .then((resp) => {
            console.log("resp", resp);
            if (resp.data.status == "ok") {
              id = resp.data.data.credit_payment_schedule_id;
            }
          })
          .catch(() => {
            id = null;
            return;
          });
      }
      let data = [];
      if (!id) return;
      let api;
      if (this.model?.api) {
        api = this.model.api.api;
        let apiData = this.model.api.data;
        apiData.params = { id };
        let resp = await this.$axios.post(api, apiData);
        if (resp.data.status == "ok") {
          data = resp.data.data;
        }
        return;
      } else {
        //let api="mechti/objects/"
        api = "/accounting/dir/loan_payment_schedule";
        let resp = await this.$axios.get(api + "/" + id);
        if (resp.data.status == "ok") {
          data = [...resp.data.data.loan_schedule];
        }
        if (this.model?.typeAction == "close") {
          if (data.filter((d) => !d.date_real).length > 1) {
            data
              .filter((d) => !d.date_real)
              .forEach((d) => {
                d.disabled = true;
              });
          }
        }
      }
      this.loan_schedule = data ? data : [];
    },
  },
};
</script>
