/** @format */

const buying = {
  form: [
    { name: 'name', title: 'Заголовок', type: 'string', validator: ['req'], setReadonly: true },
    { name: 'description', title: 'Описание', type: 'text', setReadonly: true },
    { name: 'type', title: 'Тип задачи', type: 'select', sourceList: 'types', validator: ['req'] },
    { name: 'date_start', title: 'Дата начала', type: 'date', hidden: true, validator: ['req'] },
    { name: 'date_end', title: 'Выполнить до', type: 'date', hidden: true, validator: ['req'] },
    { name: 'daterange', title: 'Дата начала и конца', type: 'daterange', dates: ['date_start', 'date_end'], calculated: true, errorFrom: ['date_start', 'date_end'] },
    {
      name: 'is_important',
      title: 'Приоритет',
      type: 'select',
      options: [
        { value: 1, text: 'Низкий', color: 'rgb(190, 190, 190)' },
        { value: 2, text: 'Стандартный', color: '#626262' },
        { value: 3, text: 'Высокий', color: '#FFB914' },
        { value: 4, text: 'Блокирующий', color: '#E97678' },
      ],
      default: 2,
      sourceList: 'optionsIsImportant',
    },
    { name: 'user_id', title: 'Назначено', type: 'selectuser', api: 'mechti/tasks/users/select', validator: ['req'] },
    { default: 0, name: 'status', title: 'Статус', validator: ['req'], sourceList: 'statuses' },
    {
      name: 'is_show_main',
      title: 'На главной',
      type: 'switcher',
      hidden: true,
    },
    { name: 'files', title: 'Файлы', type: 'files', setReadonly: false },
    {
      name: 'creator_name',
      title: 'Кем создана',
      type: 'string',
      filter: { type: 'selectapi', name: 'createdby_id', api: '/manager/users/select', filters: { status: 1 } },
      hidden: true,
    },
    { name: 'user_name', title: 'Кому назначена', type: 'string', filter: { type: 'selectapi', name: 'user_id', api: '/manager/users/select' }, hidden: true },
    { name: 'createdon', title: 'Дата создания', type: 'datetime', hidden: true },
    { name: 'watchers', title: 'Наблюдатели', type: 'selectuser', chips: true, hidden: true },
    { name: 'params.eventsbefore', title: 'Напоминание', type: 'select', sourceList: 'eventBeforeList', chips: false, hidden: true },
  ],
  list: [
    { name: 'id', title: '#', type: 'chipId', sortable: true, width: 60, hidden: false, hideText: true, sourceList: 'optionsIsImportant' },
    {
      filter: {
        type: 'select',
        name: 'is_important',
        options: [
          { value: 1, text: 'Низкий', color: 'rgb(190, 190, 190)' },
          { value: 2, text: 'Стандартный', color: '#626262' },
          { value: 3, text: 'Высокий', color: '#FFB914' },
          { value: 4, text: 'Блокирующий', color: '#E97678' },
        ],
      },
      name: 'is_important',
      title: 'Приоритет',
      type: 'chipId',
      width: 40,
      hideText: true,
      sourceList: 'optionsIsImportant',
      hidden: true,
    },
    {
      name: 'name',
      title: 'Задача',
      type: 'taskname',
      sortable: true,
      filter: { type: 'text', name: 'name' },
      style: 'white-space: normal;',
      width: 250,
      is_color: true,
    },
    {
      name: 'good_category_name',
      title: 'Категория',
      type: 'string',
      sortable: true,
      filter: { type: 'selectapi', name: 'good.category_id', api: 'accounting/dir/service_category/select', filters: { type: 2, status: 1 } },
      width: 250,
    },
    { name: 'type', sourceList: 'types', title: 'Тип', type: 'select', width: 130, badgeField: 'unviewedscomments_total', isMenu: true },
    {
      name: 'schedule',
      title: 'Расписание',
      type: 'string',
      width: 100,
      sortable: false,
      searchable: false,
      calculated: true,
      formula: '((d) => {return d._row?.data ? `за ${d._row.data.days}д до ${d._row.data.date} ;` :``})',
      style: 'white-space: normal;',
    },
    { name: 'date_start', title: 'Дата начала', type: 'date', sortable: true, width: 100 },
    { name: 'date_end', title: 'Выполнить до', type: 'date', sortable: true, width: 100 },
    {
      name: 'object_name',
      filter: { type: 'select', name: 'object_id', dir: 'object' },
      sortable: true,
      title: 'Объект',
      type: 'string',
      width: 150,
      isMenu: true,
    },
    { name: 'createdon', title: 'Дата', type: 'datetime', sortable: true, width: 150, hidden: true },
    {
      name: 'creator_name',
      title: 'Кем создана',
      type: 'string',
      filter: { type: 'selectapi', name: 'createdby_id', api: '/manager/users/select', filters: { status: 1 } },
      sortable: true,
      width: 150,
    },
    {
      name: 'user_name',
      title: 'Кому назначена',
      type: 'string',
      filter: { type: 'selectapi', name: 'user_id', api: '/manager/users/select', filters: { status: 1 } },
      sortable: true,
      width: 150,
      hidden: false,
    },
    { name: 'status', title: 'Статус', type: 'select', sortable: true, width: 150, sourceList: 'statuses', style: 'white-space: normal;' },
  ],
  optionsIsImportant: [
    { value: 1, text: 'Низкий', color: 'rgb(190, 190, 190)' },
    { value: 2, text: 'Стандартный', color: '#626262' },
    { value: 3, text: 'Высокий', color: '#FFB914' },
    { value: 4, text: 'Блокирующий', color: '#E97678' },
  ],
  statuses: [
    { value: 200, type: 'goods', text: 'Новая задача', next: [201, 299], action: '', status: 'new' },
    { value: 201, type: 'goods', text: 'Ожидает закупа', next: [299, 298, 220], action: 'Принять', status: 'progress' },
    { value: 205, type: 'goods', text: 'Ожидает перезакупа', next: [202, 299, 298], action: 'На перезакуп', status: 'progress' },
    { value: 202, type: 'goods', text: 'Закуплен (не доставлен)', next: [203, 204, -205], action: 'Закупить', func: 'statusBuyGoods', status: 'progress' },
    { value: 203, type: 'goods', text: 'На складе', next: [], action: 'Доставлено на склад', status: 'ready' },
    { value: 204, type: 'goods', text: 'На объекте', next: [], action: 'Доставлено на объект', status: 'ready' },
    { value: 299, type: 'goods', text: 'Отменено', next: [], action: 'Отменить', status: 'cancel' },
    { value: 298, type: 'goods', text: 'Требуется замена', action: 'Запросить замену', next: [], status: 'cancel', func: 'statusAddComment' },
    { value: 220, type: 'goods', text: 'Отправить в заказ', action: 'Закупить (в заказ)', status: 'progress', func: 'statusBuyGoods2' },
    { value: 280, type: 'goods', text: 'В заказе на закуп', status: 'progress' },
    { value: 281, type: 'goods', text: 'Закуплен (в заказе)', status: 'progress' },
  ],
  types: [{ value: 'construction-goods', text: 'Чек-лист', color: '#e76a6c', hidden: true, bgColor1: '#e76a6c' }],
};
export default buying;
