<template>
  <v-dialog :value="value" @input="$emit('input')" hide-overlay persistent max-width="600">
    <v-card>
      <v-card-title>Загрузка файлов</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-8">
        <v-file-input outlined label="Выберите файл" type="file" v-model="importFile"></v-file-input>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :disabled="importFile ? false : true" :loading="fileLoading" color="primary" @click="onImport()">Импорт</v-btn>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    parent_id: Number,
    api: String,
    uploadApi: { type: String, default: "/file/upload" },
  },
  data() {
    return {
      importFile: null,
      fileLoading: false,
    };
  },

  methods: {
    onChange(file) {
      if (file) this.importFile(file);
      else this.$emit("input", null);
    },
    onImport() {
      if (this.importFile) this.uploadFile(this.importFile);
    },
    async uploadFile(file) {
      this.loading = true;
      this.status = 0;
      let formData = new FormData();
      formData.append("file", file);
      formData.append("name", file.name);
      //accounting/doc/order_good/import_table
      let res = await this.$axios.post(this.uploadApi, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (progressEvent) => {
          this.status = parseInt((progressEvent.loaded / progressEvent.total) * 100);
        },
      });
      console.log(res);
      if (!this.api) {
        this.$emit("import", res.data);
        this.$emit("input", null);
        return;
      }
      if (res.data.data.file) {
        let data = { upload_id: res.data.data.id, parent_id: this.parent_id };
        await this.$axios.post(this.api, data);

        //await this.$axios.post("accounting/doc/order_good/import_table", data);
      }
    },
  },
};
</script>
