/** @format */

import Vue from 'vue';
import App from '@/App.vue';
import vuetify from './libs/vuetify';
import router from './libs/router';

import store from './store/index';
import config from './config/config';
import PortalVue from 'portal-vue';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';
import VueFullscreen from 'vue-fullscreen';
import 'element-ui/lib/theme-chalk/index.css';
import './libs/registerComponents';
import './libs/axios';
import './libs/translations';
import './libs/date';
import './libs/array';
import './libs/string';
import dirs from './libs/dirs';

import './registerServiceWorker';
import VueHtmlToPaper from 'vue-html-to-paper';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import VueApexCharts from 'vue-apexcharts';
import { VCascader } from 'vuetify-toolkit/vuetify-toolkit.umd';
import { VueMaskDirective } from 'v-mask';
Vue.directive('mask', VueMaskDirective);
Vue.component('apexchart', VueApexCharts);
Vue.component(VueQrcode.name, VueQrcode);
//Vue.use(qrcode);
// To use Html5Qrcode (more info below)

import CKEditor from '@ckeditor/ckeditor5-vue2';
//import CKEditor4 from 'ckeditor4-vue';
Vue.use(CKEditor);
//Vue.mixin(dirs);
Vue.use(PortalVue);

Vue.config.productionTip = false;
Vue.use(ElementUI, { locale });
///Vue.use(ApexCharts);
//Vue.use(QrcodeScanner);
//Vue.use(Qrcode);
Vue.use(VueFullscreen);
const options = {
  name: '_blank',
  specs: ['fullscreen=no', 'titlebar=no', 'scrollbars=no'],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
};

Vue.use(VueHtmlToPaper, options);
//Vue.component('confirmDialog', require.context('@/components', true, /confirmDialog.vue$/));
//console.log(require.context('@/components', true, /confirmDialog.vue$/));
const app = new Vue({
  mixins: [dirs],
  vuetify,
  router,
  store,
  render: (h) => h(App),
  el: '#app',
  data: () => {
    return {
      leftMenuDrawer: null,
      dialogShow: false,
      lang: 'ru',
      title: '',
      websocketWorking: false,
      websocket: null,
      websocketData: {},
      windowSize: { contentHeight: 0, innerHeight: 0 },
      filename: '',
      qrCodeList: [],
      _dirs: {},
    };
  },
  computed: {
    version() {
      return this.$store.getters['config/getVersion'];
    },
    filters() {
      return this.$store.getters['config/getSessionFilter'];
    },
    config() {
      return config;
    },
    profile() {
      let res = this.$store.getters['auth/profile'];
      if (res?.role) {
        //this.initDirs();
      }
      return res;
    },
    roles() {
      return this.dirs.roles;
      return this.$store.getters['config/get'].models.users.roles;
    },
    taskStatuses() {
      return this.$store.getters['config/get'].models.tasks.statuses;
    },
    interface() {
      return this.$store.getters['config/get'].config.interface;
    },
    typeClick() {
      return this.$store.getters['config/getTypeClick'];
    },
  },
  watch: {},
  created() {
    this.$vuetify.lang.current = this.lang;
  },
  mounted() {},
  methods: {
    makedirs(model) {
      return;
      model.forEach((m) => {
        if (m.dir) {
          if (this.dirs[m.dir]) {
          }
        }
      });
    },
    export2xls({ header, data, filename }) {
      const formatJson = (filterVal, jsonData) => {
        return jsonData.map((v) =>
          filterVal.map((j) => {
            if (j === 'timestamp') {
              return parseTime(v[j]);
            } else {
              return v[j];
            }
          })
        );
      };
      import('@/libs/Export2Excel').then((excel) => {
        console.log('@/libs/Export2Excel  ok');
        const tHeader = header;
        const filterVal = header;
        const tData = formatJson(filterVal, data);
        excel.export_json_to_excel({
          header: tHeader,
          data: tData,
          filename,
          autoWidth: this.autoWidth,
          bookType: this.bookType,
        });
        this.downloadLoading = false;
      });
    },
    log: function (info, type = '', level = '') {
      console.log(info);
    },

    async login(data) {
      await this.$store.dispatch('auth/login', {
        login: data.login,
        password: data.password,
      });
    },
    async logout() {
      await this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    },

    setSessionFilter(n, v) {
      this.$store.commit('config/setSessionFilter', {
        name: n,
        value: v,
      });
    },
    getSessionFilter(n) {
      const f = this.filters?.[n] || null;
      return f;
    },
  },
});
