<template>
  <div class="d-flex" style="position: relative">
    <v-dialog ref="dialog" v-model="showPicker" :return-value="date" persistent width="580px" :disabled="readonly">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          outlined
          :value="text"
          :label="model.title"
          :error-messages="error"
          :disabled="disabled"
          :hide-details="config.hideDetails || false"
          readonly
          persistent-placeholder
          placeholder="Не указано"
          :clearable="!!value && !readonly"
          :dense="config.dense || false"
          :append-icon="!value ? 'mdi-calendar' : ''"
          @click="showPicker = true"
          @click:append="showPicker = true"
          @click:clear="
            $emit('input', null);
            initValue = null;
          "
        >
        </v-text-field>
      </template>
      <v-card v-if="showPicker">
        <v-card-title> </v-card-title>
        <div class="d-flex">
          <v-date-picker
            :disabled="readonly"
            first-day-of-week="1"
            no-title
            v-model="date"
            @dblclick:date="
              setValue(dt);
              initValue = dt;
              $refs.dialog.save();
            "
          />
          <v-time-picker :disabled="readonly" format="24hr" v-model="time" no-title @click:minute="initValue = dt" />
        </div>
        <v-card-actions>
          {{ text }}
          <v-spacer></v-spacer>
          <v-btn
            @click="
              setValue(initValue);
              showPicker = false;
            "
            >Закрыть</v-btn
          >
          <v-btn
            v-if="!readonly"
            color="primary"
            @click="
              setValue(text);
              initValue = dt;
              $refs.dialog.save();
            "
            >Записать</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    model: Object,
    config: { type: Object, default: {} },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dt: null,
      initValue: this.value,
      showPicker: false,
      showTimePicker: false,
    };
  },
  watch: {},
  created() {},
  computed: {
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
    dateObject() {
      if (this.value instanceof Date) {
        console.log("date is date", this.value);
        return this.value;
      } else if (typeof this.value == "string") {
        console.log("date is string", this.value);
        return new Date(this.value);
      }
      // return null;
      return new Date();
    },
    text: {
      get() {
        return this.setText();
      },
      set(v) {
        this.$emit("input", null);
      },
    },
    time: {
      get: function () {
        this.dt = this.dateObject.toISOString();
        return this.dateObject?.time;
      },
      set: function (v) {
        const date = new Date(this.date + " " + v).toISOString();
        //const date = new Date(this.date + " " + v);
        this.dt = date;
        this.$emit("input", date);
      },
    },
    date: {
      get: function () {
        this.dt = this.dateObject.toISOString();
        return this.dateObject?.date;
      },
      set: function (v) {
        const date = new Date(v + " " + this.time).toISOString();
        //const date = new Date(v + " " + this.time);
        this.dt = date;
        console.log("date set", date);
        this.$emit("input", date);
      },
    },
  },
  methods: {
    setText() {
      return this.value ? this.dateObject.date + " " + this.dateObject.time : "";
    },
    setValue(v) {
      console.log("set value ", v);
      this.$emit("input", this.dt);
    },
  },
};
</script>
