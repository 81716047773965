<template>
  <li>
    <span v-if="edited">
      <input ref="input" @blur="onChangeInput" v-model="elName" />
      <b>:</b>
    </span>
    <span v-else>
      <select v-model="addType" style="font-size: 10px">
        <option v-for="(oe, oi) in addTypes" :key="oi" :value="oi">
          {{ oe }}
        </option>
      </select>
      <span title="create element" style="margin-left: 5px" @click="addElement">
        <i class="fas fa-plus"></i>
      </span>
    </span>
  </li>
</template>

<script>
export default {
  props: {
    el: [Array, Object, String, Number],
    name: { type: String, default: "" },
    type: { type: String, default: "String" },
  },
  data: function () {
    return {
      edited: false,
      elName: "",
      addTypes: ["String", "Number", "Array", "Object", "Boolean", "Function"],
      addType: 0,
    };
  },
  created() {
    this.addType = this.addTypes.findIndex((t) => t == this.type) || 0;
  },
  methods: {
    addElement2() {
      this.onChangeInput();
    },
    addElement: function () {
      if (Array.isArray(this.el)) {
        this.$emit("change", { v: this.getType() });
      } else if (typeof this.el === "object") {
        this.edited = true;
        if (this.name.length > 0) {
          this.elName = this.name;
          this.onChangeInput();
          return;
        }
        this.$nextTick(() => this.$refs.input.focus());
      } else {
        this.edited = true;
        if (this.name.length > 0) {
          this.elName = this.name;
          this.onChangeInput();
          return;
        }
        this.$nextTick(() => this.$refs.input.focus());
      }
    },
    onChangeInput: function (e) {
      this.edited = false;
      if (this.elName.length > 0) {
        this.$emit("change", { i: this.elName, v: this.getType() });
        this.elName = "";
      }
    },
    getType: function () {
      switch (this.addType) {
        case 0:
          return "";
        case 1:
          return 0;
        case 2:
          return [];
        case 3:
          return {};
        case 4:
          return false;
        case 5:
          return () => {
            return true;
          };
      }
    },
  },
};
</script>
