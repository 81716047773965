/** @format */

import axios from '../../libs/axios';
const state = {
  keys: {},
  badges: { tasks: { comments: 0, items: 0 }, tasksgoods: { comments: 0, items: 0 }, finance: { comments: 0, items: 0 }, payorder: { new: 0, plan: 0, late: 0 } },
  newComment: 0,
};

const getters = {
  getKeys: (state) => state.keys,
  getBadges:
    (state) =>
    (name = null, type = null) => {
      if (!name && !type) return state.badges;
      return state.badges?.[name]?.[type];
    },
  getNewComment: (state, name) => {
    return state.newComment;
  },
};

const actions = {
  keys: async ({ state, commit, dispatch }, data) => {
    let keys = state.keys;
    for (const [key, value] of Object.entries(data)) {
      keys[key] = value;
    }
    commit('setKeys', keys);
  },
  async badges({ state, commit, dispatch, rootState }, data) {
    let badges = state.badges;
    let resp;
    if ((rootState.auth.profile?.role || 0) < 20) {
      return;
    }
    let params = {};
    if (data == 'payorder' || !data) {
      axios.get('/mechti/pay_orders', { params: { filters: { status: 0 } } }).then((resp) => {
        if (resp.data.status === 'ok') {
          badges.payorder.new = resp.data.data.length || 0;
        }
      });
      axios.get('/mechti/pay_orders', { params: { filters: { status: 1 } } }).then((resp) => {
        if (resp.data.status === 'ok') {
          badges.payorder.plan = resp.data.data.length || 0;
        }
      });
      axios
        .get('/mechti/pay_orders', { params: { filters: { date_end: { condition: '<', value: new Date().date }, status: { condition: 'not in', value: [2, 3] } } } })
        .then((resp) => {
          if (resp.data.status === 'ok') {
            badges.payorder.late = resp.data.data.length || 0;
          }
        });
    }
    params = { filters: { type: { condition: '<>', value: 'construction-goods' } } };
    if (data == 'task_comments' || !data)
      axios.get('/mechti/tasks/count_unviewed_comments', { params }).then((resp) => {
        if (resp.data.status === 'ok') {
          badges.tasks.comments = resp.data.data;
        }
      });
    if (data == 'task_items' || !data)
      axios.get('/mechti/tasks/count_unviewed', { params }).then((resp) => {
        if (resp.data.status === 'ok') {
          badges.tasks.items = resp.data.data;
        }
      });
    params = { filters: { type: 'construction-goods' } };
    if (data == 'task_comments' || !data)
      axios.get('/mechti/buying/count_unviewed_comments', {}).then((resp) => {
        if (resp.data.status === 'ok') {
          badges.tasksgoods.comments = resp.data.data;
        }
      });
    if (data == 'task_items' || !data)
      axios.get('/mechti/buying/count_unviewed', {}).then((resp) => {
        if (resp.data.status === 'ok') {
          badges.tasksgoods.items = resp.data.data;
        }
      });
    if (data == 'finance_items' || !data)
      axios.get('/mechti/finance/count_unviewed').then((resp) => {
        if (resp.data.status === 'ok') {
          badges.finance.items = resp.data.data;
        }
      });

    if (data == 'task_comments') commit('setNewComments');
    commit('setBadges', badges);
  },
  newComment({ commit }, data) {
    commit('setNewComment');
  },
};

const mutations = {
  setKeys: (state, keys) => {
    console.log('state', keys);
    state.keys = keys;
  },
  setBadges: (state, badges) => {
    state.badges = badges;
  },
  setNewComment: (state) => {
    state.newComment++;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
