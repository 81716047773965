<template>
  <v-chip :color="el.color" dark small class="px-1 mx-0">{{ el.text }}</v-chip>
</template>

<script>
import { modelOptions } from "../../mixings";
export default {
  mixins: [modelOptions],
  props: {
    value: [String, Number],
    values: Object,
    model: Object,
    options: Array,
    hideText: { type: Boolean, default: false }, 
    showText: [Number, String, Object],
  },
  computed: {
    el() {
      let el = this.getElementByValue(this.value);
      //let el = this.getOptions(this.value);
      let res = {};
      if (el.text && el.color) res = JSON.parse(JSON.stringify(el));
      else
        res = {
          color: "#555",
          text: "Не задано",
        };
      if (this.hideText) res.text = " ";
      if (this.showText) {
        if (typeof this.showText === "object" && this.showText !== null) {
          console.log("showText is object", this.values, this.model);
          res.text = this.values?.[this.showText.field];
        } else res.text = this.showText;
      }
      return res;
    },
  },
  methods: {
    getElementByValue(value) {
      let options = this?.model?.options || this?.options || [];
      return options.find((x) => x.value == value) || {};
    },
  },
};
</script>
