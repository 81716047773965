import { render, staticRenderFns } from "./roomsItem.vue?vue&type=template&id=e381e7fe&"
import script from "./roomsItem.vue?vue&type=script&lang=js&"
export * from "./roomsItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports