<template>
  <div class="py-1 mb-4">
    <v-card :class="{ 'error--text': showError }">
      <v-card-title class="py-2">
        {{ model.title }}
        <v-spacer></v-spacer>
        <div v-if="!readonly && model.photo !== false" class="text-right pa-1">
          <v-btn class="ma-1" @click="addImage()" color="primary">+ Фото</v-btn>
        </div>
        <div v-if="!readonly && model.video !== false" class="text-right pa-1">
          <v-btn class="ma-1" @click="addVideo()" color="primary">+ Видео</v-btn>
        </div>
      </v-card-title>
      <v-card-text>
        <div>
          <v-row style="max-height: 300px; overflow: auto">
            <v-card
              v-for="(el, key) in images"
              :key="key"
              height="150px"
              width="150px"
              class="ma-2"
              @dragover="onDragOver(el, key, $event)"
              @dragend="finishDrag(el, key, $event)"
              @dragstart="startDrag(el, key, $event)"
              @dragleave="onDragLeave(el, key, $event)"
              :class="{ over: el === over.item && el !== dragFrom, [over.dir]: el === over.item && el !== dragFrom }"
            >
              <a v-if="el.includes('video#')" :href="$root.config.videoUrl + el.split('#')[1]" target="_blank">
                <v-img :src="$root.config.imageUrl + el.split('#')[1] + '?width=150&height=150'">
                  <s-video-icon :id="el.split('#')[2]" />
                </v-img>
                <v-btn
                  v-if="!readonly"
                  class="mx-0"
                  fab
                  dark
                  x-small
                  color="error"
                  style="position: absolute; right: 8px; top: 8px"
                  title="Удалить"
                  @click.prevent="remove(key)"
                >
                  <v-icon dark> mdi-close </v-icon>
                </v-btn>
              </a>
              <a v-else :href="$root.config.imageUrl + el" target="_blank">
                <v-img :src="$root.config.imageUrl + el + '?width=150&height=150'" />
                <v-btn
                  v-if="!readonly"
                  class="mx-0"
                  fab
                  dark
                  x-small
                  color="error"
                  style="position: absolute; right: 8px; top: 8px"
                  title="Удалить"
                  @click.prevent="remove(key)"
                >
                  <v-icon dark> mdi-close </v-icon>
                </v-btn>
              </a>
            </v-card>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
    <div class="v-text-field__details" v-if="showError">
      <div class="v-messages theme--light" role="alert">
        <div class="v-messages__wrapper error--text">{{ err }}</div>
      </div>
    </div>
    <v-dialog v-model="dialogFile" hide-overlay persistent max-width="600">
      <v-card class="">
        <v-card-title>Загрузка изображений</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-8">
          <v-file-input accept="image/*" multiple outlined chips label="Выберите файл изображений" type="file" v-model="uploadsFiles"></v-file-input>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="uploadsFiles ? false : true" :loading="fileLoading" color="primary" @click="upload()">
            Загрузить 
            <template v-slot:loader> <v-progress-linear v-model="progressLoading" color="green" height="35" rounded></v-progress-linear> </template>
          </v-btn>
          <v-btn @click="dialogFile = false">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogVideoFile" hide-overlay persistent max-width="600">
      <v-card class="">
        <v-card-title>Загрузка видео</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-8">
          <v-file-input accept="video/*" multiple outlined chips label="Выберите файл видео" type="file" v-model="uploadsFiles"></v-file-input>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="uploadsFiles ? false : true" :loading="fileLoading" color="primary" @click="upload()">
            Загрузить
            <template v-slot:loader>
              <v-progress-linear v-model="progressLoading" color="green" height="35" rounded></v-progress-linear>
            </template>
          </v-btn>
          <v-btn @click="dialogVideoFile = false">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: Array,
    values: {
      type: Object,
      default: () => {
        return {};
      },
    },
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    config: { type: Object, default: {} },
  },
  data() {
    return {
      uploadsFiles: null,
      dialogVideoFile: false,
      dialogFile: false,
      fileLoading: false,
      showError: false,
      err: null,
      over: {},
      startLoc: 0,
      progressLoading: 0,
      dragging: false,
      dragFrom: {},
    };
  },
  watch: {
    error(e) {
      this.err = e;
      if (e) {
        this.showError = true;
        setTimeout(() => {
          this.showError = false;
          this.err = "";
        }, 2000);
      }
    },
  },
  computed: {
    isReverse() {
      return this.model?.reverce || false;
    },
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
    images: {
      get() {
        let i = JSON.parse(JSON.stringify(this.items));
        return this.isReverse ? i.reverse() : i;
      },
      set(i) {
        // let i = JSON.parse(JSON.stringify(v));
        this.items = this.isReverse ? i.reverse() : i;
      },
    },
    items: {
      get() {
        let d = [];
        try {
          // d = JSON.parse(this.value);
          d = this.value;
          if (!d) d = [];
        } catch (error) {
          d = [];
        }
        return d;
      },
      set(v) {
        this.$emit("input", v);
        //    this.$emit("input", this.isReverse ? v.reverse() : v);
      },
    },
  },
  methods: {
    startDrag(item, i, e) {
      e.dataTransfer.setData("text", item);
      e.dataTransfer.setData("from", JSON.stringify(this.model));
      e.dataTransfer.effectAllowed = "move";
      // this.startLoc = e.clientY;
      this.dragging = true;
      this.dragFrom = this.model.name;
    },
    finishDrag(item, pos) {
      if (this.readonly) return;
      let el = this.images;
      el.splice(pos, 1);
      el.splice(this.over.pos, 0, item);
      this.images = el;
      setTimeout(() => {
        this.over = {};
      }, 50);
    },

    onDragOver(item, pos, e) {
      if (this.over.pos == pos) return;
      if (this.dragFrom != this.model.name) return;
      if (this.readonly) return;
      e.preventDefault();
      let dir;
      // dir = this.startLoc < e.clientY ? "down" : "up";
      this.over = { item, pos, dir };
      //      setTimeout(() => {        this.over = { item, pos, dir };      }, 50);
    },
    onDragLeave(item, pos, e) {
      if (this.over.pos == pos) this.over = {};
    },
    remove(key) {
      let e = this.images;
      e.splice(key, 1);
      this.images = e;
    },
    addImage() {
      this.uploadsFiles = null;
      this.dialogFile = true;
      this.fileLoading = false;
    },
    addVideo() {
      this.uploadsFiles = null;
      this.dialogVideoFile = true;
      this.fileLoading = false;
    },
    async upload() {
      this.fileLoading = true;
      let promises = [];
      for (let file of this.uploadsFiles) {
        promises.push(this.uploadFile(file));
      }
      await Promise.all(promises);
      this.fileLoading = false;
      this.dialogFile = false;
      this.dialogVideoFile = false;
    },
    uploadFile(file) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append("file", file);
        formData.append("name", file.name);
        if (this.dialogVideoFile) {
          if (this.model.group_id) formData.append("group_id", this.model.group_id);
          formData.append("parent_name", this.model.table_name);
          formData.append("parent_id", this.values.id);
        }
        this.progressLoading = 0;
        let count = this.dialogVideoFile ? this.uploadsFiles.length : 1;
        let api = this.dialogVideoFile ? "/file/upload/video" : "/image/upload";
        this.$axios
          .post(api, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
              this.progressLoading = parseInt((progressEvent.loaded / progressEvent.total) * 100);
            },
          })
          .then((response) => {
            let data = response.data.data.file;
            if (this.dialogVideoFile) data = "video#" + data + "#" + (response.data.data?.id || 0);
            let el = this.images;
            if (!Array.isArray(el)) el = [];
            if (this.isReverse) {
              el.unshift(data);
            } else {
              el.push(data);
            }
            this.images = el;
            resolve();
          })
          .catch(function (error) {
            console.log(error);
            this.$root.$emit("show-info", { text: "Error: " + error });
            reject(error);
          });
      });
    },
  },
};
</script>
<style lang="scss">
.over {
  opacity: 0.6;
}
</style>
