/** @format */

const documentOrderGoods = { 
  title: 'Заказ товаров',
  routeName: 'ordersGoods_view',
  accessName: 'orderGoods',
  api: '/accounting/doc/order_good',
  operations: { 1: [1, 2], 2: [10], 3: [20] },
  operationsCash: { 1: [3, 5, 69, 68, 70], 2: [3, 5, 69, 68, 70], 3: [3, 5, 69, 68, 70] },
  accDocName: 'AccountingDocOrderGoodModel',
  modelName: 'AccountingDocOrderGoodModel',
  orderType: [
    { value: 1, text: 'Заказ на объект' },
    { value: 2, text: 'Заказ на офис' },
    { value: 3, text: 'Заказ на склад' },
  ],
  statuses: [
    { value: 1, text: 'не проведен', next: [2], action: 'Распровести' },
    { value: 2, text: 'проведен', next: [1], action: 'Провести' },
    { value: 6, text: 'Коррекция' },
  ],
  list: [
    { name: 'id', title: 'id', type: 'number', sortable: true, width: 30, hidden: true },
    { name: 'code_doc', title: 'Номер', type: 'string', sortable: true, width: 50 },
    { name: 'date_doc', title: 'Дата', type: 'datetime', sortable: true, width: 120 },
    { name: 'type', title: 'Тип', type: 'select', sourceList: 'orderType', sortable: true, width: 30 },
    {
      name: 'order_for',
      title: 'Назначение',
      type: 'string',
      filter: {
        type: 'select',
        dirVar: {
          key: 'type',
          dirName: [
            { value: 1, name: 'object', preText: '[Объект] ', field: 'object_code' },
            { value: 2, name: 'office', preText: '[Офис] ', field: 'office_id' },
            { value: 3, name: 'storage', preText: '[Склад] ', dirFilter: { object_id: null }, field: 'store_id' },
          ],
        },
      },
      fieldFilter: ['object_code', 'office_id', 'store_id'],
      isMenu: true,
      sortable: true,
      width: 150,
    },
    { name: 'vendor_id', title: 'Поставщик', type: 'select', dir: 'vendor', width: 130 },
    { name: 'createdby_id', title: 'Автор', type: 'select', dir: 'user', filter: { type: 'select', name: 'createdby_id', dir: 'user' }, sortable: true, width: 150 },
    { name: 'status', title: 'Статус', type: 'select', sourceList: 'statuses', sortable: true, width: 150 },
    { name: 'total_price', title: 'Сумма', type: 'number', sortable: true, width: 80 },
    {
      name: 'balance',
      title: 'Долг',
      type: 'balanceDoc',
      params: { acc: '60.1', doc_name: 'AccountingDocOrderGoodModel' },
      value: '((d)=>{return d.status>1});',
      sortable: false,
      width: 80,
    },
    {
      name: 'balanceVendor',
      title: 'Излишек',
      type: 'balanceDoc',
      params: { acc: '60.5', doc_name: 'AccountingDocOrderGoodModel', negative: true },
      value: '((d)=>{return d.status==2});',
      sortable: false,
      width: 80,
    },
  ],

  viewForm1: {
    fields: 'code_doc,date_doc,createdby_id,operation_type,object_code,store_id,office_id,vendor_id,balanceVendor',
  },
 
  form: [
    { name: 'type', title: 'Назначение', type: 'select', sourceList: 'orderType', validator: ['req'] },
    { name: 'code_doc', title: 'Номер Документа', type: 'string', roleShow: [1000], hiddenCreate: true },
    { name: 'date_doc', title: 'Дата документа', type: 'datetime', hiddenCreate: true },
    { name: 'operation_type', title: 'Операция', type: 'select', dir: 'operationTable', validator: ['req'] },
    { name: 'object_code', title: 'Объект', type: 'select', dir: 'object', validator: ['req'], autocomplete: true, isMenu: true },
    { name: 'office_id', title: 'Офис', type: 'select', dir: 'office', validator: ['req'], autocomplete: true },
    { name: 'store_id', title: 'Склад', type: 'select', dir: 'storage', dirFilter: { object_id: '%null' }, validator: ['req'] },
    { name: 'vendor_id', title: 'Поставщик', type: 'select', dir: 'vendor', validator: ['req'], autocomplete: true },
    { name: 'status', title: 'Статус', validator: ['req'], default: 1, hidden: true },
    { name: 'createdby_id', title: 'Автор', type: 'select', dir: 'user' },
    {
      name: 'balanceVendor',
      title: 'Баланс поставщика',
      type: 'balanceApi',
      account: '60.5',
      value: 1,
      subconto1: '((d) => {return d.vendor_id;})',
    },
  ],
  config: {
    default: { 
      fields: 'type,status',
      fieldsRO: '',
      fieldsForm: 'type',
    },
    1: {
      fields: 'type,date_doc,operation_type,object_code,vendor_id,status',
      fieldsRO: '',
      fieldsForm: 'type,date_doc,operation_type,object_code,vendor_id,status',
    },
    2: {
      fields: 'type,date_doc,operation_type,office_id,vendor_id,status',
      fieldsRO: '',
      fieldsForm: 'type,date_doc,operation_type,office_id,vendor_id,status',
    },
    3: {
      fields: 'type,code_doc,date_doc,operation_type,store_id,vendor_id,status',
      fieldsRO: '',
      fieldsForm: 'type,code_doc,date_doc,operation_type,store_id,vendor_id,status',
    },
  },
  printField: 'category_id,name,amount_real,measure,price_real',
  listDetail: [
    { name: 'actions', width: 40, statusHide: 2 },
    { name: 'id', title: 'id', type: 'number', sortable: true, width: 55 },
    { name: 'good_id', type: 'number', hidden: true, sortable: true, width: 30 },
    { name: 'category_id', title: 'Категория', type: 'select', dir: 'category', typeEdit1: 'selectapi', sortable: true, width: 100 },
    { name: 'name', title: 'Наименование', type: 'string', sortable: true, width: 300, style: 'white-space: normal;' },
    { name: 'amount', title: 'Кол-во', type: 'number', validator: ['req'], sortable: true, width: 50 },
    { name: 'amount_real', title: 'Кол.факт', type: 'number', validator: ['req'], sortable: true, width: 50 },
    { name: 'measure', title: 'ед.изм.', type: 'select', options: 'measure', width: 30 },
    { name: 'price_plan', title: 'Стоим.план', type: 'number', sortable: true, width: 60 },
    { name: 'price_real', title: 'Стоим.факт', type: 'number', sortable: true, width: 60 },
    { name: 'price_adding', title: '+/-', type: 'number', sortable: true, width: 60 },
    { name: 'date_before', title: 'Дата доставки', type: 'date', sortable: true, width: 120 },
    { name: 'amount_income', title: 'Принято', type: 'number', sortable: true, width: 50, statusHide: 1 },
    { name: '_jm_construction_goods_1_task_id', title: 'Задача', type: 'string', sortable: true, width: 100, isMenu: true, alias: 'buying_id' },
  ],
  formDetail: [
    { name: 'good_id', type: 'number', hidden: true },
    {
      name: 'category_id',
      title: 'Категория',
      type: 'selectapi',
      api: 'accounting/dir/service_category/select',
      filters: {
        type: 2,
        OR: [
          { field: 'status', condition: '<>', value: 2 },
          { field: 'status', condition: 'IS', value: null },
        ],
      },
      validator: ['req'],
      autocomplete: true,
    },
    { name: 'name', title: 'Наименование', type: 'string', validator: ['req'] },
    { name: 'amount', title: 'Кол-во', type: 'number', validator: ['req'], default: 1 },
    { name: 'amount_real', title: 'Кол-во факт', type: 'number', hidden: true },
    { name: 'measure', title: 'ед.измер.', type: 'select', options: 'measure', validator: ['req'] },
    { name: 'price_plan', title: 'Плановая стоимость', type: 'number', validator: ['req'] },
    { name: 'price_unit', title: 'Цена за ед.', type: 'number' },
    { name: 'price_real', title: 'Фактическая стоимость', type: 'number', validator: ['req'] },
    { name: 'date_before', title: 'Дата доставки', type: 'datestring' },
    { name: 'status', validator: ['req'], default: 0 },
  ],
  listServiceDetail: [
    { name: 'actions', width: 40, statusHide: 2 },
    { name: 'id', title: 'id', type: 'number', sortable: true, width: 55 },
    { name: 'service_id', title: 'Наименование', type: 'select', dir: 'service', sortable: true, width: 300, style: 'white-space: normal;' },
    { name: 'value', title: 'Стоимость', type: 'number', sortable: true, width: 60 },
  ],
  formServiceDetail: [
    {
      name: 'service_id',
      title: 'Наименование',
      type: 'selectapi',
      api: 'accounting/dir/service/select',
      filters: { category_id: 30, id: { condition: `<>`, value: 33 } },
      dir1: 'service',
      dirFilter1: { value: [29] },
      validator: ['req'],
    },
    { name: 'value', title: 'Стоимость', type: 'number', validator: ['req'] },
  ],
};
export default documentOrderGoods;
