/** @format */

const model = {
  title: 'Приглашения',
  routeName: 'invite',
  accessName: 'invite',
  api: '/mechti/invite_registration',
  statuses: [
    { value: 1, text: 'Черновик', next: [2], action: 'В черновик' },
    { value: 2, text: 'Отправлено', next: [1], action: 'Отправить' },
    { value: 3, text: 'Зарегистрирован' },
  ],
  list: [
    { name: 'id', title: '#', type: 'id', sortable: true, width: 50 },
    { name: 'createdon', title: 'Дата', type: 'datetime', sortable: true, width: 120 },
    { name: 'createdby_id', title: 'Автор', type: 'select', dir: 'user', filter: { type: 'select', name: 'createdby_id', dir: 'user' }, sortable: true, width: 150 },
    { name: 'name', title: 'Имя', type: 'string', sortable: true, width: 150 },
    { name: 'whatsapp', title: 'whatsapp', type: 'string', sortable: true, width: 150 },
    { name: 'role', title: 'Роль', type: 'select', dir: 'roles', dirFilter: { id: [57, 58, 66, 67, 30, 40, 45, 51] }, sortable: true, width: 150 },
    { name: 'status', title: 'Статус', type: 'select', sourceList: 'statuses', sortable: true, width: 150 },
  ],
  form: [
    { name: 'name', title: 'Имя', type: 'string', validator: ['req'], autofocus: true, validator: ['req'] },
    { name: 'whatsapp', title: 'Номер whatsapp', type: 'string', validator: ['req', 'tel'] },
    { name: 'role', title: 'Роль', type: 'select', dir: 'roles', dirFilter: { id: [57, 58, 66, 67, 30, 40, 45, 51] }, validator: ['req'] },
    { name: 'template_id', title: 'Шаблон', type: 'selectapi', api: 'mechti/doc_template/select?filters={"type":3}', validator: ['req'] },
    { name: 'status', title: 'Статус', type: 'select', sourceList: 'statuses', default: 1, validator: ['req'] },
  ],

  config: {
    form: {
      title: 'Приглашение',
      fields: 'name,role,whatsapp,template_id',
      fieldsRO: '',
      fieldsForm: 'name,role,whatsapp,template_id',
    },
  },
};
export default model;
