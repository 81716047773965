/** @format */

export default {
  methods: {
    async save(api, data) { 
      this.loading = true;
      try {
        let response = await this.$axios.post(api, data);
        this.loading = false;
        let status = response.data.status == 'ok';
        if (status) {
          this.$root.$emit('show-info', {
            text: 'Данные записаны',
          });
        }
        this.afterSave(response);
        return response; 
      } catch (error) {
        this.$root.$emit('show-info', {
          type: 'error',
          text: 'Ошибка при сохранении ' + error.data.message,
        });
        this.loading = false;
        return false;
      }
    },
    afterSave(data) {},
  },
};
