/** @format */
const hidden = true;
const autocomplete = true;
const contracts = {
  title: 'Журнал договоров',
  routeName: 'contracts_view',
  accessName: 'contracts',
  api: '/accounting/dir/contract',
  accDocName: 'AccountingDirContract',
  statuses: [
    { value: 0, text: 'черновик', next: [1], action: 'Вернуть' },
    { value: 1, text: 'Не подписан', next: [0, 2], action: 'Отправить на подпись' },
    { value: 3, text: 'Не подписан', next: [], action: 'Вернуть на подпись', setValue: 1 },
    { value: 2, text: 'Подписан', next: [3], action: 'Подписать' },
  ],
  list: [
    { name: 'id', title: 'id', type: 'number', sortable: true, width: 30, hidden: !true },
    { name: 'contract_number', title: '№ контракта', type: 'string', sortable: true, width: 100 },
    { name: 'name', title: 'Название', type: 'string', width: 220 },
    { name: 'contract_type', title: 'Тип договора', type: 'select', options: 'contract_type', sortable: true, width: 150 },
    { name: 'contract_date', title: 'Дата заключения', type: 'date', sortable: true, width: 125 },
    { name: 'contract_end', title: 'Дата окончания', type: 'date', sortable: true, width: 125 },
    { name: 'object_id', title: 'Объект', type: 'select', dir: 'object', sortable: true, width: 120 },
    { name: 'status', title: 'Статус', type: 'select', sourceList: 'statuses', sortable: true, width: 150 },
    {
      name: 'side_1_vendor_id',
      title: 'Юридическое лицо',
      type: 'select',
      dir: 'vendor',
      sortable: true,
      width: 200,
      style: 'white-space: normal;',
      access: { role: [-1, -10] },
    },
    {
      name: 'side_2_vendor_id',
      title: 'Поставщик',
      type: 'select',
      dir: 'vendor',
      sortable: true,
      width: 200,
      style: 'white-space: normal;',
      access: { role: [-1, -10] },
    },
    { name: 'side_2_client_id', title: 'Клиент', type: 'select', dir: 'client', sortable: true, width: 200, style: 'white-space: normal;', access: { role: [-1, -10] } },
    {
      name: 'side_2_investor_id',
      title: 'Инвестор',
      type: 'select',
      dir: 'investor',
      sortable: true,
      width: 200,
      style: 'white-space: normal;',
      access: { role: [-1, -10] },
    },
    {
      name: 'side_2_employer_id',
      title: 'Сотрудник',
      type: 'select',
      dir: 'employee',
      sortable: true,
      width: 200,
      style: 'white-space: normal;',
      access: { role: [-1, -10] },
    },
    { name: 'createdby_id', title: 'Автор', type: 'select', dir: 'user', sortable: true, width: 180, style: 'white-space: normal;', access: { role: [-1, -10] } },
  ],
  objectContractList: {
    fields: 'contract_number,contract_type,,side_1_vendor_id,side_2_vendor_id,side_2_client_id,side_2_investor_id,side_2_employer_id,status',
  },
  viewForm1: {
    fields: 'object_id,name,contract_type,side_1_vendor_id,side_2_vendor_id,side_2_client_id,side_2_investor_id,side_2_employer_id,contract_date,contract_end',
  },
  viewForm2: {
    fields: 'createdon,createdby_id,comments,template_id',
  },
  form: [
    { name: 'contract_number', title: '№ контракта', type: 'string', validator: ['req'] },
    { name: 'name', title: 'Название', type: 'string', validator: ['req'] },
    { name: 'contract_date', title: 'Дата заключения', type: 'datestring', validator: ['req'] },
    {
      name: 'contract_type',
      title: 'Тип договора',
      type: 'select',
      options: 'contract_type',
      cascade: { template_id: { type: 'field', value: 'template_id' } },
      validator: ['req'],
    },
    { name: 'status', title: 'Статус', type: 'select', sourceList: 'statuses', default: 0 },
    { name: 'object_id', title: 'Объект', type: 'select', dir: 'object', autocomplete: true, isMenu: true },
    {
      name: 'side_1_vendor_id',
      title: 'Юридическое лицо',
      type: 'select',
      dir: 'vendor',
      dirFilter: { formula: '(d)=>{return (d?.document_types?.list || []).includes("AccountingDirContract_side1") }', validator: ['req'] },
      validator: ['req'],
      autocomplete,
    },
    { name: 'side_2_vendor_id', title: 'Поставщик', type: 'select', dir: 'vendor', validator: ['req'], autocomplete },
    { name: 'side_2_client_id', title: 'Клиент', type: 'select', dir: 'client', validator: ['req'], autocomplete },
    { name: 'side_2_investor_id', title: 'Инвестор', type: 'select', dir: 'investor', validator: ['req'], autocomplete },
    { name: 'side_2_employer_id', title: 'Сотрудник', type: 'select', dir: 'employee', validator: ['req'], autocomplete },
    { name: 'contract_end', title: 'Дата окончания', type: 'datestring' },

    { name: 'code_doc', title: 'Номер Документа', type: 'string', roleShow: [1000], hiddenCreate: true },
    { name: 'comments', title: 'Комментарий', type: 'text' },
    { name: 'createdby_id', title: 'Автор', type: 'select', dir: 'user', hiddenCreate: true },
    { name: 'createdon', title: 'Дата создания', type: 'datestring', hidden },
    { name: 'docs', title: 'Документы', type: 'filesdata', hiddenCreate: true },
    { name: 'filesRemove', default: [], hidden: true },
    { name: 'filesAdd', default: [], hidden: true },
    { name: 'template_id', title: 'Шаблон', type: 'selectapi', api: 'mechti/doc_template/select?filters={"type":1}' },
  ],
  listAppendix: [
    { name: 'actions', width: 40 },
    { name: 'sort', title: 'Порядок', type: 'number', width: 50 },
    { name: 'name', title: 'Название', type: 'string', width: 180 },
    { name: 'type', title: 'Тип', type: 'select', options: 'appendix_type', width: 130, isMenu: true },
    { name: 'regdate', title: 'Дата', type: 'datestring', width: 180 },
  ],
  formAppendix: [
    { name: 'sort', title: 'Порядок', type: 'number' },
    { name: 'name', title: 'Название', type: 'string', validator: ['req'] },
    { name: 'regdate', title: 'Дата', type: 'datestring' },
    {
      name: 'type',
      title: 'Тип',
      type: 'select',
      options: 'appendix_type',
      cascade: { template_id: { type: 'field', value: 'template_id' }, config: { type: 'field', value: 'config' } },
      validator: ['req'],
    },
    { name: 'template_id', title: 'Шаблон', type: 'selectapi', api: 'mechti/doc_template/select?filters={"type":2}' },
    { name: 'config', hidden: true },
    { name: 'data', hidden: true },
  ],
  config: {
    watch: 'contract_type',
    default: {
      fields: 'template_id,contract_type',
      fieldsRO: '',
      fieldsForm: 'contract_type,template_id',
    },
    1: {
      note: ' Договор с клиентом',
      fields: 'object_id,contract_number,name,contract_type,contract_date,contract_end,side_1_vendor_id,side_2_client_id,comments,status,docs,filesRemove,filesAdd',
      fieldsRO: '',
      fieldsForm: 'contract_number,name,contract_type,object_id,contract_date#6,contract_end#6,side_1_vendor_id,side_2_client_id,comments,docs',
    },
    2: {
      note: ' Договор с инвестором',
      fields: 'object_id,contract_number,name,contract_type,contract_date,contract_end,side_1_vendor_id,side_2_investor_id,comments,status,docs,filesRemove,filesAdd',
      fieldsRO: '',
      fieldsForm: 'contract_number,name,contract_type,object_id,contract_date#6,contract_end#6,side_1_vendor_id,side_2_investor_id,comments,docs',
    },
    3: {
      note: ' Договор с подрядчиком',
      fields:
        'template_id,object_id,contract_number,name,contract_type,contract_date,contract_end,side_1_vendor_id,side_2_vendor_id,comments,status,docs,filesRemove,filesAdd',
      fieldsRO: '',
      fieldsForm: 'contract_number,name,contract_type#8,template_id#4,object_id,contract_date#6,contract_end#6,side_1_vendor_id,side_2_vendor_id,comments,docs',
    },
    4: {
      note: ' Договор с сотрудником',
      fields: 'contract_number,name,contract_type,contract_date,contract_end,side_1_vendor_id,side_2_employer_id,comments,status,docs,filesRemove,filesAdd',
      fieldsRO: '',
      fieldsForm: 'contract_number,name,contract_type,contract_date#6,contract_end#6,side_1_vendor_id,side_2_employer_id,comments,docs',
    },
    5: {
      note: ' Договор с поставщиком',
      fields: 'contract_number,name,contract_type,contract_date,contract_end,side_1_vendor_id,side_2_vendor_id,comments,status,docs,filesRemove,filesAdd',
      fieldsRO: '',
      fieldsForm: 'contract_number,name,contract_type,contract_date#6,contract_end#6,side_1_vendor_id,side_2_vendor_id,comments,docs',
    },
  },
};
export default contracts;
