<template>
  <div>
    <gantt-elastic v-if="!loading" :tasks="tasks" :options="options">
      <gantt-header v-if="0" slot="header"></gantt-header>
    </gantt-elastic>
    <div v-if="loading" class="loading-block">
      <div class="loader"></div>
    </div>
  </div>
</template>

<script>
import GanttElastic from "gantt-elastic";
import GanttHeader from "gantt-elastic-header";
//import tasks from "../../data/gantt_tasks.js";
import options from "./options.js";
const taskStyles = {
  project: {},
  milestone: {
    base: {
      fill: "#1EBC61",
      stroke: "#0EAC51",
    },
  },
  task: {
    base: {
      fill: "#508FED",
      stroke: "#1656B6",
    },
  },
};

export default {
  props: {
    url: String,
    object_id: Number,
    config: { type: Object, default: {} },
  },
  data() {
    return {
      tasks: {},
      data: [],
      loading: true,
      options: options,
    };
  },
  components: {
    GanttElastic,
    GanttHeader,
  },
  methods: {
    mapTasks(tasks) {
      return tasks.map((task) => {
        return {
          id: task.id,
          label: task.name,
          //     user: task.user,
          start: new Date(task.date_start).date,
          end: new Date(task.date_end).setHours(23),
          //  progress: task.progress,
          progress: 5,
          type: "task", // task.department,
          department: task.department,
          style: {},
          //  style: taskStyles[task.type],
          //  parentId: task.parent_id,
          //  dependentOn: task.dependencies,
        };
      });
    },
    async fitchData() {
      this.loading = true;
      const d = await this.$axios.get(this.url, {
        params: {
          filters: { object_id: this.object_id },
          sort: { key: "date_start", order: "ASC" },
        },
      });
      if (d.data && d.data.data) {
        this.data = d.data.data;
        this.tasks = this.mapTasks(this.data);
      }
      this.loading = false;
    },
  },
  watch: {
    "config.taskList"(v) {
      console.log("watch config.taskList", this.config.taskList);
      this.options.taskList.display = this.config.taskList;
    },
  },
  created() {
    this.fitchData();
    console.log("height", this.options.height);
    if (this.config?.height) this.options.maxHeight = this.config.height - 82;
    if (this.config?.taskList) this.options.taskList.display = false;
  },
};
</script>