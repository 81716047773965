<template>
  <v-list-item link :to="value.route">
    <v-list-item-icon>
      <v-icon v-if="!sublist">{{ value.icon }}</v-icon>
    </v-list-item-icon>
    <v-list-item-title style="font-size: 1rem; font-weight: 300">
      <div>
        {{ $t(value.name) }}
        <span v-if="value.badge && typeof value.badge === 'object'" v-for="(badge, key) in value.badge" :key="key" :id="key">
          <v-badge v-if="badges[key] && badges[key][k]" v-for="(v, k) in badge" :key="k" :color="v" :content="badges[key] && badges[key][k]" inline />
        </span>
        <v-badge v-if="value.badge && typeof value.badge === 'string'" color="green" :value="comments" :content="comments" inline />
        <v-badge v-if="value.badge && typeof value.badge === 'string'" color="red" :value="items" :content="items" inline />
      </div>
    </v-list-item-title>
    <v-list-item-icon v-if="sublist" class="ml-0">
      <v-icon>{{ value.icon }}</v-icon>
    </v-list-item-icon>
  </v-list-item>
</template>

<script>
//import mdiOfficeBuildingPlusOutline from "@assets/icons/office-building-plus-outline";
export default {
  props: {
    value: Object,
    sublist: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    comments() {
      return this.$store.getters["state/getBadges"](this.value.badge, "comments");
    },
    badges() {
      let res = this.$store.getters["state/getBadges"]();

      return res;
    },
    items() {
      //let res = this.$store.getters["state/getBadges"];
      let res = this.$store.state.state.badges;

      return res?.[this.value.badge]?.items || 0;
    },
  },
  methods: {},
};
</script>
