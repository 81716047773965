<template>
  <div>
    <dialog-edit2 :value="value" @input="$emit('input')" :maxWidth="'400px'">
      <template v-slot:title> Настройки </template>
      <v-row>
        <v-col cols="12" class="pb-0">
          <a-form-model v-model="data" :model="model" :errors="errors" :config="{ dense: true }" @validate="validate($event)" />
        </v-col>
      </v-row>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save @click="submit()" :title="'Сохранить'"></a-btn-save>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit2>
  </div>
</template>

<script>
import { getForm, submitForm, removeEl } from "../mixings";
export default {
  mixins: [getForm, submitForm, removeEl],

  props: {
    value: Boolean,
    title: String,
    values: Object,
    model: [Object, Array],
  },
  data() {
    return {};
  },
  computed: {},
  created() {
    this.fetchData();
  },
  watch: {
    value(v) {
      if (v) {
        this.fetchData();
      }
    },
  },
  methods: {
    fetchData() {
      for (const m of this.model) {
        this.$set(this.data, m.name, this.values?.[m.name] || null);
      }
    },
    async submit() {
      this.$emit("apply", this.data);
      this.$emit("input");

      return true;
    },
  },
};
</script>
