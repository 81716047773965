/** @format */

export default {
  methods: {
    async remove(api, id) {
      this.loading = true;
      try {
        const response = await this.$axios({
          method: 'delete',
          url: api + '/' + id,
          data: {},
          params: {},
        });
        this.loading = false;
        this.afterRemove(response);
      } catch (error) {
        console.log(error);
        this.$root.$emit('show-info', {
          text: 'ОШИБКА. ' + error?.data?.message,
          type: 'error',
        });
        throw error;
      }
    },
    afterRemove(data) {},
  },
};
