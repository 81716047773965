<template>
  <v-dialog v-model="dialog" @keydown.esc="dialog = false">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" class="px-2 mx-1" :title="title" v-bind="attrs" v-on="on">
        <v-icon>{{ icon }}</v-icon>
        <btn-title v-if="$vuetify.breakpoint[`${size}AndUp`] && title && false">{{ title }}</btn-title>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="lighten-2">
        <span>
          {{ title }}
        </span>
        <v-btn style="position: absolute; right: 12px; top: 12px" fab dark small color="blue-grey" title="Закрыть окно" @click="dialog = false"
          ><v-icon dark> mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text style="height: 300px; overflow: auto">
        <s-document-acc-record v-if="dialog" ref="accounting" :id="id" :name="name" :join="join" :orWhere="orWhere" />
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="dialog = false">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    size: { type: String, default: "sm" },
    icon: { type: String, default: "fas fa-balance-scale-left" },
    title: { type: String, default: "Проводки" },
    id: Number,
    name: String,
    orWhere: { type: Array, default: () => [] },
    join: { type: Object, default: () => {} },
  },
  data() {
    return {
      dialog: false,
    };
  },
  created() {},
  watch: {
    dialog(v) {
      if (v) {
      }
    },
  },
  methods: {},
};
</script>
