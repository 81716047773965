<template>
  <div>
    <v-row class="mt-1 pt-2" style="overflow-y: auto; overflow-x: hidden">
      <v-col v-for="(el, i) in elements" :key="i" cols="12" lg="3" md="4" class="pa-1">
        <v-card class="mx-auto" max-width="400" elevation="4" @click="$emit('onClick', el)">
          <div :style="'background-color: #385f73'" class="pa-0">
            <v-img class="align-end pa-4" height1="90px" :src="getImage(el)" :aspect-ratio="5"> </v-img>
          </div>
          <v-card-title :style="el.is_parent ? 'background-color:#FFF3E0' : ''">{{ el.name }}</v-card-title>

          <v-card-text class="text--primary" :style="el.is_parent ? 'background-color:#FFF3E0' : ''">
            {{ el.introtext }}
          </v-card-text>
        </v-card>
      </v-col> 
    </v-row>
  </div>
</template>
 
<script>
export default {
  components: {},
  mixins: [],
  props: {
    parent: { type: Number, default: 0 },
    api: String,
    m: Object,
    title: String,
    config: Object,
  },
  data() {
    return {
      model: [],
      idEdit: 0,
      type: null,
      loaded: false,
      elements: [],
    };
  },
  computed: {},
  created() {
    this.updateData();
  },
  watch: {},
  methods: {
    getImage(el) {
      let res = "";
      if (el.images && el.images[0]) res = this.$root.config.imageUrl + el.images[0] + "?width=600&height=150";
      else res = require("@/assets/logo.png");
      return res;
    },
    async updateData(id = -1) {
      if (id == -1) id = this.parent;
      let params = {
        filters: { parent_id: id },
        sort: JSON.stringify([
          { key: "is_parent", order: "DESC" },
          { key: "name", order: "ASC" },
        ]),
      };
      let resp = await this.$axios.get(this.api, { params });
      if (resp.data.status == "ok") { 
        let res = resp.data.data;
        this.elements = res.filter((r) => {
          if (r.information_data.role) {
            try {
              let role = `[${r.information_data.role},1000]`;
              role = JSON.parse(role);
              return role.includes(this.$root.profile.role);
            } catch (error) {
              console.log(error);
              return false;
            }
          }
          return true;
        });
      }
      this.loaded = !false;
    },
  },
};
</script>
