/** @format */
import axios from '../../libs/axios';
import { models } from '@/config/models';
import config from '@/config/config';
const state = {
  version: { actual: 0, build: config.version, evaluted: false },

  data: {},
  phoneBar: false,
  params: null,
  typeClick: null,
  interfaceConfig: {},
  filters: {},
};

const getters = {
  getVersion: (state) => state.version,

  get: (state) => state.data,

  getAccess:
    (state) =>
    (name, conf = {}) => {
      const config = state.data.config;
      const o = name.split('.');
      let obj = name;
      let access = {};
      if (o.length == 1) {
        obj = o[0];
        access = config.access?.[obj] || {};
      } else if (o.length == 2) {
        obj = o[1];
        access = config.access?.[o[0]]?.[obj] || {};
      } else {
        return false;
      }
      console.log('getAccess', name, access);
      const role = this.$root.profile.role;
      const user = this.$root.profile.id;

      let res = false;
      if (access?.role?.length) {
        res = access.role.includes(role) || false;
        if (!res) res = access.role.includes(-1) && !access.role.includes(-role);
      }
      if (!res && access?.user?.length) res = res || access?.user.includes(user) || false;
      if (!res && access?.inRole?.length && conf?.access) {
        conf.access.forEach((el) => {
          res = res || (user == el.user_id && access.inRole.includes(el.user_role)) || false;
        });
      }
      if (!res && conf?.access && !access?.inRole?.length) {
        if (
          conf.access.find((el) => {
            return el.user_id == user;
          })
        ) {
          res = true;
          //console.log('conf?.access', conf, res);
        }
      }
      if (!res && conf?.users) {
        res = conf.users.includes(user) || false;
        //console.log('conf?.users', conf, res);
      }
      if (!res && conf?.data) {
        if (conf.data?.createdby_id == user) {
          res = true;
          //console.log('conf?.data', conf, res);
        }
      }

      //console.log('getAccess ' + name, { role, res }, access);

      return res;
    },
  getPhoneBar: (state) => state.phoneBar,
  getLocalParams: (state) => {
    let params = state.params;
    if (!params) {
      try {
        params = JSON.parse(localStorage.getItem('user-params'));
      } catch {}
      if (!params) {
        params = {};
        //localStorage.setItem('user-params', JSON.stringify(params));
      }
      state.params = params;
    }
    return state.params;
  },

  getSessionFilter: (state, name) => {
    let filters = state.filters;
    return filters;
  },
  getInterfaceConfig: (state) => {
    let interfaceConfig = state.interfaceConfig;
    if (!interfaceConfig) {
      try {
        interfaceConfig = state.data.config?.interface || {};
      } catch {}
      if (!interfaceConfig) {
        interfaceConfig = {};
        //localStorage.setItem('user-params', JSON.stringify(params));
      }
      state.interfaceConfig = interfaceConfig;
    }
    return state.interfaceConfig;
  },
  getTypeClick: (state) => {
    let type = state.typeClick;
    const def = 1;
    if (!type) {
      try {
        type = JSON.parse(localStorage.getItem('typeClick'));
        if (!type) type = state.data.config?.interface?.typeClick;
      } catch {}
      if (!type) {
        type = def;
        //localStorage.setItem('user-params', JSON.stringify(params));
      }
      state.typeClick = type;
    }
    return state.typeClick;
  },
};

const actions = {
  fitch: async ({ commit, dispatch }) => {
    let data = {};
    data.model = {};

    let enums = {
      destinations: [
        { value: 0, text: 'Исходящий' },
        { value: 1, text: 'Входящий' },
      ],
      callStatuses: [
        { value: 0, text: 'Не отвечен' },
        { value: 1, text: 'Отвечен' },
      ],
    };

    const resp = await axios.get('/config');
    let d = Object.assign({}, resp.data);
    const model = d.config.models;
    //console.log('config 2', resp.data);
    const keys = Object.keys(d.config);
    let m = {};
    for (const key in d.config) {
      if (key.includes('models.')) {
        m[key.split('.')[1]] = Object.assign({}, d.config[key]);
      }
      if (key.includes('statuses.')) {
        if (!d.config?.statuses) d['statuses'] = {};
        d.config.statuses = Object.assign({}, d.config.statuses, d.config[key]);
      }
    }
    d.models = Object.assign({}, models, model, m);
    //console.log('/config 1', d);

    commit('set', d);

    //commit('set', { models, enums });
  },

  async checkVersion({ state, commit, dispatch }, data) {
    let ver = state.version;
    axios.get('/config/config.version').then((resp) => {
      if (resp.data.status === 'ok') {
        ver.actual = resp.data.data;
        commit('setVersion', ver);
      }
    });
  },
};

const mutations = {
  set: (state, resp) => {
    state.data = resp;
  },

  setVersion: (state, resp) => {
    state.version = resp;
  },
  setPhoneBar: (state, r) => {
    state.phoneBar = !state.phoneBar;
  },
  setLocalParams: (state, { name, value }) => {
    let params = state.params;
    if (!value && value !== 0) {
      delete params[name];
    } else {
      params[name] = value;
    }
    localStorage.setItem('user-params', JSON.stringify(params));
    state.params = params;
  },
  setSessionFilter: (state, { name, value }) => {
    let filters = state.filters;
    if (!value && value !== 0) {
      delete filters[name];
    } else {
      filters[name] = value;
    }
    state.filters = filters;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
