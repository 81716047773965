/** @format */

const cashRemainder = {
  title: 'Остатки по кассам',
  accessName: 'cashRemainder',
  api: '/accounting/doc/cash_balance',
  apiStatus: '/accounting/doc/cash_balance',
  statuses: [
    { value: 1, text: 'не проведен', next: [2], action: 'Распровести' },
    { value: 2, text: 'проведен', next: [1], action: 'Провести' },
  ],

  list: [
    { name: 'id', title: 'id', type: 'number', sortable: true, width: 30, hidden: true },
    { name: 'code_doc', title: 'Номер', type: 'string', sortable: true, width: 50 },
    { name: 'date_doc', title: 'Дата остатка', type: 'datetime', sortable: true, width: 150 },
    { name: 'status', title: 'Статус', type: 'select', sourceList: 'statuses', sortable: true, hidden: true },
    { name: 'cashbox_id', title: 'Касса', type: 'select', dir: 'cashbox', width: 250 },
    { name: 'amount', title: 'Сумма', type: 'number', sortable: true },
    { name: 'comments', title: 'Заметки', type: 'text', sortable: true, width: 150 },
    {
      name: 'createdby_id',
      title: 'Автор',
      type: 'select',
      dir: 'user',
      filter: { name: 'createdby_id', type: 'selectapi', api: '/manager/users/select' },
      sortable: true,
      width: 150,
    },
  ],

  form: [
    { name: 'date_doc', title: 'Дата остатка', type: 'datetime' },
    { name: 'cashbox_id', title: 'Касса', type: 'select', dir: 'cashbox', validator: ['req'] },
    { name: 'amount', title: 'Сумма', type: 'number', validator: ['req'] },
    { name: 'status', title: 'Статус', validator: ['req'], default: 1 },
    { name: 'comments', title: 'Заметки', type: 'text', rows: 2 },
    { name: 'docs', title: 'Документация', type: 'filesdata', category: 1, hiddenCreate: true },
    { name: 'filesRemove', default: [], hidden: true },
    { name: 'filesAdd', default: [], hidden: true },
  ],
  config: {
    default: {
      fields: 'date_doc,cashbox_id,comments,amount,docs,filesRemove,filesAdd,status,createdby_id',
      fieldsRO: '',
      fieldsForm: 'date_doc,cashbox_id,amount,comments,docs',
    },
  },
};
export default cashRemainder;
