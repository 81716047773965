<template>
  <span v-html="text"> </span>
</template>

<script>
import modelOptions from "../../libs/modelOptions";
export default {
  mixins: [modelOptions],
  props: {
    value: [Array, Number, Object],

    model: Object,
  },
  data() {
    return {};
  },
  created() {},
  computed: {
    text() {
      if (this.value) {
        let v = [...this.value];
        let res = [];
        v.forEach((el) => {
          res.push(
            (this.items.find((i) => (i.value == el.role && this.model.accessType == "role") || (i.value == el.user_id && this.model.accessType == "user")) || {})?.text
          );
        });
        return res.join("; ");
      }
    },

    items() {
      let res = [];
      if (this.model.accessType == "role") res = this.$root.dirs.roles;
      if (this.model.accessType == "user") res = this.$root.dirs.user;
      return res;
    },
  },
  methods: {
    fetchData() {
      this.loading = true;
      this.loading = false;
    },
  },
};
</script>
<style lang="scss"></style>
