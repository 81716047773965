<template>
  <div v-html="player(value)"></div>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    model: {
      type: Object,
      default: {},
    },
  },
  methods: {
    player: function (url) {
      return (
        '<audio class="audio-player calls-audio" style="height: 30px;" controls>' +
        '<source src="' +
        (this.model?.direct ? "" : this.$root.config.recordUrl) +
        url +
        '"' +
        ' type="audio/mpeg"/></audio>'
      );
    },
  },
};
</script>
