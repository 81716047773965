var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-form-view',{attrs:{"value":_vm.balance,"model":[
      { name: 'credit', title: 'К оплате по документу', type: 'number' },
      { name: 'debit', title: 'Выплачено по документу', type: 'number' },
      { name: 'saldo', title: 'Не выплачено по документу', value: _vm.balance.credit - _vm.balance.debit, type: 'number' },
      { name: 'overpay', title: _vm.model.overpay || 'Излишек', value: _vm.overpay, type: 'number', hide: !(_vm.overpay > 0 && _vm.model.overpay) },
    ],"config":{
      dense: true,
    }},on:{"click":function($event){return _vm.getBalance()}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }