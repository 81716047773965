/** @format */
const editable = true;

const objects = {
  list: [
    { name: 'id', title: '#', type: 'id', sortable: true, width: 50 },
    {
      name: 'object_group',
      title: 'Тип',
      type: 'select',
      options: [
        { value: 0, text: 'Инвесторский' },
        { value: 1, text: 'Дизайн' },
        { value: 2, text: 'Ремонт' },
        { value: 3, text: 'Мебель' },
      ],
      filter: { name: 'status' },
      sortable: true,
      width: 200,
    },
    { name: 'name', title: 'Название', type: 'string', style: 'white-space: normal;', sortable: true, width: 200 },
    { name: 'city', title: 'Город', type: 'string', filter: { name: 'city', type: 'select', options: 'city' }, sortable: true, width: 150 },
    { name: 'address', title: 'Адрес', type: 'string', style: 'white-space: normal;', class: 'text-max-line-2', sortable: true, width: 300 },
    { name: 'status', title: 'Статус', type: 'select', options: 'status_object', sortable: true, width: 100 },
    {
      name: 'is_investor',
      title: 'Тип участия',
      type: 'select',
      options: [
        { value: 1, text: 'Инвестор' },
        { value: 0, text: 'Клиент' },
      ],
      width: 90,
    },
    { name: 'type', title: 'Тип', type: 'select', sortable: true, width: 90, options: 'objectTypes' },
    { name: 'class', title: 'Класс', type: 'select', options: 'objectClasses', sortable: true, width: 90 },
    { name: 'ap_area', title: 'Площадь', type: 'string', sortable: true, width: 70 },
    { name: 'ap_rooms', title: 'Комнат', type: 'string', sortable: true, width: 70 },
    { name: 'ap_ceiling_height', title: 'Потолки', type: 'string', sortable: true, width: 70 },
    { name: 'object_access', type: 'string', hidden: true },
    {
      name: 'object_design',
      title: 'Дизайнер',
      type: 'string',
      sortable: false,
      searchable: false,
      calculated: true,
      formula: '((d) => {return d.users_access.find((u)=>{return u.role==55})?.name;})',
      style: 'white-space: normal;',
      filter: { type: 'selectapi', name: 'objects_access.user_id', api: '/manager/users/select', filters: { role: 55 } },
      access: { role: [-1, -10] },
    },
    { name: 'client_id', type: 'select', dir: 'client', style: 'white-space: normal;', width: 300, hidden: true },
  ],
  form: [
    { name: 'name', title: 'Название', type: 'string', validator: ['req'], autofocus: true },
    { name: 'source', title: 'Источник', type: 'comboboxapi', api: '/mechti/objects/tags/source', chips: false, validator: [] },
    { name: 'link_ref', title: 'Ссылка', type: 'string', isMenu: true },
    { name: 'city', title: 'Город', validator: ['req'], type: 'comboboxapi', api: '/mechti/objects/tags/city', chips: false, rating: true },
    { name: 'address', title: 'Адрес', validator: ['req'], type: 'string', rating: true },
    { name: 'class', title: 'Класс', type: 'select', options: 'objectClasses', rating: true },
    { name: 'type', title: 'Тип', type: 'select', options: 'objectTypes', validator: ['req'], rating: true },
    { name: 'ap_price_buy', title: 'Стоимость покупки', validator: ['req'], type: 'number', rating: true },
    { name: 'ap_area', title: 'Площадь', type: 'number', validator: ['req', 'min#1'], rating: true },
    { name: 'ap_kitchen_area', title: 'Кухня м2', type: 'number', validator: ['req', 'min#1'] },
    { name: 'price_m2', title: 'Стоимость м2', type: 'number', disabled: true },
    { name: 'building_type', title: 'Тип здания', type: 'select', validator: ['req'], options: 'building_type' },
    { name: 'ap_rooms', title: 'Кол-во комнат', type: 'number', rating: true, validator: ['req', 'min#1'] },
    { name: 'ap_ceiling_height', title: 'Высота потолков', type: 'number', rating: true },
    { name: 'ap_layout', title: 'Планировка', type: 'text', rating: true },
    { name: 'ap_level', title: 'Уровней', type: 'number', rating: true },
    { name: 'ap_floor', title: 'Этаж', type: 'number', rating: true, validator: ['req', 'min#1'] },
    { name: 'building_floors', title: 'Этажей', type: 'number', validator: ['req', 'min#1'] },
    { name: 'ap_number', title: '№ квартиры', type: 'number', validator: ['req', 'min#1'] },
    { name: 'ap_view', title: 'Вид из окна', type: 'text', rating: true },
    { name: 'infrastructure', title: 'Инфраструктура', type: 'comboboxapi', api: '/mechti/objects/tags/infrastructure', chips: true, rating: true },
    { name: 'metro1', title: 'Метро', type: 'comboboxapi', api: '/mechti/objects/tags/metro', chips: true, rating: true, size: 6 },
    { name: 'metro', title: 'Метро', type: 'comboboxapi', api: '/mechti/underground_stations/select', fields: { value: 'text' }, chips: true, rating: true, size: 6 },
    { name: 'nearby', title: 'в 5 мин доступности', type: 'comboboxapi', api: '/mechti/objects/tags/nearby', chips: true, default: [], rating: true },
    { name: 'description', title: 'Описание', type: 'text' },
    { name: 'photos', title: 'Галерея', type: 'images', reverce: true },
    { name: 'photos_design', title: 'Дизайн-проект', type: 'images' },
    { name: 'docs', title: 'Проектная документация', type: 'files' },
    { name: 'docs_adv', title: 'Рекламные материалы', type: 'files', json: true },
    { name: 'docs_law', title: 'Юридические документы', type: 'files', json: true },
    { name: 'docs_furniture', title: 'Документы по мебели', type: 'files', json: true },
    { name: 'status', title: 'Статус', type: 'select', options: 'status_object', default: 10 },
    { name: 'data.vendor', title: 'Продавец', type: 'text', size: 6 },
    { name: 'residential_complex_id', title: 'ЖК', type: 'selectapi', api: 'mechti/residential_complex/select' },
    { name: 'residential_type', title: 'тип недвижимости', type: 'select', options: 'residential_type' },

    {
      name: 'buyer_id',
      title: 'покупатель',
      type: 'selectapi',
      api: 'mechti/clients/select',
      optionsFilter: {
        client_type: (v) => {
          return v & (1 << (1 - 1));
        },
      },
    },
    {
      name: 'client_id',
      title: 'Клиент',
      type: 'select',
      dir: 'client',
      api1: 'mechti/clients/select',
      optionsFilter1: {
        client_type: (v, el) => {
          if (v & (1 << (2 - 1))) return true;
          if (v & (1 << (3 - 1))) return true;
          if (v & (1 << (4 - 1))) return true;

          return false;
        },
      },
    },
    { name: 'object_access', title: 'Сотрудники', type: 'selectuser', chips: true },
    { name: 'investor_id', title: 'Инвестор', type: 'selectapi', api: 'mechti/investors/select' },
    { name: 'status_fin', title: 'Финансовый статус' },
    { name: 'object_design', title: 'Дизайнер проекта' },
    { name: 'investment_amount', title: 'Сумма по договору', type: 'number', json: true },
    { name: 'ap_price_sell', title: 'Стоимость продажи', type: 'number', disabled: true },
    { name: 'filesRemove', default: [], hidden: true },
    { name: 'filesAdd', default: [], hidden: true },
    { name: 'doc', title: 'Проектная документация', type: 'filesdata', category: 1, hiddenCreate: true },
    { name: 'doc_adv', title: 'Рекламные материалы', type: 'filesdata', category: 2, hiddenCreate: true },
    { name: 'doc_law', title: 'Юридические документы', type: 'filesdata', category: 3, hiddenCreate: true },
    { name: 'doc_furniture', title: 'Документы по мебели', type: 'filesdata', category: 4, hiddenCreate: true },
    { name: 'loan_schedule', title: 'График выплат', default: '', type: 'string' },
    { name: 'is_investor', title: 'Тип участия', type: 'number', default: 0, validator: ['req'] },
    { name: 'credit_sum', title: 'Сумма ипотеки', type: 'number', size: 6 },
    { name: 'credit_rate' },
    { name: 'credit_date_start' },
    { name: 'credit_month_payment' },
    { name: 'data.donor_id', title: 'Собственник', type: 'selectapi', api: 'mechti/investors/select' },
    { name: 'data.is_procuratory', title: 'Доверенность', type: 'switcher' },
    { name: 'data.is_agreement_investor', title: 'Договор с инвестором', type: 'switcher' },
    { name: 'data.is_agreement_donor', title: 'Договор с собственником', type: 'switcher' },
    { name: 'data.doc_sell_price', title: 'Стоимость продажи по ДКП', type: 'number', size: 6 },
    { name: 'data.doc_buy_price', title: 'Стоимость покупки по ДКП', type: 'number', size: 6 },
    { name: 'data.investor_share', title: 'Доля прибыли инвестора', default: 50, type: 'number', size: 6, validator: ['min#0', 'max#100'] },
    { name: 'data.version', title: 'Версия объявления', type: 'string' },
    { name: 'credit_first_payment', title: 'Размер перв. взноса', type: 'number', size: 6 },
    { name: 'plan_month_expenses', title: 'Ежемесячные расходы', type: 'number', size: 6 },
    { name: 'plan_date_finish', title: 'Дата готовности', type: 'datestring', size: 6 },
    { name: 'plan_sell_period', title: 'Сроки готовности (мес)', type: 'number', size: 6 },
    { name: 'plan_sell_price', title: 'Стоимость продажи', type: 'number', size: 6 },
    { name: 'plan_expenses', title: 'Стоимость реализации проекта', type: 'number', size: 6 },
    { name: 'plan_org_expenses', title: 'Плановые расходы на оформление', type: 'number', size: 6 },
    { name: 'plan_investment', title: 'Плановые личные вложения', type: 'number', readonly: true, size: 6 },
    { name: 'plan_percent_investor', title: '', type: 'number', size: 6 },
    { name: 'layout_legal', title: 'Планировка узаконена', type: 'switcher' },
    { name: 'ap_area_real', title: 'Площадь по договору', type: 'number', size: 6 },
    { name: 'ap_area_plan', title: 'Площадь фактическая', type: 'number', size: 6 },
    { name: 'plan_investment_amount', title: 'Сумма инвестирования план', type: 'number' },

    {
      name: 'object_group',
      title: 'Тип',
      type: 'select',
      options: [
        { value: 0, text: 'Инвесторский' },
        { value: 1, text: 'Дизайн (Клиент)' },
        { value: 2, text: 'Ремонт (Клиент)' },
        { value: 3, text: 'Мебель (Клиент)' },
      ],
      default: 0,
      validator: ['req'],
    },
    {
      name: 'object_type',
      title: 'Работы на объекте',
      type: 'selectbit',
      options: [
        { value: 1, text: 'Дизайн' },
        { value: 2, text: 'Ремонт' },
        { value: 3, text: 'Мебель' },
      ],

      validator: ['req'],
    },
    { name: 'data.rooms', title: 'Список комнат' },
    { name: 'status_site' },
    { name: 'bet' },
    { name: 'ap_area_plan', title: 'Площадь фактическая', type: 'number', size: 6 },
    { name: 'tech_requirements', title: 'Техническое задание', type: 'object-techrequirements' },
    { name: 'plan_solution', title: 'Планировочное решение', type: 'object-gallery', video: false },
    { name: 'style_compilation', title: 'Стилистическая подборка', type: 'object-gallery', video: false },
  ],
  formInfo: { fields: 'investor_id,client_id,name,source,link_ref,city,address,ap_rooms' },
  formInfo_role10: { fields: 'client_id,city,address' },
  formSale: [
    {
      name: 'buyer_id',
      title: 'Покупатель',
      validator: ['req'],
      type: 'selectapi',
      api: 'mechti/clients/select',
      optionsFilter: {
        client_type: (v) => {
          return v & (1 << (1 - 1));
        },
      },
      autofocus: true,
    },
    { name: 'ap_price_sell', title: 'Сумма продажи', validator: ['req'], type: 'number' },

    //ap_price_sell
  ],

  config: {
    site: {
      title: 'Данные объекта',
      fields:
        'residential_complex_id,residential_type,name,city,address,class,type,ap_area,ap_rooms,ap_ceiling_height,ap_layout,ap_level,ap_floor,ap_view,infrastructure,metro,nearby,description,photos,photos_design,doc,doc_adv,ap_price_sell',
      fieldsRO:
        'name,city,address,class,type,ap_area,ap_rooms,ap_ceiling_height,ap_layout,ap_level,ap_floor,ap_view,infrastructure,metro,nearby,description,photos,photos_design,doc,doc_adv,ap_price_sell',
      fieldsForm:
        'name,city,address,residential_complex_id#8,residential_type#4,class#6,type#6,ap_area#4,ap_rooms#4,ap_ceiling_height#4,ap_level#4,ap_floor#4,ap_price_sell#4,ap_layout,ap_view,infrastructure,metro,nearby,description,photos,photos_design,doc,doc_adv',
    },
    object: {
      title: 'Данные объекта',
      fields:
        'residential_complex_id,residential_type,style_compilation,plan_solution,tech_requirements,name,city,address,class,type,ap_area,ap_rooms,ap_ceiling_height,ap_layout,ap_level,ap_floor,ap_view,infrastructure,metro,nearby,description,photos,photos_design,doc,doc_adv,ap_price_sell,object_group',
      fieldsRO: '',
      fieldsForm:
        'name,city,address,residential_complex_id#8,residential_type#4,class#6,type#6,ap_area#4,ap_rooms#4,ap_ceiling_height#4,ap_level#4,ap_floor#4,ap_price_sell#4,ap_layout,ap_view,infrastructure,metro,nearby,description,photos,photos_design,doc,doc_adv,tech_requirements',
    },
    considers: {
      title: 'Предложения',
      fields:
        'status,source,name,credit_sum,data.investor_share,plan_sell_price,plan_expenses,credit_first_payment,plan_org_expenses,data.doc_buy_price,data.doc_sell_price,plan_month_expenses,data.donor_id,plan_investment_amount,data.is_procuratory,data.is_agreement_investor,data.is_agreement_donor,plan_date_finish,city,address,class,type,building_type,ap_area_real,ap_area_plan,layout_legal,ap_area,ap_kitchen_area,price_m2,ap_price_buy,ap_floor,building_floors,ap_number,ap_rooms,ap_ceiling_height,ap_level,ap_layout,ap_view,infrastructure,nearby,description,metro,data.vendor,photos,photos_design,doc,doc_adv,doc_law,style_compilation,plan_solution,photos_design',
      fieldsRO: '',
      fieldsForm: 'object_square,comments',
      fieldsReplace: {
        status: { name: 'status', title: 'Статус', type: 'select', options: status, default: 0 },
      },
    },
    lampObjects: {
      title: 'Фонарики',
      fields:
        'residential_complex_id,residential_type,bet,status_site,link_ref,status,source,name,plan_sell_price,plan_expenses,credit_first_payment,plan_org_expenses,data.doc_buy_price,data.doc_sell_price,plan_month_expenses,data.donor_id,plan_investment_amount,data.is_procuratory,data.is_agreement_investor,data.is_agreement_donor,plan_date_finish,city,address,class,type,building_type,ap_area_real,ap_area_plan,layout_legal,ap_area,ap_kitchen_area,price_m2,ap_price_buy,ap_floor,building_floors,ap_number,ap_rooms,ap_ceiling_height,ap_level,ap_layout,ap_view,infrastructure,nearby,description,metro,data.vendor,photos,photos_design,doc,doc_adv,doc_law,photos_design',
      fieldsRO: '',
      viewForm2: {
        fields: 'link_ref,name,city,adress,ap_rooms,ap_area,ap_price_buy,price_m2,building_floors,ap_floor',
      },
      fieldsForm: 'link_ref,object_square,comments',
      fieldsReplace: {
        status_site: {
          name: 'status_site',
          title: 'Публиковать на',
          type: 'selectbit',
          options: [
            { value: 1, text: '1', default: true, show: false },
            { value: 2, text: 'Cайт' },
            { value: 3, text: 'Авито' },
            { value: 4, text: 'Циан' },
          ],
        },

        status: { name: 'status', title: 'Статус', type: 'select', options: 'status_site', sourceList: 'config.statuses.lamp_object', default: 2 },
      },
    },
    agentObjects: {
      title: 'Агентский объект',
      fields:
        'link_ref,status,source,name,plan_sell_price,plan_expenses,credit_first_payment,plan_org_expenses,data.doc_buy_price,data.doc_sell_price,' +
        'plan_month_expenses,data.donor_id,plan_investment_amount,data.is_procuratory,data.is_agreement_investor,data.is_agreement_donor,plan_date_finish,' +
        'city,address,residential_complex_id,class,type,building_type,ap_area_real,ap_area_plan,layout_legal,ap_area,ap_kitchen_area,price_m2,ap_price_buy,ap_floor,building_floors,' +
        'ap_number,ap_rooms,ap_ceiling_height,ap_level,ap_layout,ap_view,infrastructure,nearby,description,metro,data.vendor,photos,photos_design,doc,doc_adv,' +
        'doc_law,photos_design,residential_complex_id,residential_type',
      fieldsRO: '',
      viewForm2: {
        fields: 'link_ref,name,city,adress,residential_complex_id#8,residential_type#4,ap_rooms,ap_area,ap_price_buy,price_m2,building_floors,ap_floor',
      },
      fieldsForm: 'link_ref,object_square,comments',
      fieldsReplace: {
        status_site: {
          name: 'status_site',
          title: 'Публиковать на',
          type: 'selectbit',
          options: [
            { value: 1, text: '1', default: true, show: false },
            { value: 2, text: 'Cайт' },
            { value: 3, text: 'Авито' },
            { value: 4, text: 'Циан' },
          ],
        },
        status: { name: 'status', title: 'Статус', type: 'select', options: 'status_site', sourceList: 'config.statuses.lamp_object', default: 1 },
      },
    },
    leadObjects: {
      title: 'Данные объекта клиента',
      fields:
        'link_ref,source,name,plan_sell_price,plan_expenses,credit_first_payment,plan_org_expenses,data.doc_buy_price,data.doc_sell_price,' +
        'plan_month_expenses,data.donor_id,plan_investment_amount,data.is_procuratory,data.is_agreement_investor,data.is_agreement_donor,plan_date_finish,' +
        'city,address,residential_complex_id,class,type,building_type,ap_area_real,ap_area_plan,layout_legal,ap_area,ap_kitchen_area,price_m2,ap_price_buy,ap_floor,building_floors,' +
        'ap_number,ap_rooms,ap_ceiling_height,ap_level,ap_layout,ap_view,infrastructure,nearby,description,metro,data.vendor,photos,photos_design,doc,doc_adv,' +
        'doc_law,photos_design,residential_complex_id,residential_type',
      fieldsRO: '',
      fieldsForm:
        'city#6,address#6,residential_complex_id#8,residential_type#4,class#4,type#4,building_type#4,ap_area_real#3,ap_area_plan#3,' +
        'layout_legal#6,ap_area#2,ap_kitchen_area#2,price_m2#2,ap_price_buy#6,ap_floor#2,building_floors#2,ap_number#2,ap_rooms#2,ap_ceiling_height#2,' +
        'ap_level#2,ap_layout#6,ap_view#6,infrastructure,nearby,description,metro',
    },
  },
  formSite: [
    { name: 'name', title: 'Название', type: 'string', validator: ['req'], autofocus: true },
    { name: 'city', title: 'Город', validator: ['req'], type: 'comboboxapi', api: '/mechti/objects/tags/city', chips: false, rating: true },
    { name: 'address', title: 'Адрес', validator: ['req'], type: 'string', rating: true },
    { name: 'class', title: 'Класс', type: 'select', options: 'objectClasses', rating: true },
    { name: 'type', title: 'Тип', type: 'select', options: 'objectTypes', validator: ['req'], rating: true },
    { name: 'ap_price_buy', title: 'Стоимость квартиры', validator: ['req'], type: 'number', rating: true },
    { name: 'ap_area', title: 'Площадь', type: 'number', validator: ['req'], rating: true },
    { name: 'ap_rooms', title: 'Количество комнат', type: 'number', rating: true },
    { name: 'ap_ceiling_height', title: 'Высота потолков', type: 'number', rating: true },
    { name: 'ap_layout', title: 'Планировка', type: 'text', rating: true },
    { name: 'ap_level', title: 'Уровней', type: 'number', rating: true },
    { name: 'ap_floor', title: 'Этаж', type: 'number', rating: true },
    { name: 'ap_view', title: 'Вид из окна', type: 'text', rating: true },
    { name: 'infrastructure', title: 'Инфраструктура', type: 'comboboxapi', api: '/mechti/objects/tags/infrastructure', chips: true, rating: true },
    { name: 'metro', title: 'Метро', type: 'comboboxapi', api: '/mechti/objects/tags/metro', chips: true, rating: true },
    { name: 'nearby', title: 'в 5 мин доступности', type: 'comboboxapi', api: '/mechti/objects/tags/nearby', chips: true, default: [], rating: true },
    { name: 'description', title: 'Описание', type: 'text' },
    { name: 'photos', title: 'Фото галерея', type: 'images' },
    { name: 'photos_design', title: 'Дизайн-проект', type: 'images' },
    { name: 'docs', title: 'Проектная документация', type: 'files' },
    { name: 'docs_adv', title: 'Рекламные материалы', type: 'files', json: true },
    { name: 'docs_law', title: 'Юридические документы', type: 'files', json: true },
    { name: 'docs_furniture', title: 'Документы по мебели', type: 'files', json: true },
    { name: 'status', title: 'Статус', type: 'select', options: 'status_object', default: 10 },
    { name: 'object_access', title: 'Сотрудники', type: 'selectapi', api: '/mechti/users', chips: true },
    { name: 'investor_id', title: 'Инвестор', type: 'selectapi', api: '/mechti/investors/select' },
    { name: 'ap_price_sell', title: 'Стоимость продажи', type: 'number', disabled: true },
    { name: 'doc', title: 'Проектная документация', type: 'filesdata', category: 1, hiddenCreate: true },
    { name: 'doc_adv', title: 'Рекламные материалы', type: 'filesdata', category: 2, hiddenCreate: true },
    { name: 'doc_law', title: 'Юридические документы', type: 'filesdata', category: 3, hiddenCreate: true },
    { name: 'doc_furniture', title: 'Документы по мебели', type: 'filesdata', category: 4, hiddenCreate: true },
    { name: 'residential_complex_id', title: 'ЖК', type: 'selectapi', api: 'mechti/residential_complex/select' },
    { name: 'residential_type', title: 'тип недвижимости', type: 'select', options: 'residential_type' },
  ],
};

export default objects;
