/** @format */

const documentActWork = {
  title: 'Акт (Подрядчик)',
  tableName: 'accounting_doc_act_work',
  routeName: 'actWork_view',
  accessName: 'actWork',
  accDocName: 'AccountingDocActWork',
  api: '/accounting/doc/act_work',
  operationsCash: [47, 65],

  statuses: [
    { value: 0, text: 'черновик', next: [], action: 'Вернуть' },
    { value: 1, text: 'На проверке', next: [0, 2], action: 'Распровести' },
    { value: 2, text: 'проведен', next: [1], action: 'Провести' },
  ],
  list: [
    { name: 'id', title: 'id', type: 'number', sortable: true, width: 30, hidden: true },
    { name: 'code_doc', title: 'Номер', type: 'string', sortable: true, width: 50 },
    { name: 'object_id', title: 'Объект', type: 'select', dir: 'object', sortable: true, width: 50, isMenu: true },
    { name: 'date_doc', title: 'Дата', type: 'datetime', sortable: true, width: 120 },
    { name: 'work_name', title: 'Название', type: 'string', sortable: true, width: 30 },
    { name: 'vendor_id', title: 'Поставщик', type: 'select', dir: 'vendor', filter: { type: 'select', name: 'vendor_id', dir: 'vendor' }, sortable: true, width: 130 },
    { name: 'createdby_id', title: 'Автор', type: 'select', dir: 'user', filter: { type: 'select', name: 'createdby_id', dir: 'user' }, sortable: true, width: 150 },
    { name: 'status', title: 'Статус', type: 'select', sourceList: 'statuses', sortable: true, width: 150 },
    { name: 'value', title: 'Сумма', type: 'number', sortable: true, width: 80 },
    {
      name: 'balance',
      title: 'Долг',
      type: 'balanceDoc',
      params: { acc: '60.4', doc_name: 'AccountingDocActWork' },
      value: '((d)=>{return d.status==2});',
      sortable: false,
      width: 80,
    },
  ],

  form: [
    { name: 'code_doc', title: 'Номер Документа', type: 'string', roleShow: [1000], hiddenCreate: true },
    { name: 'date_doc', title: 'Дата документа', type: 'datetime', hiddenCreate: true },
    { name: 'object_id', title: 'Объект', type: 'select', dir: 'object', validator: ['req'], isMenu: true },
    { name: 'employee_id', title: 'Сотрудник', type: 'select', dir: 'user', validator: ['req'] },
    { name: 'vendor_id', title: 'Поставщик', type: 'selectapi', api: 'accounting/dir/vendor/select', validator: ['req'], filters: {} },
    { name: 'value', title: 'Стоимость', type: 'number', validator: ['req'] },
    { name: 'value_penalty', title: 'Размер пени', type: 'number', validator: ['req1'] },
    { name: 'days_overdue', title: 'Дней просрочки', type: 'number', validator: ['req1'] },
    { name: 'work_name', title: 'Наименование работы', type: 'string', validator: ['req'] },
    { name: 'status', title: 'Статус', validator: ['req'], default: 0, hidden: true },
    { name: 'description', title: 'Примечание', type: 'text' },
    { name: 'photos', title: 'Галерея', type: 'images', reverce: true, hiddenCreate: true },
    { name: 'docs', title: 'Документация', type: 'filesdata', category: 1, hiddenCreate: true },
    {
      name: 'doc_all',
      title: 'Документация',
      type: 'filesdata',
      big: true,
      bigSize: 0,
      category: 1,
      hiddenCreate: true,
      table_name: 'accounting_doc_act_work',
    },
    {
      name: 'doc_design',
      title: 'Исходники дизайн-проекта',
      type: 'filesdata',
      big: true,
      bigSize: 0,
      category: 2,
      fileType: 'Дизайн проект',
      hiddenCreate: true,
      table_name: 'accounting_doc_act_work',
    },
    { name: 'filesRemove', default: [], hidden: true },
    { name: 'filesAdd', default: [], hidden: true },
    { name: 'construction_schedule_id', hidden: true, validator: ['req'] },
    {
      name: 'contract_id',
      title: 'Договор',
      type: 'selectapi',
      api: 'accounting/dir/contract/select',
      filters: { stasus: -1 },
      validator: ['req1'],
    },
    { name: 'createdby_id', title: 'Автор', type: 'select', dir: 'user', hiddenCreate: true },
  ],
  viewForm1: {
    fields: 'code_doc,date_doc,object_id,vendor_id,contract_id',
  },
  viewForm2: {
    fields: 'work_name,value,value_penalty,days_overdue,description',
  },
  config: {
    default: {
      fields: 'vendor_id,work_name,value,photos,description,contract_id,doc_all,filesRemove,filesAdd,status,createdby_id,construction_schedule_id,doc_design',
      fieldsRO: '',
      fieldsForm: 'createdby_id,vendor_id,contract_id,work_name,value,description,photos,doc_all,doc_design',
    },
    1: {
      fields:
        'vendor_id,work_name,photos,value,value_penalty,contract_id,status,createdby_id,construction_schedule_id,description,doc_all,filesRemove,filesAdd,days_overdue,doc_design',
      fieldsRO: 'vendor_id,work_name,value',
      fieldsForm: 'createdby_id,vendor_id,work_name,value,contract_id#6,value_penalty#3,days_overdue#3,description,photos,doc_all,doc_design',
    },
  },
};
export default documentActWork;
