var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"text-align":"left"}},[(_vm.loaded)?_c('div',{staticClass:"mb-2"},[_vm._v("Сумма кредита: "+_vm._s(_vm.getLoansDetail(1)))]):_vm._e(),_vm._l((_vm.schedule),function(el,i){return _c('v-row',{key:i,staticClass:"mb-2 .flex-nowrap .flex-md-nowrap",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"flex-grow-0 flex-shrink-0",attrs:{"cols":"1","min-width":"50"}},[_c('a-input-string',{staticClass:"pr-2",attrs:{"model":{ name: 'numb', title: '№', type: 'string', readonly: true },"disabled":false,"value":el.numb,"config":_vm.config}})],1),_c('v-col',{staticClass:"flex-grow-1 flex-shrink-0",attrs:{"cols":"0"}},[_c('a-input-datestring',{attrs:{"model":{ name: 'date_plan', title: 'Дата (план)', readonly: (_vm.hasError && _vm.curError.id != i && (_vm.curError.field != 'value' || !_vm.curError.field)) || el.doc_id },"disabled":false,"value":el.date_plan,"config":_vm.config,"readonly":true,"error":_vm.getError(i, 'date_plan')},on:{"input":function($event){el.date_plan = $event}}})],1),_c('v-col',{staticClass:"flex-grow-1 flex-shrink-1",attrs:{"cols":"0"}},[_c('a-input-number',{staticClass:"px-2",attrs:{"model":{ name: 'value', title: 'Сумма (тело)', readonly: (_vm.hasError && _vm.curError.id != i && (_vm.curError.field != 'value' || !_vm.curError.field)) || el.doc_id },"values":el,"disabled":false,"value":el.value,"config":_vm.config,"error":_vm.getError(i, 'value')},on:{"input":function($event){return _vm.onInput($event, i, 'value', el)}}})],1),_c('v-col',{staticClass:"flex-grow-1 flex-shrink-1",attrs:{"cols":"0"}},[_c('a-input-number',{staticClass:"pr-2",attrs:{"model":{
          name: 'value_percent',
          title: 'Сумма (%)',
          type: 'number',
          readonly: (_vm.hasError && _vm.curError.id != i && (_vm.curError.field != 'value_percent' || !_vm.curError.field)) || el.doc_id,
        },"values":el,"disabled":false,"config":_vm.config,"error":_vm.getError(i, 'value_percent')},on:{"input":function($event){return _vm.onInput($event, i, 'value_percent', el)}},model:{value:(el.value_percent),callback:function ($$v) {_vm.$set(el, "value_percent", $$v)},expression:"el.value_percent"}})],1),_c('v-col',{staticClass:"flex-grow-1 flex-shrink-0",attrs:{"cols":"0"}},[_c('a-input-number',{attrs:{"model":{
          name: 'value_commission',
          title: 'Комиссия',
          type: 'number',
          readonly: (_vm.hasError && _vm.curError.id != i && (_vm.curError.field != 'value_commission' || !_vm.curError.field)) || el.doc_id,
        },"values":el,"disabled":false,"config":_vm.config,"error":_vm.getError(i, 'value_commission')},on:{"input":function($event){return _vm.onInput($event, i, 'value_commission', el)}},model:{value:(el.value_commission),callback:function ($$v) {_vm.$set(el, "value_commission", $$v)},expression:"el.value_commission"}})],1),_c('v-col',{staticClass:"flex-grow-0 flex-shrink-1",attrs:{"cols":"1"}},[(el.doc_id)?_c('v-icon',{attrs:{"color":"success","large":""}},[_vm._v(" mdi-check-circle ")]):(!_vm.hasError || _vm.curError.id == i)?_c('v-btn',{attrs:{"elevation":"5","fab":"","small":"","color":"red","dark":"","top":"","right":""},on:{"click":function($event){return _vm.onClickDel(i)}}},[_c('v-icon',[_vm._v("mdi-minus")])],1):_vm._e()],1)],1)}),_c('div',{staticClass:"text-left"},[(!_vm.hasError)?_c('v-btn',{attrs:{"elevation":"2","fab":"","x-small":"","dark":"","color":"green","top":"","left":""},on:{"click":_vm.onClickAdd}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1),_c('span',[_vm._v(" Итого тело: "+_vm._s(_vm.total_value)+", Итого процент: "+_vm._s(_vm.total_percent)+" ")])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }