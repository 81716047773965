<template>
  <ul :class="{ menu__list: true, 'menu__list--level2': !!level }">
    <li v-for="(item, i) in data" :key="i" :class="{ menu__item: true, 'menu__item--level2': !!level }" link :to="item.route">
      <a
        @click="$emit('active', item.parent_id)"
        :disabled="item.lock"
        :href="item.is_parent || item.lock ? '#' + $route.path : `#/education-person/${item.id}`"
        :class="{
          menu__link: true,
          'menu__link--level2': !!level,
          'link--active': $route.params.id == item.id || active || (child.includes(Number($route.params.id)) && item.is_parent),
          'menu__link--level2_active': !!level && $route.params.id == item.id,
          'menu__link--level2__lock': item.lock,
        }"
      >
        {{ item.name }}
      </a>

      <list-edu-menu-item v-if="item.is_parent" :key="i" :value="item" :level="level + 1" @active="setActive($event)" :parent="item.id" @child="child = [...$event]" />
    </li>
  </ul>
</template>

<script>
//import mdiOfficeBuildingPlusOutline from "@assets/icons/office-building-plus-outline";
export default {
  props: {
    value: Object,
    level: { type: Number, default: 0 },
    parent: { type: Number, default: 1 },
  },
  data() {
    return {
      data: [],
      active: false,
      child: [],
      m: this.$store.getters["config/get"].models.education.chain,
    };
  },
  created() {
    console.log(this.$route);
    this.getCourse(this.value.id);
  },
  watch: {
    "$route.params.id"(v) {
      //if (v == this.value.id) this.$emit("active", true);
    },
  },
  computed: {
    isLock1() {},
  },
  methods: {
    async isLock() {
      this.data.forEach(async (e) => {
        if (e.lock) {
          const params = { filters: { status: 3, education_chain_id: e.data.rules.split(","), user_id: this.$root.profile.id } };
          let res = await this.$axios("/mechti/education/result", { params });
          if (res.data.data.length) e.lock = false;
        }
      });
    },
    setActive(v) {
      console.log("set active", this.parent, v);
      this.active = this.parent == v;
    },
    async getCourse(p) {
      let params = { filters: { parent_id: p } };
      const d = await this.$axios.get(this.m.api, { params });
      if (d.data && d.data.data) {
        let data = d.data.data;
        data.forEach((e) => {
          e["lock"] = !!e.data?.rules;
        });
        this.data = data;
      }
      this.isLock();
      this.$emit(
        "child",
        this.data.map((d) => d.id)
      );
      this.loading = false;
    },
  },
};
</script>
<style scoped lang="scss">
//@import "@/style/education.scss";
</style>
