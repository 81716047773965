/**
 * /* eslint-disable no-console
 *
 * @format
 */

import { register } from 'register-service-worker';
import { notificationBus } from '@/libs/notificationBus';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log('App is being served from cache by a service worker.\n' + 'For more details, visit https://goo.gl/AFskqB');
    },
    registered(registration) {
      console.log('Service worker has been registered.');
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        window.location.reload();
      });
      if (registration.waiting) {
        setTimeout(() => {
          notificationBus.$emit('newVersionAvailable', { registration });
        }, 2000);
      }
    },
    cached() {
      console.log('Content has been cached for offline use.');
    },
    updatefound() {
      console.log('New content is downloading.');
    },
    updated(registration) {
      console.log('New content is available; please refresh.');
      notificationBus.$emit('newVersionAvailable', { registration });
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.');
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    },
  });
}
