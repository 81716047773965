<template>
  <v-dialog v-model="dialog" width="500" @keydown.esc="dialog = false">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" :disabled="disabled" class="px-2" :title="title" v-bind="attrs" v-on="on">
        <v-icon>{{ icon }}</v-icon>
        <btn-title v-if="$vuetify.breakpoint[`${size}AndUp`] && title && false">{{ title }}</btn-title>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="lighten-2">
        <span>
          {{ title }}
        </span>
        <v-btn style="position: absolute; right: 12px; top: 12px" fab dark small color="blue-grey" title="Закрыть окно" @click="dialog = false"
          ><v-icon dark> mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="pb-0">
            <a-form-model v-model="data" :model="model" :errors="errors" :config="{ dense: true }" @validate="validate($event)" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <a-btn-save @click="submit()" :title="'Применить'"></a-btn-save>
        <v-btn @click="dialog = false">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getForm, submitForm, removeEl } from "@/components/mixings";
export default {
  mixins: [getForm, submitForm, removeEl],
  props: {
    value: Object,
    size: { type: String, default: "sm" },
    title: { type: String, default: "Настройки" },
    disabled: { type: Boolean, default: false },
    icon: { type: String, default: "fas fa-cog" },
    model: { type: Array, default: () => [] },
  },
  data() {
    return {
      dialog: false,
    };
  },
  created() {
    this.fetchData();
  },
  watch: {
    dialog(v) {
      if (v) {
        this.fetchData();
      }
    },
  },
  methods: {
    fetchData() {
      for (const m of this.model) {
        this.$set(this.data, m.name, this.value?.[m.name] || null);
      }
    },
    async submit() {
      this.$emit("onConfig", this.data);
      this.$emit("input", this.data);
      this.dialog = false;

      return true;
    },
  },
};
</script>
