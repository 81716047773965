/** @format */

const documentGoodsReserve = {
  title: 'Резервирование',
  accessName: 'goodsReserve',
  api: '/accounting/doc/goods_reserve',
  apiStatus: '/accounting/doc/goods_reserve',
  accDocName: 'AccountingDocGoodsReserveModel',
  statuses: [
    { value: 1, text: 'не проведен', next: [2], action: 'Распровести' },
    { value: 2, text: 'проведен', next: [1], action: 'Провести' },
    { value: 4, text: 'проведен', next: [1], action: 'Провести4' },
    { value: 6, text: 'проведен', next: [1], action: 'Провести6' },
  ],
  operationType: [
    { value: 24, text: 'Резервирование ' },
    { value: 25, text: 'Снятие с резерва' },
  ],
  list: [
    { name: 'id', title: 'id', type: 'number', sortable: true, width: 30, hidden: true },
    { name: 'code_doc', title: 'Номер', type: 'string', sortable: true, width: 50 },
    { name: 'date_doc', title: 'Дата', type: 'datetime', sortable: true, width: 120 },
    { name: 'status', title: 'Статус', type: 'select', sourceList: 'statuses' },
    { name: 'operation_type', title: 'Операция', type: 'select', sourceList: 'operationType', sortable: true, width: 120 },
    { name: 'object_id', title: 'Объект', type: 'select', dir: 'object', sortable: true, width: 150 },
    {
      name: 'createdby_id',
      title: 'Автор',
      type: 'select',
      dir: 'user',
      filter: { name: 'createdby_id', type: 'selectapi', api: '/manager/users/select' },
      sortable: true,
      width: 150,
    },
  ],

  form: [
    { name: 'date_doc', title: 'Дата документа', type: 'datetime', validator1: ['req'] },
    { name: 'code_doc', title: 'Номер Документа', type: 'string' },
    { name: 'operation_type', title: 'Операция', type: 'select', sourceList: 'operationType', validator: ['req'] },
    { name: 'object_id', title: 'Объект', type: 'select', dir: 'object', sortable: true, width: 150 },
    { name: 'status', title: 'Статус', validator: ['req'], default: 1 },
    { name: 'comments', title: 'Заметки', type: 'text', rows: 2 },
    { name: 'createdby_id', title: 'Автор', type: 'select', dir: 'user', filter: { name: 'createdby_id', type: 'selectapi', api: '/manager/users/select' } },
  ],
  form_0: [{ name: 'operation_type', title: 'Операция', type: 'select', sourceList: 'operationType', validator: ['req'] }],
  form_24: [
    { name: 'date_doc', title: 'Дата документа', type: 'datetime', validator1: ['req'] },
    { name: 'code_doc', title: 'Номер Документа', type: 'string', roleShow: [1000] },
    { name: 'operation_type', title: 'Операция', type: 'select', sourceList: 'operationType', validator: ['req'], hidden1: true },
    { name: 'object_id', title: 'На объект', type: 'select', dir: 'object', sortable: true, width: 150 },
    { name: 'status', title: 'Статус', validator: ['req'], default: 1 },
    { name: 'comments', title: 'Заметки', type: 'text', rows: 2 },
  ],
  form_25: [
    { name: 'date_doc', title: 'Дата документа', type: 'datetime', validator1: ['req'] },
    { name: 'code_doc', title: 'Номер Документа', type: 'string', roleShow: [1000] },
    { name: 'operation_type', title: 'Операция', type: 'select', sourceList: 'operationType', validator: ['req'], hidden1: true },
    { name: 'object_id', title: 'С объекта', type: 'select', dir: 'object', sortable: true, width: 150 },
    { name: 'status', title: 'Статус', validator: ['req'], default: 1 },
    { name: 'comments', title: 'Заметки', type: 'text', rows: 2 },
  ],

  createForm: {
    fields: 'operation_type,object_id,status,comments',
    fieldsRO: '',
    fieldsForm: '',
  },
  editForm: {
    fields: 'code_doc,date_doc,operation_type,object_id,status,comments',
    fieldsRO: '',
    fieldsForm: '',
  },
  viewForm1: {
    fields: 'operation_type,object_id',
    fieldsRO: '',
    fieldsForm: '',
  },
  viewForm2: {
    fields: 'date_doc,createdby_id,comments',
    fieldsRO: '',
    fieldsForm: '',
  },
  listDetail: [
    { name: 'actions', width: 40 },
    { name: 'id', title: 'id', type: 'number', sortable: true, width: 30 },
    { name: 'good_id', type: 'number', hidden: true, sortable: true, width: 30 },
    { name: 'good_name', title: 'Наименование', type: 'string', sortable: true, width: 300 },
    { name: 'amount', title: 'Кол-во', type: 'number', validator: ['req'], sortable: true, width: 50 },
    { name: 'value', title: 'Стоимость', type: 'number', sortable: true, width: 100 },
    { name: 'store_id', title: 'Склад', type: 'select', dir: 'storage', sortable: true, width: 200 },
    { name: 'object_id', title: 'для объекта...', type: 'select', dir: 'object', sortable: true, width: 200 },
    { name: 'buying_id', title: 'Покупка', type: 'number', sortable: true, width: 100 },
    { name: 'comments', title: 'Заметки', type: 'text', style: 'white-space: normal;', width: 250 },
  ],
  formDetail: [
    { name: 'amount', title: 'Кол-во', type: 'number', validator: ['req', 'min'], min: 1, default: 1 },
    { name: 'value', title: 'Cтоимость', type: 'number', validator: ['req'], readonly: true },
    { name: 'comments', title: 'Заметки', type: 'text', rows: 2 },
  ],
};
export default documentGoodsReserve;
