<template>
  <div>
    <div v-if="model.chips" class="d-flex flex-wrap">
      <v-chip v-for="v in value" :key="v" small :color="v.color">{{ options.find(f => f.value == v).text }}</v-chip>
    </div>
    <v-badge v-else :value="badge" :content="badge" inline color="green">
      <div :style="background + style" v-html="textByValue"></div>
    </v-badge>
  </div>
</template>

<script>
import { modelOptions } from "./../../../mixings";

export default {
  mixins: [modelOptions],
  props: {
    value: [String, Number, Array],
    values: [Object],
    model: {
      type: Object,
      default: {},
    },
  },
  computed: { 
    textByValue() {
      return this.getTextByValue(this.value, this.model);
    },

    options() {
      return this.getOptions(this.model);
    },
    badge() {
      if (this.model.hasOwnProperty("badgeField") && this.model?.["badgeField"]) return this.values[this.model.badgeField];
      return false;
    },
    background() {
      let value = this.value;
      if (this.model?.aliace) {
        value = this.values?.[this.model.aliace] || this.value;
      }
      let text = this.getElementByValue(value, this.model);
      return text?.bgColor ? "background-color:" + text.bgColor : "";
    },
    style() {
      let style = "";
      if (this.model?.style) style = this.model?.style;
      return style;
    },
  },
};
</script>
