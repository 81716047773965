<template>
  <v-dialog v-model="dialog" max-width="800" @keydown.esc="dialog = false" persistent scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-show="isData" outlined color="primary" :disabled="disabled" class="px-2" :title="model.title" v-bind="attrs" v-on="on">
        <btn-title>{{ model.title }}</btn-title>
      </v-btn>
    </template>
    <v-card height="auto">
      <v-card-title class="lighten-2">
        <v-btn style="position: absolute; right: 12px; top: 12px" fab dark small color="blue-grey" title="Закрыть окно" @click="dialog = false"
          ><v-icon dark> mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text height="100px">
        <v-row class="mt-4">
          <v-col cols="12" class="pb-0">
            <a-form-model v-model="values" :model="model.model" :errors="errors" :config="{ dense: true }" @validate="validate($event)" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  mixins: [],
  props: {
    value: [String, Array, Object, Boolean],
    values: {
      type: Object,
      default: () => {},
    },
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    config: { type: Object, default: {} },
  },
  data() {
    return {
      dialog: false,
      errors: {},
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    isData() {
      if (this.value) return true;
      return false;
    },
  },
  watch: {},
  methods: {
    fetchData() {},
  },
};
</script>
