/** @format */

const rent = {
  title: 'Аренда недвижимости',
  accessName: 'rent',
  api: '/mechti/rent',
  apiStatus: '/mechti/rent',
  accDocName: 'RentModel',
  statuses: [
    { value: 1, text: 'Сдается', next: [2, 3], action: 'Освободить', field: 'status' },
    { value: 2, text: 'Сдан', next: [1], action: 'Сдать в аренду', field: 'status' },
    { value: 3, text: 'Не активный', next: [0], action: 'Завершить аренду', field: 'status' },
    { value: 0, text: 'Не активный', next: [0], action: 'Возобновить аренду', field: 'status', setValue: 1 },
  ],
  operationsCash: { in: [26], out: [30, 31, 73] },
  operationType__: [
    { value: 26, text: 'Прием средств за аренду' },
    { value: 30, text: 'Выплата средств арендодателю' },
  ],
  list: [
    { name: 'id', title: '#', type: 'number', sortable: true, width: 30 },
    { name: 'name', title: 'Название', type: 'string', sortable: true, width: 150 },
    { name: 'city', title: 'Город', type: 'string', filter: { name: 'city', type: 'select', options: 'city' }, sortable: true, width: 150 },
    { name: 'address', title: 'Адрес', type: 'string', sortable: true, width: 300, style: 'white-space: normal;' },
    { name: 'status', title: 'Статус', type: 'select', sourceList: 'statuses', sortable: true },
    { name: 'landlord_client_id', title: 'Арендодатель', type: 'select', dir: 'client', style: 'white-space: normal;', sortable: true, width: 150 },
    { name: 'renter_client_id', title: 'Арендатор', type: 'select', dir: 'client', style: 'white-space: normal;', sortable: true, width: 150 },
  ],

  form: [
    { name: 'name', title: 'Название', type: 'string', validator: ['req'], autofocus: true },
    { name: 'city', title: 'Город', validator: ['req'], type: 'select', options: 'city' },
    { name: 'address', title: 'Адрес', validator: ['req'], type: 'string' },
    { name: 'status', title: 'Статус', validator: ['req'], default: 1 },
    { name: 'photos', title: 'Фото галерея', type: 'images' },
    { name: 'landlord_client_id', title: 'Арендодатель', type: 'selectapi', api: 'mechti/clients/select', optionsFilter: { client_type: 'bit#6' } },
    { name: 'renter_client_id', title: 'Арендатор', type: 'selectapi', api: 'mechti/clients/select', optionsFilter: { client_type: 'bit#5' } },
    { name: 'amount_renter', title: 'Сумма для арендатора', validator: ['req'], type: 'number' },
    { name: 'amount_landlord', title: 'Сумма выплаты арендодателю ', validator: ['req'], type: 'number' },
    { name: 'docs', title: 'Документация', type: 'filesdata', category: 1, hiddenCreate: true },
    { name: 'filesAdd', default: [], hidden: true },
  ],

  createForm: {
    fields: 'name,city,address,status,landlord_client_id',
    fieldsRO: '',
    fieldsForm: 'name,city,address,landlord_client_id',
  },
  editForm: {
    fields: 'name,city,address,status,landlord_client_id,renter_client_id,docs,photos,amount_renter,amount_landlord',
    fieldsRO: '',
    fieldsForm: 'name,city,address,landlord_client_id#6,amount_landlord#6,renter_client_id#6,amount_renter#6,photos,docs',
  },
  viewForm1: {
    fields: 'city,address,landlord_client_id,renter_client_id,amount_renter,amount_landlord',
    fieldsRO: '',
    fieldsForm: '',
  },
};
export default rent;
