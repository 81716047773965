/** @format */
import { status, statusFin } from './libs';
const sales = {
  api: '/mechti/objects/broker',
  statuses___: [
    { value: 0, text: 'Новое', next: [1], action: '', field: 'status_fin' },
    { value: 1, text: 'На продаже', next: [-1, 2], action: 'Выставить на продажу', field: 'status_fin' },
    { value: -1, text: 'Снято с продажи', next: [1], action: 'Снять с продажи', field: 'status_fin' },
    { value: 2, text: 'Продано', next: [2], action: 'Продать', field: 'status_fin', func: 'statusSale' },
    { value: 1, text: 'Не публикуется сайте', next: [2], action: 'Убрать с сайта', field: 'status_site' },
    { value: 2, text: 'Публикуется сайте', next: [1], action: 'На сайт', field: 'status_site' },
    { value: 0, text: 'Публикация не настроена', next: [2], action: '', field: 'status_site' },
    { value: null, text: 'Публикация не настроена', next: [2], action: '', field: 'status_site' },
  ],

  objects: 'id,actions,name,city,address,ap_rooms,ap_ceiling_height,type,class,status,status_fin',
  lampObjects: 'id,actions,name,city,address,ap_rooms,ap_ceiling_height,type,class,status',
  agentObjects: 'id,actions,name,city,address,ap_rooms,ap_ceiling_height,type,class,status',
  list: [
    { name: 'id', title: '#', type: 'id', sortable: true, width: 50 },
    { name: 'actions', title: '', buttons: [{ title: 'Презентация', icon1: 'mdi-file-pdf-box', icon: 'fas fa-file-export', url: '' }], width: 50 },
    { name: 'name', title: 'Название', type: 'string', sortable: true, width: 200 },
    { name: 'city', title: 'Город', type: 'string', sortable: true, width: 200 },
    { name: 'address', title: 'Адрес', type: 'string', sortable: true, width: 300 },
    { name: 'ap_rooms', title: 'Количество комнат', type: 'string', sortable: true, width: 50 },
    { name: 'type', title: 'Тип', type: 'select', sortable: true, width: 80, options: 'objectTypes' },
    { name: 'class', title: 'Класс', type: 'select', options: 'objectClasses', sortable: true, width: 80 },
    { name: 'status', title: 'Статус', type: 'select', options: 'status_object', sortable: true, width: 100 },
    { name: 'status_fin', title: 'Продажа', type: 'select', options: statusFin, sortable: true, width: 100 },
    { name: 'ap_ceiling_height', title: 'Высота потолков', type: 'string', sortable: true, width: 100 },
  ],
  form: [
    { name: 'name', title: 'Название', type: 'string', validator: ['req'], autofocus: true },
    { name: 'source', title: 'Источник', type: 'comboboxapi', api: '/mechti/objects/tags/source', chips: false, validator: ['req'] },
    { name: 'city', title: 'Город', validator: ['req'], type: 'comboboxapi', api: '/mechti/objects/tags/city', chips: false, rating: true },
    { name: 'address', title: 'Адрес', validator: ['req'], type: 'string', rating: true },
    { name: 'class', title: 'Класс', type: 'select', options: 'objectClasses', rating: true },
    { name: 'type', title: 'Тип', type: 'select', options: 'objectTypes', validator: ['req'], rating: true },
    { name: 'ap_price_buy', title: 'Стоимость квартиры', validator: ['req'], type: 'number', rating: true },
    { name: 'ap_area', title: 'Площадь', type: 'number', validator: ['req'], rating: true },
    { name: 'ap_kitchen_area', title: 'Кухня м2', type: 'number', validator: ['req', 'min#1'] },
    { name: 'ap_rooms', title: 'Количество комнат', type: 'number', rating: true },
    { name: 'ap_ceiling_height', title: 'Высота потолков', type: 'number', rating: true },
    { name: 'ap_layout', title: 'Планировка', type: 'text', rating: true },
    { name: 'ap_level', title: 'Уровней', type: 'number', rating: true },
    { name: 'ap_floor', title: 'Этаж', type: 'number', rating: true },
    { name: 'ap_view', title: 'Вид из окна', type: 'text', rating: true },
    { name: 'infrastructure', title: 'Инфраструктура', type: 'comboboxapi', api: '/mechti/objects/tags/infrastructure', chips: true, rating: true },
    { name: 'metro', title: 'Метро', type: 'comboboxapi', api: '/mechti/objects/tags/metro', rating: true },
    { name: 'nearby', title: 'в 5 мин доступности', type: 'comboboxapi', api: '/mechti/objects/tags/nearby', chips: true, default: [], rating: true },
    { name: 'description', title: 'Описание', type: 'text' },
    { name: 'photos', title: 'Фото галерея', type: 'images' },
    { name: 'photos_design', title: 'Дизайн-проект', type: 'images' },
    { name: 'docs', title: 'документация', type: 'files' },
    { name: 'doc_adv', title: 'Рекламные материалы', type: 'filesdata', category: 2, hiddenCreate: true },
    { name: 'status', title: 'Статус', type: 'select', options: 'status_fin,status_site', default: 10 },
    { name: 'vendor', title: 'Продавец', from: 'data' },
    { name: 'client_id', title: 'Клиент', type: 'selectapi', api: '/mechti/investors/select' },
    { name: 'investor_id', title: 'Инвестор', type: 'selectapi', api: '/mechti/investors/select' },
    { name: 'plan_sell_price', title: 'Стоимость продажи (план)', type: 'number', size: 6 },
    { name: 'floor', title: 'Этаж', type: 'string', formula: '((d)=>{return `${d?.ap_floor} / ${d?.building_floors}`;})', calculated: true },
  ],
  form1_: [
    { name: 'name', title: 'Название', type: 'string', validator: ['req'], autofocus: true },
    { name: 'source', title: 'Источник', type: 'comboboxapi', api: '/mechti/objects/tags/source', chips: false, validator: [] },
    { name: 'city', title: 'Город', validator: ['req'], type: 'comboboxapi', api: '/mechti/objects/tags/city', chips: false },
    { name: 'address', title: 'Адрес', validator: ['req'], type: 'string' },
    { name: 'class', title: 'Класс', type: 'select', options: 'objectClasses' },
    { name: 'type', title: 'Тип', type: 'select', options: 'objectTypes', validator: ['req'] },
    { name: 'ap_price_buy', title: 'Стоимость квартиры', validator: ['req'], type: 'number' },
    { name: 'ap_area', title: 'Площадь', type: 'number', validator: ['req'], rating: true },
    { name: 'ap_rooms', title: 'Количество комнат', type: 'number' },
    { name: 'ap_ceiling_height', title: 'Высота потолков', type: 'number' },
    { name: 'ap_layout', title: 'Планировка', type: 'text' },
    { name: 'ap_level', title: 'Уровней', type: 'number' },
    { name: 'ap_floor', title: 'Этаж', type: 'number' },
    { name: 'ap_view', title: 'Вид из окна', type: 'text' },
    { name: 'infrastructure', title: 'Инфраструктура', type: 'comboboxapi', api: '/mechti/objects/tags/infrastructure', chips: true },
    { name: 'metro', title: 'Метро', type: 'comboboxapi', api: '/mechti/objects/tags/metro', chips: true },
    { name: 'nearby', title: 'в 5 мин доступности', type: 'comboboxapi', api: '/mechti/objects/tags/nearby', chips: true, default: [] },
    { name: 'description', title: 'Описание', type: 'text' },
    { name: 'photos', title: 'Фото галерея', type: 'images' },
    { name: 'photos_design', title: 'Дизайн-проект', type: 'images' },
    { name: 'status', title: 'Статус', type: 'select', options: status, default: 10 },
    { name: 'status_fin', title: 'Финансовый статус', type: 'select', options: statusFin },
    { name: 'vendor', title: 'Продавец', from: 'data' },
    { name: 'client_id', title: 'Клиент', type: 'selectapi', api: '/mechti/investors/select' },
    { name: 'object_design', title: 'Дизайнер проекта' },
    { name: 'ap_price_sell', title: 'Стоимость продажи', type: 'number', disabled: true },
  ],
  viewForm1: {
    fields: 'name,city,address,ap_rooms,floor,ap_area,ap_kitchen_area,class,plan_sell_price',
  },
  config: {
    default: {
      title: 'Данные объекта',
      fields:
        'name,city,address,class,type,ap_area,ap_rooms,ap_ceiling_height,ap_layout,ap_level,ap_floor,ap_view,infrastructure,metro,nearby,description,photos,photos_design,doc,doc_adv,ap_price_sell,floor ',
      fieldsRO:
        'name,city,address,class,type,ap_area,ap_rooms,ap_ceiling_height,ap_layout,ap_level,ap_floor,ap_view,infrastructure,metro,nearby,description,photos,photos_design,doc,doc_adv,ap_price_sell',
      fieldsForm:
        'name,city,address,class#6,type#6,ap_area#4,ap_rooms#4,ap_ceiling_height#4,ap_level#4,ap_floor#4,floor,ap_price_sell#4,ap_layout,ap_view,infrastructure,metro,nearby,description,photos,photos_design,doc,doc_adv',
    },
  },
};
export default sales;
