/** @format */
const hidden = true;
const autocomplete = true;
const model = {
  title: 'Журнал закрытия объектов',
  routeName: 'objectClose_view',
  accessName: 'objectClose',
  accDocName: 'AccountingDocCloseObject',
  api: '/accounting/doc/close_object',
  statuses: [
    { value: 0, text: 'черновик', next: [2], action: 'открыть' },
    { value: 2, text: 'Зафиксирован', next: [0, 3], action: 'Зафиксировать расходы' },
    { value: 3, text: 'Распределен', next: [4], action: 'Распределить прибыль' },
    { value: 4, text: 'Закрыт', next: [], action: 'Закрыть' },
  ],
  list: [
    { name: 'id', title: 'id', type: 'number', sortable: true, width: 30, hidden: true },
    { name: 'createdon', title: 'Дата', type: 'datetime', sortable: true, width: 120 },
    { name: 'object_id', title: 'Объект', type: 'select', dir: 'object', sortable: true, width: 120 },
    { name: 'status', title: 'Статус', type: 'select', sourceList: 'statuses', sortable: true, width: 150 },
    { name: 'createdby_id', title: 'Автор', type: 'select', dir: 'user', sortable: true, width: 180, style: 'white-space: normal;' },
  ],
  listGoods: [
    { name: 'id', title: 'id', type: 'number', sortable: true, width: 30, hidden: true },
    { name: 'code_doc', title: 'Номер', type: 'string', sortable: true, width: 50 },
    { name: 'date_doc', title: 'Дата', type: 'datetime', sortable: true, width: 120 },
    { name: 'createdby_id', title: 'Кем создан', type: 'select', dir: 'user', sortable: true, width: 150 },
    { name: 'total_price', title: 'Сумма', type: 'number', sortable: true, width: 80 },
    { name: 'balance', title: 'Долг', type: 'number', sortable: false, width: 80, hidden },
    { name: 'result', title: '', type: 'string', sortable: true, width: 250, style: 'color: red;' },
  ],
  listService: [
    { name: 'id', title: 'id', type: 'number', sortable: true, width: 30, hidden: true },
    { name: 'code_doc', title: 'Номер', type: 'string', sortable: true, width: 50 },
    { name: 'date_doc', title: 'Дата', type: 'datetime', sortable: true, width: 120 },
    { name: 'createdby_id', title: 'Кем создан', type: 'select', dir: 'user', sortable: true, width: 150 },
    { name: 'total_price', title: 'Сумма', type: 'number', sortable: true, width: 80 },
    { name: 'balance', title: 'Долг', type: 'number', width: 80, hidden },
    { name: 'result', title: '', type: 'string', sortable: true, width: 250, style: 'color: red;' },
  ],
  listStore: [
    { name: 'id', title: 'id', type: 'number', sortable: true, width: 30, hidden: true },
    { name: 'store_name', title: 'Место нахождения', type: 'string', sortable: true, width: 150 },
    { name: 'category', title: 'Категория', type: 'string', sortable: true, width: 150 },
    { name: 'name', title: 'Наименование', type: 'string', sortable: true, width: 300 },
    { name: 'amount', title: 'Кол-во', type: 'number', sortable: true, width: 80 },
    { name: 'value', title: 'Сумма', type: 'number', sortable: true, width: 80 },
    { name: 'result', title: '', type: 'string', sortable: true, width: 250, style: 'color: red;' },
  ],
  viewForm1: {
    fields: 'object_id,object.city,object.address,object.investor_id',
  },
  viewForm2: {
    fields: 'createdon,createdby_id,comments,template_id',
  },
  form: [
    { name: 'object_id', title: 'Объект', type: 'select', dir: 'object', autocomplete: true, isMenu: true },
    { name: 'object.city', title: 'Город', type: 'string' },
    { name: 'status', title: 'Статус', default: 0 },
  ],

  config: {
    default: {
      fields: 'object_id,status',
      fieldsRO: '',
      fieldsForm: 'object_id',
    },
  },
};
export default model;
