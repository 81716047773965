<template>
  <a-input :value="displayValue" :label="model.title">
    <template v-slot:default>
      <v-icon>mdi-calendar</v-icon>
      <div class="d-inline-block">
        <div class="d-inline-block">
          <a-input-base-date-picker v-model="date" :readonly="readonly" :text="text" />
        </div>
      </div>
      <div class="d-inline-block pl-5">
        <v-icon>mdi-clock</v-icon>
        <div class="d-inline-block">
          <a-input-base-time-picker v-model="time" :readonly="readonly" />
        </div>
      </div>
    </template>
  </a-input>
</template>

<script>
export default {
  props: {
    value: String | Date,
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },

    config: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      text: "Не указано",
      displayValue1: null,
    };
  },
  computed: {
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
    displayValue() {
      let res = this.date + " " + this.time;
      return res;
    },
    dateObject() {
      if (this.value instanceof Date) {
        console.log("date is date");
        this.text = new Date(this.value).date;
        return this.value;
      } else if (typeof this.value == "string") {
        console.log("date is string");
        this.text = new Date(this.value).date;
        return new Date(this.value);
      }
      // return null;
      return new Date();
    },

    time: {
      get: function () {
        return this.dateObject?.time;
      },
      set: function (v) {
        //const date = new Date(this.date + " " + v).toUTCString();
        const date = new Date(this.date + " " + v).toISOString();
        console.log("date set", date);
        //const date = this.date + " " + v;
        this.$emit("input", date);
      },
    },
    date: {
      get: function () {
        return this.dateObject?.date;
      },
      set: function (v) {
        const date = new Date(v + " " + this.time).toISOString();
        //const date = new Date(v + " " + this.time).toUTCString();
        //const date = v + " " + this.time;
        this.$emit("input", date);
      },
    },
  },
};
</script>
