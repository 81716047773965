<template>
  <div>
    <dialog-edit2 :value="value" @input="$emit('input')" :maxWidth="'400px'">
      <template v-slot:title> Настройки доступа к файлу</template>
      <v-row>
        <v-col cols="12" class="pb-0">
          <a-form-model v-model="data" :model="model" :errors="errors" :config="{ dense: true }" @validate="validate($event)" />
        </v-col>
      </v-row>
      <template v-slot:actions>
        <v-btn v-if="$root.profile.role == 1000" :href="$root.config.fileUrl + data.file" target="_blank" class="mx-2" fab dark x-small color="error" title="Скачать">
          <v-icon dark> mdi-download </v-icon>
        </v-btn>

        <v-spacer></v-spacer>
        <a-btn-save @click="submit()" :title="'Записать'"></a-btn-save>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit2>
  </div>
</template>

<script>
import { getForm, submitForm, removeEl } from "@/components/mixings";
export default {
  mixins: [getForm, submitForm, removeEl],

  props: {
    value: Boolean,
    id: Number,
    model1: [Object, Array],
  },
  data() {
    return {
      model: [
        { name: "name", title: "Имя файла", type: "string" },

        {
          name: "createdon",
          title: "Дата загрузки",
          type: "string",
          readonly: true,
        },
        {
          name: "createdby_id",
          title: "Кто загрузил",
          type: "select",
          dir: "user",
          readonly: true,
        },
        {
          name: "group_id",
          title: "Группа доступа",
          type: "selectapi",
          api: "/uploads-group/select",
        },
        {
          name: "accessCan",
          title: "+ Пользователь",
          type: "selectuser",
          dir: "user",
          chips: true,
        },
        {
          name: "accessReject",
          title: "- Пользователь",
          type: "selectuser",
          dir: "user",
          chips: true,
        },
      ],
    };
  },
  computed: {},
  created() {
    this.fetchData();
  },
  watch: {
    value(v) {
      console.log("watch value", v);
      if (v) {
        this.fetchData();
      }
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      let response = await this.$axios.get("/uploads/" + this.id);

      this.loading = false;
      this.data = response.data.data;
    },
    async submit() {
      let data = {
        id: this.id,
        group_id: this.data.group_id,
        accessCan: this.data.accessCan,
        accessReject: this.data.accessReject,
      };
      await this.post(data, "/uploads");
      this.$emit("input");

      return true;
    },
  },
};
</script>
