<template>
  <dialog-edit v-if="dialog" :value="dialog" @input="dialog = !true" :maxWidth="'100hv'">
    <template v-slot:title>
      {{ params.title }}
    </template>
    <v-card-text style="height: calc(100vh - 155px); overflow: auto" class="pa-0 list-card1">
      <a-table-f-api
        v-if="type == 'api'"
        ref="table"
        :api="params.api"
        :model="modelList"
        :useQuery="false"
        :limit="0"
        :defaults="defaults"
        @click="onClickRow($event)"
      />
      <a-table-f-sql v-if="type == 'sql'" ref="table" :query="params.sql" :model="modelList" :useQuery="false" :defaults="defaults" @click="onClickRow" />
    </v-card-text>
    <template v-slot:actions>
      <v-spacer></v-spacer>
      <a-btn-save
        @click="
          add();
          dialog = !true;
        "
        :title="'Выбрать'"
      />
      <v-btn
        @click="
          dialog = !true;
          $emit('input');
        "
        >Закрыть</v-btn
      >
    </template>
  </dialog-edit>
</template>

<script>
import { getAccess } from "@/components/mixings";

export default {
  components: {},
  mixins: [getAccess],
  props: {},
  data() {
    return {
      dialog: false,
      type: null,
      defaultParams: {
        type: "",
        title: "",
        api: "",
        model: [],
        multiple: false,
        sort: { key: "id", order: "DESC" },
        filters: {},
        where: {},
      },
      params: {},
    };
  },
  computed: {
    defaults() {
      let res = { sort: this.params?.sort || {}, filters: Object.assign({}, this.params.filters, this.params.where) };
      return res;
    },
    modelList() {
      let res = [...(this.params?.model || [])];
      if (this.params?.multiple) res = [{ name: "checked", type: "checkbox", width: 35 }, ...res];
      return res;
    },
  },
  created() {},
  watch: {},
  methods: {
    init(config) {
      this.type = null;
      let params = Object.assign({}, this.defaultParams, config);
      this.params = params;
      if (config?.api) this.type = "api";
      if (config?.sql) this.type = "sql";
      this.dialog = true;
    },

    onClickRow(d) {
      this.$refs.table.$refs.base.clickCheckbox(d.event, d.row);
      this.$refs.table.$refs.base.$forceUpdate();
    },
    add() {
      let rows = this.$refs.table.data.filter((el) => {
        return el?.checked;
      });
      console.log({ type: this.params.type, rows });
      this.$emit("save", { type: this.params.type, rows, params: this.params });
      this.$emit("input");
    },
  },
};
</script>

<style lang="scss">
.list-card {
  height: calc(100vh - 150px);
  overflow: auto;
}
</style>
