<template>
  <div class="d-flex" style="position: relative">
    <v-dialog ref="dialog" v-model="showPicker" :return-value="date" persistent width="290px" :disabled="readonly">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          outlined
          :value="text"
          :label="model.title"
          :error-messages="error"
          :disabled="disabled"
          :hide-details="config.hideDetails || false"
          readonly
          persistent-placeholder
          placeholder="Не указано"
          :clearable="!!value && !readonly"
          :dense="config.dense || false"
          :append-icon="!value ? 'mdi-calendar' : ''"
          @click="showPicker = true"
          @click:append="showPicker = true"
          @click:clear="
            $emit('input', null);
            initValue = null;
          "
        >
        </v-text-field>
      </template>
      <v-date-picker
        v-if="showPicker"
        :disabled="readonly"
        v-model="date"
        @dblclick:date="
          $emit('input', d);
          initValue = d;
          $refs.dialog.save();
        "
        full-width
      >
        <v-spacer></v-spacer>
        <v-btn
          @click="
            $emit('input', initValue);
            showPicker = false;
          "
          >Закрыть</v-btn
        >
        <v-btn
          v-if="!readonly"
          color="primary"
          @click="
            $emit('input', d);
            initValue = d;
            $refs.dialog.save();
          "
          >Записать</v-btn
        >
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    model: Object,
    config: { type: Object, default: {} },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      text1: null,
      d: null,
      initValue: this.value,
      showPicker: false,
    };
  },
  watch: {
    value1(v) {
      console.log("watch value", v);
      this.setText();
    },
  },
  created() {
    //this.setText();
  },
  computed: {
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
    text: {
      get() {
        //console.log("vvvv", this.value, this.value ? new Date(this.value).date : "Не указано");
        return this.setText();
      },
      set(v) {
        this.$emit("input", null);
      },
    },
    date: {
      get: function () {
        if (!this.value) {
          this.$emit("input", null);
          this.d = new Date().date;
          return this.d;
        }
        // this.text = new Date(this.value).date;
        this.d = new Date(this.value).datetime;
        return this.d;
      },
      set: function (v) {
        this.d = v;
        //  console.log("set date ", new Date(v).datetime);
        // this.text = new Date(v).date;
        this.$emit("input", new Date(v).datetime);
        // this.$emit("commit", v);
      },
    },
  },
  methods: {
    setText() {
      return this.value ? new Date(this.value).date : "";
      this.text = this.value ? new Date(this.value).date : "";
    },
  },
};
</script>
