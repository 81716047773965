<template>
  <div class="py-1 pb-3">
    <v-card>
      <v-card-title class="py-2">
        {{ model.title }}
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-simple-table style="width: 100%" :height="height" dense>
          <tbody>
            <tr v-for="(el, key) in getSortItems" :key="key">
              <td class="text-left">{{ key }}</td>
              <td class="text-left">{{ el }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    value: String | Array,

    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    config: { type: Object, default: () => {} },
  },
  data() {
    return {
      dialogEdit: false,
      editKey: 0,
      editItem: { value: "", text: "", order: 0 },
    };
  },
  computed: {
    height() {
      return this.$root.windowSize.innerHeight * 0.5;
    },
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
    items: {
      get() {
        let d;
        try {
          d = this.value;
        } catch (error) {
          d = [];
        }

        return d || [];
      },
      set(v) {
        this.$emit("input", v);
      },
    },
    getSortItems() {
      return this.items;
      return this.items.sort((a, b) => {
        return a.order > b.order - b.order > a.order;
      });
    },
  },
  methods: {
    remove(key) {
      let e = this.items;
      e.splice(key, 1);
      this.items = e;
    },
    edit(key) {
      this.editKey = key;
      this.editItem = this.items[key];
      this.dialogEdit = true;
    },

    add() {
      this.editKey = -1;
      this.editItem = { value: "", text: "", order: 0 };
      this.dialogEdit = true;
    },
    save() {
      let r = this.editItem;
      let el = this.items;
      //  if (!r?.order) r.order = 0;
      if (!Array.isArray(el)) el = [];
      if (this.editKey > -1) {
        el[this.editKey] = r;
      } else {
        el.push(r);
      }
      this.items = el;
      this.dialogEdit = false;
    },
  },
};
</script>
