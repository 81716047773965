/** @format */

export default {
  methods: {
    getObjectPath(o, path) {
      const p = path.split('.');
      let tmp;
      for (let i = 0; i < p.length; i++) {
        const el = p[i];
        if (i === 0) {
          tmp = JSON.parse(JSON.stringify(o || {}));
        }
        tmp = tmp?.[el];
      }
      return tmp;
    },
    getFilters(model, { value, values }) {
      if (!(model && model.filters)) return false;
      const filters = {};
      if (typeof model.filters === 'object') {
        for (const key in model.filters) {
          const f = model.filters[key];
          if (f.type == 'field') {
            filters[key] = values[f.value];
          }
        }
      }
      return filters;
    },
    getInitData(model, { value, values }) {
      if (!(model && model.initData)) return false;
      const initData = {};
      if (typeof model.filters === 'object') {
        for (const key in model.initData) {
          const f = model.initData[key];
          if (f.type == 'field') {
            initData[key] = values[f.value];
          }
        }
      }
      return initData;
    },
  },
};
