<template>
  <div>
    <v-snackbar v-model="snackbar" outlined top timeout="-1">
      <a-form-view v-if="typeView == 2" :value="data" :model="model" :config="{ dense: true }" />
      <v-card-text v-if="typeView == 1" style="height: 150px; overflow: auto" class="pa-0">
        <a-table-f-data2 ref="table" :dataTable="data" :model="model" :searchable="false" />
      </v-card-text>

      <v-simple-table dense v-if="type == 3">
        <thead>
          <tr>
            <th v-for="(el, i) in model" :key="i" class="text-left">
              {{ el.title }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, r) in data" :key="r">
            <td v-for="(el, i) in model" :key="i" class="text-left">
              <component v-if="el.type" :is="'a-view-' + el.type" :model="el" :values="row" :value="el.value || row[el.name]" />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: {
    model: { type: Array, default: [] },
    data: [Array, Object],
    type: { type: Number, default: 0 },
    id: Number,
  },
  data: function () {
    return {
      snackbar: true,
      timer: null,
      color: null,
      snackbars: [],
      // data: {},
    };
  },
  created() {
    console.log("createdSnack", this.data, this.model);
  },
  computed: {
    typeView() {
      if (this.type) return this.type;
      if (!this?.data) return 0;

      if (Array.isArray(this.data)) return 1;
      if (typeof this.data === "object") return 2;
    },
  },
  watch: {
    id(v) {
      if (v) {
        this.getRow();
      }
    },
  },
  methods: {
    async getRow(url) {
      return;
      if (!this.id) return;
      const d = await this.$axios.get(this.url + "/" + this.id);
      if (d.data && d.data.data) {
        this.data = d.data.data;
      }
    },
  },
};
</script>
