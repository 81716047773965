var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('dialog-edit2',{attrs:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input')}},scopedSlots:_vm._u([{key:"title",fn:function(){return [(_vm.id)?_c('small'):_vm._e(),_vm._v(" "+_vm._s(_vm.title)+" ")]},proxy:true},{key:"actions",fn:function(){return [_c('v-spacer'),(_vm.editComment)?_c('a-btn-save',{attrs:{"icon":_vm.id ? 'far fa-save' : `fas fa-paper-plane`,"title":_vm.id ? 'Сохранить' : 'Отправить'},on:{"click":function($event){return _vm.submit()}}}):_vm._e(),(_vm.id && _vm.editComment)?_c('a-btn-delete',{on:{"click":function($event){_vm.$emit('delete', _vm.data);
          _vm.$emit('input');}}}):_vm._e(),_c('v-btn',{on:{"click":function($event){return _vm.$emit('input')}}},[_vm._v("Закрыть")])]},proxy:true}])},[(_vm.parent)?_c('a-form-view',{attrs:{"value":_vm.parent,"model":[
        {
          name: 'content',
          title: 'Ответ на комментарий',
          type: 'text',
        },
      ],"config":{ dense: true }}}):_vm._e(),_c('a-form-model',{attrs:{"model":_vm.model,"errors":_vm.errors,"config":{ dense: true }},on:{"validate":function($event){return _vm.validate($event)}},model:{value:(_vm.data),callback:function ($$v) {_vm.data=$$v},expression:"data"}})],1),(_vm.getAccess('suData'))?_c('su-Data',{ref:"suEdit",attrs:{"api":_vm.api,"id":_vm.id,"activate":_vm.value,"dataEdit":_vm.data},on:{"show":function($event){_vm.suEditShow = true}},model:{value:(_vm.suEditShow),callback:function ($$v) {_vm.suEditShow=$$v},expression:"suEditShow"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }