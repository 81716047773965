<template>
  <v-autocomplete
    v-if="loaded"
    class="f-search"
    solo
    full-width
    append-icon=""
    :value="searchValue"
    @input="setFilter"
    @change="setFilter"
    @keydown="onKeyDown"
    :items="items_"
    dense
    item-text="text"
    item-value="value"
    @click:clear.prevent="clearValue()"
    @click:append="append()"
    @click="getList()"
  >
    <template v-slot:item="{ item }">
      <span class="f-search-option">{{ item.text }}</span>
    </template>
  </v-autocomplete>
</template>

<script>
import modelOptions from "@/components/libs/modelOptions";

export default {
  mixins: [modelOptions],
  props: {
    value: [String, Number, Array, Object],
    model: Object,
  },
  data() {
    return {
      field: null,
      showAllItem: false,
      list: [],
      loaded: !true,
    };
  },
  created() {
    this.getList();
  },
  watch: {},
  computed: {
    searchValue: {
      get() {
        let res = this.value != parseInt(this.value) ? this.value : parseInt(this.value);
        if (res === undefined || res == "null" || res === "") res = "***";
        return res;
      },
      set(v) {
        if (v == "***" || v === null) v = "";
        if (typeof v === "number") v = v.toString();
        this.$emit("change", v);
      },
    },
    items_() {
      let res = [{ text: this.$t("All"), value: "***" }];
      if (!this.list || !this.list.length) {
        this.getList();
      }
      const list = this.list.filter((e) => !this.checkDisabled(e));
      res = [...res, ...list];
      //console.log("getSortOptions", this.model.name, res);
      return res;
    },
  },
  methods: {
    async fitchData() {
      let params = {};
      if (this.model.sort) {
        params.sort = this.model.sort;
      }
      if (this.model.filters) {
        params.filters = this.model.filters;
      }
      let response = await this.$axios.get(this.model.api, { params });
      return response.data.data;
    },
    async getList() {
      let list = [];
      if (this.model.api) {
        if (!this.list.length) {
          list = await this.fitchData();
          //console.log("api", this.model.name, list);
        } else {
          return;
        }
      } else {
        list = JSON.parse(JSON.stringify(this.getFilterOptions(this.model) || []));
      }
      let res = [];
      list.forEach((l) => {
        let r = {};
        if (typeof l === "string") r = { text: l, value: l };
        else if (!l.text) r = { text: l.value, value: l.value };
        else r = Object.assign({}, l);
        res.push(r);
      });
      res = res.sort((a, b) => {
        return (a.text > b.text) - (a.text < b.text);
      });
      this.list = [...res];
      this.loaded = true;
    },
    onKeyDown(e) {
      if (e.key === "*") {
        this.showAllItem = !this.showAllItem;
        e.preventDefault();
      }
    },
    checkDisabled(e) {
      //return false;
      if (e.value == "***") return false;
      if (this.showAllItem) return false;
      if (this.model.isActiveFunc) {
        try {
          const f = eval(`(d)=>{return ${this.model.isActiveFunc}}`);
          return !f(e);
        } catch (error) {
          console.error("Error isActive", error);
        }
      }
      if (e.isActive === false || e.hidden) return true;
      return false;
    },
    clearValue() {
      this.searchValue = "***";
      return true;
    },
    append() {
      this.searchValue = "***";
      return true;
    },
    setFilter(f) {
      this.searchValue = f;
      //this.$emit("change", `${f}`);
    },
  },
};
</script>
<style lang="scss">
@import "styles.scss";

.v-list-item--dense,
.v-list--dense .v-list-item {
  min-height: 28px;
}

.f-search-option {
  font-size: 12px;
}

.f-search .v-list-item,
.f-search .v-list-item::after {
  padding: 2px;
  min-height: 20px !important;
}
.f-search.v-text-field--solo {
  color: #444;
  border-radius: 3px;
  height: 20px;
  padding: 0 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  font-size: 12px;
}
.f-search .v-label {
  font-size: 10px;
}
.f-search .v-select__slot,
.f-search .v-select__selections {
  height: 20px;
  margin: 0;
  padding: 0 !important;
}
.f-search .v-input__slot {
  padding: 0px 5px !important;
  min-height: 10px !important;
}
.f-search input {
  padding: 0 !important;
}
</style>
