<template>
  <dialog-edit2 :value="showPicker" @input="$emit('close')">
    <template v-slot:title> {{ model.title }}</template>
    <a-loader v-if="loading" />
    <v-card-text style1="height: 70vh; overflow: auto" class="pa-0">
      <v-row v-for="(el, i) in list" :key="i" no-gutters class="mb-0">
        <v-col cols="0" class="flex-grow-1 flex-shrink-0">
          <Item
            :value="list[i]"
            :conf="{ dense: true, hideDetails: true }"
            :readonly="![-2, i].includes(changed) || readonly"
            @commit="commit($event, i)"
            @change="changed = i"
          />
          <hr v-if="list[i + 1]" class="mx-2" />
        </v-col>

        <v-col v-if="!readonly" class="flex-grow-0 flex-shrink-1">
          <div class="pt-2">
            <v-btn elevation="0" fab small color="red" bottom @click="onClickDel(i)" :disabled="![-2, i].includes(changed)">
              <v-icon>mdi-minus</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <div class="text-left mt-1">
        <v-btn v-if="!readonly" elevation="2" fab x-small color="green" top left @click="onClickAdd" :disabled="![-2].includes(changed)">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
    </v-card-text>
    <template v-slot:actions>
      <v-spacer></v-spacer>
      <v-btn @click="$emit('close')">Закрыть</v-btn>
    </template>
  </dialog-edit2>
</template>

<script>
export default {
  components: {
    Item: () => import("./BuyingRulesItem"),
  },
  props: {
    value: Array,
    data: Array,
    readonly: { type: Boolean, default: false },
    model: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      changed: -2,
      isChanged: false,
      loading: false,
      showPicker: true,
      newData: null,
    };
  },

  computed: {
    list: {
      set(v) {
        this.$emit("input", v);
      },
      get() {
        let res = [];
        if (this.value) res = this.value;
        if (!Array.isArray(res)) res = [];
        return res;
      },
    },
  },
  watch: {
    value(v) {
      this.reset();
    },
  },
  methods: {
    onValid(e) {
      this.validate(e);
    },

    async reset() {
      this.changed = this.changed == this.list.length - 1 ? this.changed : -2;
    },
    onChange(i) {
      this.changed = i;
    },
    commit(e, i = -1) {
      let d = [...this.list];
      if (i == -1) {
        d = [...d, e];
      } else d[i] = e;
      this.changed = -2;
      this.list = d;
      //this.$emit("input", d);
    },
    onClickDel(key) {
      let e = this.list;
      e.splice(key, 1);
      e.forEach((el, i) => {
        el.numb = i + 1;
      });
      this.list = e;
    },
    onClickAdd() {
      this.list.push({});
      this.changed = this.list.length - 1;
      return;
      this.newData = null;
      this.changed = -1;
    },
  },
};
</script>
