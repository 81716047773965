/** @format */

const storageGoods = {
  list: [
    { name: 'id', title: '#', type: 'id', sortable: true, width: 50 },
    { name: 'name', title: 'Наименование', type: 'string', sortable: true, width: 250 },
    { name: 'category_id', title: 'Категория', type: 'select', dir: 'category', sortable: true, width: 100 },
    { name: 'measure', title: 'Ед.изм.', type: 'select', options: 'measure', sortable: true, width: 80 },
    { name: 'price', title: 'стоимость', type: 'number', sortable: true, width: 100 },
    { name: 'photos', title: 'Фото', type: 'images', hidden: true },
    { name: 'content', title: 'Описание', type: 'string', sortable: true, width: 250 },
    { name: 'status', title: 'status', type: 'number', hidden: true },
  ],
  form: [
    { name: 'name', title: 'Наименование', type: 'string', validator: ['req'] },
    { name: 'category_id', title: 'Категория', type: 'select', dir: 'category', dirFilter: { type: 2 }, validator: ['req'] },
    { name: 'measure', title: 'Ед.изм.', type: 'select', options: 'measure', validator: ['req'] },
    { name: 'price', title: 'стоимость', type: 'number', validator: ['req'] },
    { name: 'photos', title: 'Фото', type: 'images' },
    { name: 'content', title: 'Описание', type: 'text' },
    { name: 'status', title: 'status', hidden: true },
  ],
};

export default storageGoods;
