<template>
  <div>
    <v-dialog v-model="showAlert" persistent max-width="450">
      <v-card>
        <v-card-title class="text-h5"> У вас есть просроченные задачи! </v-card-title>
        <v-card-text>
          <span> Количество просроченных задач: {{ taskCount }}. </span>
          <p>Завершите их, пожалуйста.</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="!!interval" color="green darken-1" text @click="close()"> {{ interval ? interval : "Закрыть" }} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showAlert: false,
      checkInterval: null,
      taskCount: 0,
      timer: null,
      interval: 0,
    };
  },
  created() {
    this.checkInterval = setInterval(async () => {
      this.check();
    }, 15 * 60 * 1000);
    this.check();
  },
  computed: {},
  methods: {
    delay(n) {
      this.interval = n;
      this.timer = setInterval(() => {
        if (this.interval) {
          this.interval--;
        } else clearTimeout(this.timer);
      }, 1000);
    },
    close() {
      this.showAlert = false;
    },
    async check() {
      if (this.showAlert) return;
      if (!this.$root.profile?.id) return;

      const status = this.$store.getters["config/get"].models.tasks.statuses
        .filter(el => {
          return ["new", "progress"].includes(el?.status);
        })
        .map(s => {
          return s.value;
        });
      /* let d = await this.$axios.get("/mechti/tasks", {
        params: {
          sort: { key: "date_start", order: "ASC" },
          filters: {
            or: [
              {
                date_end: { condition: "<", value: new Date().date },
                user_id: this.$root.profile.id,
                status,
              },
              {
                date_end: { condition: "<", value: new Date().date },
                createdby_id: this.$root.profile.id,
                status: 2,
              },
            ],
          },
        },
      }); */
      let d = await this.$axios.post("/report_sql", { name: "getAlertTask", params: { user_id: this.$root.profile.id } });

      this.taskCount = d.data.data.length;
      if (this.taskCount) {
        const ids = d.data.data.map(el => {
          return el.id;
        });
        //console.log("alert task list ", ids);
        this.delay(5);
        this.showAlert = true;
        if (this.checkInterval) clearInterval(this.checkInterval);
      }
    },
  },
};
</script>
