<template>
  <div>
    <v-combobox
      outlined
      :clearable="!readonly"
      :readonly="readonly"
      hide-selected
      :items="items"
      :value="value"
      :label="model.title"
      :error-messages="error"
      :disabled="disabled"
      :hide-details="config.hideDetails || false"
      :dense="config.dense || false"
      @input="onInput"
      @keypress="filter($event)"
      small-chips
      multiple
      @focus="$emit('focus')"
      @blur="$emit('blur')"
    >
      <template v-slot:selection="data">
        <v-chip v-bind="data.attrs" :input-value="data.selected" close small @click:close="data.parent.selectItem(data.item)" class="ma-0 pa-1">
          {{ data.item }}
        </v-chip>
      </template>
    </v-combobox>
  </div>
</template>

<script>
import modelOptions from "@/components/libs/modelOptions";
export default {
  mixins: [modelOptions],
  props: {
    value: [Array],
    values: Object,
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    error: {
      type: String,
      default: "",
    },
    config: { type: Object, default: {} },
  },
  data() {
    return {
      items: [],
    };
  },
  created() {
    this.items = [...(this.getOptions(this.model) || [])];
  },
  computed: {
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
  },
  methods: {
    onChange(e) {
      console.log(e);
    },
    filter: function (evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      if (!/^[0-9]*\.?\,?[0-9]*$/.test(expect)) {
        evt.preventDefault();
        //evt = "";
        //return true;
      } else {
        return true;
      }
    },
    onInput(selectedValues) {
      // Преобразуем все выбранные значения в числа
      const numericValues = selectedValues.map((value) => {
        return typeof value === "string" ? parseInt(value, 10) : value;
      });
      // Отправляем массив чисел через emit
      this.$emit("input", numericValues);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-icon .v-chip__close {
  margin-left: 2px !important;
}
</style>
