<template>
  <div>
    <dialog-edit2 :value="value" @input="$emit('input')">
      <template v-slot:title>
        <small v-if="id"> </small>
        {{ title }}
      </template>
      <a-form-view
        v-if="parent"
        :value="parent"
        :model="[
          {
            name: 'content',
            title: 'Ответ на комментарий',
            type: 'text',
          },
        ]"
        :config="{ dense: true }"
      />
      <a-form-model v-model="data" :model="model" :errors="errors" :config="{ dense: true }" @validate="validate($event)" />
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save v-if="editComment" @click="submit()" :icon="id ? 'far fa-save' : `fas fa-paper-plane`" :title="id ? 'Сохранить' : 'Отправить'"></a-btn-save>
        <a-btn-delete
          v-if="id && editComment"
          @click="
            $emit('delete', data);
            $emit('input');
          "
        ></a-btn-delete>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit2>

    <su-Data ref="suEdit" v-model="suEditShow" :api="api" :id="id" :activate="value" :dataEdit="data" @show="suEditShow = true" v-if="getAccess('suData')" />
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, getAccess } from "@/components/mixings";

export default {
  components: {},
  mixins: [getForm, submitForm, removeEl, getAccess],
  props: {
    value: Boolean,
    id: Number,
    target: { type: Object, default: () => {} },
    defaults: { type: Object, default: () => {} },
  },
  data() {
    return {
      model: [
        { name: "content", title: "Сообщение", type: "text" },
        { name: "status", default: 1 },
      ],
      api: "/mechti/comments",
      idEdit: 0,
      suEditShow: false,
    };
  },
  computed: {
    editComment() {
      return this.data.user_id == this.$root.profile.id || this.id == 0;
      return this.getAccess("object.edit", {
        id: this.object_.id,
        access: this.object_?.object_access || [],
      });
    },
    title() {
      return this.id ? "Редактирование комментария" : "Новый комментарий";
    },
    parent() {
      return this.target.parent || null;
    },
  },
  watch: {
    value() {
      if (this.value) {
        this.updateData(this.id);
        if (!this.id) {
          this.data.user_id = this.$root.profile.id;
          this.data.target_name = this.target?.name;
          this.data.target_id = this.target?.id;
          this.data.parent_id = this.target.parent?.id || null;
        }
      }
    },
  },
  methods: {
    afterSave(data, status) {
      if (status) this.$emit("input", data);
    },
  },
};
</script>
