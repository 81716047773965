/** @format */

const documentObjectSell = {
  title: 'Договор на продажу объекта',
  routeName: 'objectSell_view',
  accessName: 'objectSell',
  operationsCash: [19],
  api: '/accounting/doc/building_sell',

  statuses: [
    { value: 1, text: 'не проведен', next: [2], action: 'Распровести' },
    { value: 2, text: 'проведен', next: [1], action: 'Провести' },
  ],
  list: [
    { name: 'id', title: 'id', type: 'number', sortable: true, width: 30, hidden: true },
    { name: 'code_doc', title: 'Номер', type: 'string', sortable: true, width: 50 },
    { name: 'date_doc', title: 'Дата', type: 'datetime', sortable: true, width: 120 },
    { name: 'operation_type', title: 'Операция', type: 'select', dir: 'operationTable', dirFilter: { id: [18, 59] }, style: 'white-space: normal;', width: 160 },
    { name: 'object_id', title: 'Назначение', type: 'select', dir: 'object', sortable: true, width: 150 },
    { name: 'client_id', title: 'Покупатель', type: 'selectapi', api: 'mechti/clients/select', width: 130 },
    {
      name: 'createdby_id',
      title: 'Автор',
      type: 'select',
      dir: 'user',
      filter: { type: 'select', name: 'createdby_id', dir: 'user' },
      sortable: true,
      width: 150,
    },
    { name: 'status', title: 'Статус', type: 'select', sourceList: 'statuses', sortable: true, width: 150 },
    { name: 'value', title: 'Сумма', type: 'number', sortable: true, width: 80 },
    { name: 'balance', title: 'Долг', type: 'balance', account: '62.1', value: 1, sortable: false, width: 80 },
  ],

  form: [
    { name: 'date_doc', title: 'Дата документа', type: 'datetime', hiddenCreate: true, card: 1 },
    { name: 'operation_type', title: 'Операция', type: 'select', dir: 'operationTable', dirFilter: { id: [18, 59] }, validator: ['req'], card: 1 },
    {
      name: 'accounting_doc_building_buy_id',
      title: 'Документ покупки',
      type: 'selectapi',
      api: '/accounting/doc/building_buy',
      apiVar: {
        key: 'operation_type',
        apiName: [{ value: 59, name: 'accounting/doc/building_buy', preText: 'Договор покупки №' }],
      },
      itemField: { value: 'id', text: 'code_doc' },
      validator: ['req'],
      card: 1,
      size: 6,
    },
    { name: 'object_id', title: 'Объект', type: 'select', dir: 'object', validator: ['req'], card: 1, size: 6 },
    { name: 'client_id', title: 'Покупатель', type: 'selectapi', api: 'mechti/clients/select', validator: ['req'], card: 1, size: 6 },
    { name: 'value', title: 'Стоимость покупки', type: 'number', validator: ['req'] },
    { name: 'comments', title: 'Примечание', type: 'text', hiddenCreate: true, card: 1 },
    { name: 'doc_all', title: 'Документы', type: 'filesdata', hiddenCreate: true },
    { name: 'status', title: 'Статус', validator: ['req'], default: 1 },
    { name: 'filesRemove', default: [], hidden: true },
    { name: 'filesAdd', default: [], hidden: true },
  ],
  config: {
    watch: 'operation_type',
    default: {
      fields: 'operation_type',
      fieldsRO: '',
      fieldsForm: 'operation_type',
    },
    18: {
      note: 'Продажа объекта клиенту',
      fields: 'date_doc,operation_type,object_id,client_id,,value,comments,doc_all,status,filesRemove,filesAdd',
      fieldsRO: '',
      fieldsForm: 'date_doc,operation_type,object_id,client_id,value#6,comments,doc_all',
    },
    59: {
      fieldsReplace: {
        accounting_doc_building_buy_id: {
          name: 'accounting_doc_building_buy_id',
          title: 'Документ покупки',
          type: 'selectapi',
          api: { type: 'post', api: '/report_sql', data: { name: 'getBuyDoc', params: { type: 1 } } },
          validator: ['req'],
        },
      },

      note: 'Продажа недвижимости в ипотеке',
      fields: 'date_doc,operation_type,object_id,accounting_doc_building_buy_id,comments,doc_all,status,filesRemove,filesAdd',
      fieldsRO: 'object_id',
      fieldsForm: 'date_doc,operation_type,accounting_doc_building_buy_id,object_id,comments,doc_all',
      watch: {
        accounting_doc_building_buy_id: {
          object_id: { type: 'field', value: 'object_id' },
          value: { type: 'field', value: 'value' },
        },
      },
    },
  },
};
export default documentObjectSell;
