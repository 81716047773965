<template>
  <v-dialog ref="dialog" v-model="showPicker" :return-value.sync="time" persistent width="290px" :disabled="readonly">
    <template v-slot:activator="{ on, attrs }">
      <slot>
        <div v-bind="attrs" v-on="on"><a-view-base-time :value="time" /></div>
      </slot>
    </template>
    <v-time-picker v-if="showPicker" v-model="time" full-width format="24hr">
      <v-spacer></v-spacer>
      <v-btn @click="showPicker = false">Закрыть</v-btn>
      <v-btn color="primary" @click="$refs.dialog.save(time)">Записать</v-btn>
    </v-time-picker>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: String,
    readonly: { type: Boolean, default: false },
  },
  data() {
    return {
      showPicker: false,
    };
  },
  computed: {
    time: {
      get: function () {
        return this.value;
      },
      set: function (v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>
