<template>
  <Doughnut
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Doughnut } from "vue-chartjs/legacy";

import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale } from "chart.js";
//import autocolors from "chartjs-plugin-autocolors";
ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);
export default {
  name: "DoughnutChart",
  components: {
    Doughnut,
  },
  props: {
    chartId: {
      type: String,
      default: "doughnut-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
    title: String,
    showLegend: { type: Boolean, default: true },
    chartOptions1: { type: Object, default: () => {} },
    chart: {
      type: Object,
      default: () => {
        return { labels: [], datasets: [] };
      },
    },
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            backgroundColor1: ["#41B88311", "#E46651", "#00D8FF", "#DD1B16", "#E57373", "#3949AB", "#00695C", "#9E9D24", "#A1887F", "#90A4AE", "#00E5FF"],
            data: [],
          },
        ],
      },
    };
  },
  watch: {
    "chartData.labels"(v) {
      if (this.chartData.datasets[0].backgroundColor.length == 0) this.chartData.datasets[0].backgroundColor = this.bgColors;
    },
  },
  computed: {
    chartData2() {
      console.log("chartData", this.chart);
      let res = {
        labels: this.chart.labels || [],
        datasets: this.chart.datasets.length
          ? this.chart.datasets
          : [
              {
                backgroundColor: [],
                data: [],
              },
            ],
      };
      if (!res.datasets[0].backgroundColor) res.datasets[0].backgroundColor = [];
      if (res.datasets[0].backgroundColor.length == 0) res.datasets[0].backgroundColor = this.bgColors;
      return res;
    },
    bgColors2() {
      let res = [];
      this?.chart?.labels.forEach((l, i) => {
        res.push(this.stringToColour(l));
      });
      return res;
    },
    bgColors() {
      let res = [];
      this.chartData.labels.forEach((l, i) => {
        res.push(this.stringToColour(l));
        //res.push(this.stringToColour(l + this.chartData.datasets[0].data[i]));
      });
      return res;
    },
    chartOptions() {
      return {
        plugins: {
          legend: {
            display: this.showLegend,
            position: "right",
          },
          title: {
            display: this.title ? true : false,
            text: this.title,
            align: "start",
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      };
    },
  },
  methods: {
    stringToColour(str) {
      var hash = 0;
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      var colour = "#";
      for (var i = 0; i < 3; i++) {
        var value = (hash >> (i * 8)) & 0xff;
        colour += ("00" + value.toString(16)).substr(-2);
      }
      return colour;
    },
  },
};
</script>
