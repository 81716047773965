<template>
  <v-lazy v-model="lazy" :options="{ threshold: 0.5 }" :key="values.id">
    <div v-if="lazy">
      <v-progress-circular v-if="loading" indeterminate color="green" />
      <span v-else> {{ saldo }} </span>
    </div>
  </v-lazy>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    values: Object,
    model: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      data: [],
      text: null,
      credit: null,
      debit: null,
      loaded: false,
      lazy: false,
      loading: true,
    };
  },
  created() {},
  watch: {
    "values.id"() {
      this.loaded = false;
    },
    lazy(v) {
      if (v) this.getBalance();
    },
  },
  computed: {
    saldo() {
      if (!this.loaded) this.getBalance();
      return (this.credit - this.debit).toLocaleString();
    },
  },
  methods: {
    async fetchData() {
      return;
    },
    getSubconto(s) {
      let res = -1;
      let sub = eval(this.model?.["subconto" + s] || "-1");
      if (typeof sub === "function") res = sub(this.values);
      else res = sub;
      return res;
    },
    async getBalance() {
      this.loaded = true;
      this.loading = true;
      let id = this.values.id;
      let debit = 0;
      let credit = 0;
      let acc = this.model?.account || "";
      let s1 = this.getSubconto(1);
      let s2 = this.getSubconto(2);
      let s3 = this.getSubconto(3);

      let resp = await this.$axios.post("/report_sql", { name: "getBalance", params: { acc, s1, s2, s3 } });

      this.loading = false;
      if (resp.data.status == "ok") {
        resp.data.data.forEach((el) => {
          debit += el.value_db;
          credit += el.value_cr;
        });
      }

      this.credit = credit;
      this.debit = debit;
    },
  },
};
</script>
