<template>
  <div class="d-flex" style="position: relative">
    <v-row @click="getBalance()">
      <v-simple-table style="width: 100%">
        <tbody>
          <tr v-for="(el, key) in prop" :key="key">
            <td class="text-left font-weight-bold">{{ el.text }}</td>
            <td class="text-left">{{ el.value }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-row>
    <a-form-view
      v-if="!true"
      :value="balance"
      :model="[
        { name: 'debit', title: 'Всего к оплате по документу', type: 'number' },
        { name: 'credit', title: 'Всего выплачено по документу', type: 'number' },
        { name: 'saldo', title: 'Не выплачено по документу', value: -balance.credit + balance.debit, type: 'number' },
      ]"
      @click="getBalance()"
      :config="{ dense: true }"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    values: Object,
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    config: { type: Object, default: {} },
  },
  data() {
    return {
      prop: [
        { text: "p1", value: 1 },
        { text: "p2", value: 2 },
      ],
      balance: { debit: 1, credit: 2 },
    };
  },
  computed: {
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
  },
  methods: {
    async getBalance() {
      let data = {
        name: "getBalanceForCashOrder",
        params: {},
      };
      let response = await this.$axios.post("/report_sql", data);
      console.log("rrr", response);
      this.loading = false;
    },
  },
};
</script>
