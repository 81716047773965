<template>
  <div>
    <v-card-text :style="`height: ${height}px; overflow: auto`" class="pa-0">
      <a-table-f-api v-if="false" ref="tableCash" :api="api" :model="model" :searchable="false" :useQuery="false" :defaults="defaults" @click="onClickCash($event)">
        <template v-slot:top>
          <v-fab-transition v-if="canAdd">
            <v-btn fab dark color="green" absolute bottom small style="bottom: 15px" left class="v-btn--example" @click="showCashSelectDialog = true">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-fab-transition>
        </template>
      </a-table-f-api>
      <a-table-f-data2 v-else ref="tableCash" :dataTable="data" :useQuery="false" :model="model" :searchable="false" :defaults="defaults" @click="onClickCash($event)" />
    </v-card-text>
    <select-dialog v-model="showCashSelectDialog" v-if="showCashSelectDialog" :operations="operationsList" @select="onSelectOperation($event)" />
    <portal to="v-main">
      <component v-if="cashType && showCashCreateDialog" :is="cashType" v-model="showCashCreateDialog" :id="idEditCash" :config="configDoc" :document="data" :api="api" />
    </portal>
  </div>
</template>

<script>
import libsCash from "@/views/documents/libsCash";
import { getAccess, popupMenu, genModel } from "@/components/mixings";
export default {
  mixins: [getAccess, popupMenu, genModel, libsCash],
  components: {
    selectDialog: () => import("@/views/documents/dialogs/cashOperationSelectDialog"),
    cashParent: () => import("@/views/documents/dialogs/cashParent/editDialog"),
    cashOrder: () => import("@/views/documents/dialogs/cashOrder/editDialog"),
  },
  props: {
    height: { type: Number, default: 300 },
    canAdd: Boolean,
    type: [String, Number],
    operations: { type: Array, default: () => [] },
    data: Object,
    m: Object,
  },
  data() {
    return {
      showCashCreateDialog: false,
      showAddDocDialog: false,
      showCashSelectDialog: false,
      addDocModel: null,
    };
  },
  created() {},
  watch: {
    showCashCreateDialog() {
      if (!this.showCashCreateDialog) {
        this.$refs.tableCash.updateData();
      }
    },
  },
  computed: {
    defaults() {
      let res = {
        sort: { key: "id", order: "DESC" },
        filters: {
          parent_name: this.m.accDocName,
          parent_id: this.data.id,
        },
        paramName: "documentCash" + this.type,
      };
      return res;
    },
    operationsList() {
      if (this.operations.length) return this.operations;
      return this.m.operationsCash[this.type] || this.m.operationsCash || [];
    },
    api() {
      let api;
      if (this.type == "in") api = "/accounting/doc/order_receipt";
      if (this.type == "out") api = "/accounting/doc/order_withdrawal";
      return api;
    },
    model() {
      let model;
      if (this.type == "in") model = this.getModelList(this.$store.getters["config/get"].models.cashIn);
      if (this.type == "out") model = this.getModelList(this.$store.getters["config/get"].models.cashOut);
      return model;
    },
  },
  methods: {
    onSelectOperation(e) {
      if (!e.value) return;
      this.showCashDialog(0, e.id);
    },
    async showCashDialog(id, type) {
      this.idEditCash = id;
      let getConfig;
      if (true) {
        getConfig = await this.onConfigDoc(this.keys?.ctrl ? -1 : type);
        // console.log("fdfsdfsdfsf", getConfig, this.configDoc);
      } else {
        if (this.type == "in") getConfig = await this.onConfigDocIn(type);
        if (this.type == "out") getConfig = await this.onConfigDocOut(type);
      }
      if (!getConfig) {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Документ не настроен",
        });
        return;
      }
      this.showCashCreateDialog = true;
    },
    onClickCash(e) {
      this.showCashDialog(e.row.id, e.row.operation_type);
      console.log(e);
    },
  },
};
</script>
