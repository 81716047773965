/** @format */

const documentActWorkClient = {
  title: 'Акт (Клиент)',
  routeName: 'actWorkClient_view',
  accessName: 'actWorkClient',
  operationsCash: [],
  api: '/accounting/doc/act_work_client',
  accDocName: 'AccountingDocActWorkClient',
  statuses: [
    { value: 0, text: 'черновик', next: [1], action: 'Вернуть' },
    { value: 1, text: 'На проверке', next: [0, 3], action: 'Отправить' },
    { value: 2, text: 'Принят', next: [], action: 'Принять' },
    { value: 3, text: 'Отказан', next: [2], action: 'Отказать' },
  ],
  list: [
    { name: 'id', title: 'id', type: 'number', sortable: true, width: 30, hidden: true },
    { name: 'code_doc', title: 'Номер', type: 'string', sortable: true, width: 50 },
    { name: 'object_id', title: 'Объект', type: 'select', dir: 'object', sortable: true, width: 150, style: 'white-space: normal;' },
    { name: 'date_doc', title: 'Дата', type: 'datetime', sortable: true, width: 120 },
    {
      name: 'client_id',
      title: 'Клиент',
      type: 'select',
      dir: 'client',
      filter: { type: 'select', name: 'client_id', dir: 'client' },
      sortable: true,
      width: 130,
      access: { role: [-1, -20] },
    },
    {
      name: 'investor_id',
      title: 'Инвестор',
      type: 'select',
      dir: 'investor',
      filter: { type: 'select', name: 'investor_id', dir: 'investor' },
      sortable: true,
      width: 130,
      access: { role: [-1, -10] },
    },
    { name: 'createdby_id', title: 'Автор', type: 'select', dir: 'user', filter: { type: 'select', name: 'createdby_id', dir: 'user' }, sortable: true, width: 150 },
    { name: 'status', title: 'Статус', type: 'select', sourceList: 'statuses', sortable: true, width: 150 },
    { name: 'value', title: 'Сумма ', type: 'number', sortable: true, width: 80 },
  ],

  viewForm1: {
    fields: 'code_doc,date_doc,operation_type,object_id,client_id,investor_id',
  },
  viewForm2: {
    fields: 'value,description,comments',
  },
  form: [
    { name: 'operation_type', title: 'Операция', type: 'select', dir: 'operationTable', dirFilter: { id: [60, 61] } },
    { name: 'code_doc', title: 'Номер Документа', type: 'string', roleShow: [1000], hiddenCreate: true },
    { name: 'date_doc', title: 'Дата документа', type: 'datetime', hiddenCreate: true },
    { name: 'object_id', title: 'Объект', type: 'select', dir: 'object', validator: ['req'] },
    { name: 'client_id', title: 'Клиент', type: 'select', dir: 'client', validator: ['req'] },
    { name: 'investor_id', title: 'Инвестор', type: 'select', dir: 'investor', validator: ['req'] },
    { name: 'value', title: 'Стоимость', type: 'number', validator: ['req'] },
    { name: 'status', title: 'Статус', validator: ['req'], default: 0, hidden: true },
    { name: 'comments', title: 'Коментарий', type: 'text' },
    { name: 'description', title: 'Описание', type: 'text' },
    { name: 'photos', title: 'Галерея', type: 'images', reverce: true, hiddenCreate: true },
    { name: 'doc_all', title: 'Документация', type: 'filesdata', category: 1, hiddenCreate: true },
    { name: 'filesRemove', default: [], hidden: true },
    { name: 'filesAdd', default: [], hidden: true },
    { name: 'createdby_id', title: 'Автор', type: 'select', dir: 'user', hiddenCreate: true },
  ],
  listDetail: [
    { name: 'actions', width: 40, statusHide: 2 },
    { name: 'id', title: 'id', type: 'number', sortable: true, width: 55 },
    { name: 'name', title: 'Наименование', type: 'string', sortable: true, width: 300, style: 'white-space: normal;' },
    { name: 'amount', title: 'Кол-во', type: 'number', validator: ['req'], sortable: true, width: 50 },
    { name: 'measure', title: 'ед.изм.', type: 'select', options: 'measure', width: 30 },
    { name: 'price', title: 'Цена', type: 'number', sortable: true, width: 60 },
    { name: 'value', title: 'Стоим.', type: 'number', sortable: true, width: 60 },
  ],
  formDetail: [
    { name: 'name', title: 'Наименование', type: 'string', validator: ['req'] },
    { name: 'amount', title: 'Кол-во', type: 'number', validator: ['req', 'min#1'], default: 1 },
    { name: 'measure', title: 'ед.измер.', type: 'select', options: 'measure', validator: ['req'] },
    { name: 'price', title: 'Цена за ед.', type: 'number' },
    { name: 'value', title: 'Стоимость', type: 'number', validator: ['req'] },
  ],

  config: {
    watch: 'operation_type',
    default: {
      fields: 'operation_type',
      fieldsRO: '',
      fieldsForm: 'operation_type',
    },
    60: {
      note: 'Акт для клиента',
      fieldsReplace: {
        object_id: {
          name: 'object_id',
          title: 'Объект',
          type: 'selectapi',
          api: { type: 'post', api: '/report_sql', data: { name: 'getObjects', params: { is_investor: 0 } } },
          validator: ['req'],
        },
      },
      fields: 'date_doc,operation_type,object_id,client_id,,description,doc_all,status,filesRemove,filesAdd,photos',
      fieldsRO: 'client_id',
      fieldsForm: 'date_doc,operation_type,object_id,client_id,description,doc_all,photos',
      watch: {
        object_id: {
          client_id: { type: 'field', value: 'client_id' },
        },
      },
    },
    61: {
      note: 'Акт для инвестора',
      fieldsReplace: {
        object_id: {
          name: 'object_id',
          title: 'Объект',
          type: 'selectapi',
          api: { type: 'post', api: '/report_sql', data: { name: 'getObjects', params: { is_investor: 1 } } },
          validator: ['req'],
        },
      },
      fields: 'date_doc,operation_type,object_id,investor_id,,description,doc_all,status,filesRemove,filesAdd,photos',
      fieldsRO: 'client_id',
      fieldsForm: 'date_doc,operation_type,object_id,investor_id,description,doc_all,photos',
      watch: {
        object_id: {
          investor_id: { type: 'field', value: 'investor_id' },
        },
      },
    },
  },
};
export default documentActWorkClient;
