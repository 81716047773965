/** @format */
import { statusTask } from './libs';

const statusGoods = statusTask.filter((status) => {
  return status.type == 'goods';
});

const goods = {
  list: [
    {
      name: 'checked',
      type: 'checkbox',
      width: 35,
      isDisableFunc: `(r) => {return r?.status == -1;}`,
    },
    {
      name: 'id',
      title: '#',
      type: 'badge',
      sortable: true,
      width: 50,
      masterField: 'status',
      options: [
        { value: -2, color: 'red' },
        { value: -1, color: 'yellow' },
        { value: 0, color: 'green' },
      ],
    },
    { name: 'room_type', title: 'Помещение', type: 'string', style: 'white-space: normal;', sortable: true, width: 120 },
    { name: 'photo', title: 'Фото', type: 'image', width: 40, target: 'data', sizeImg: 150 },
    {
      name: 'category_id',
      title: 'Категория',
      type: 'select',
      dir: 'category',
      dirFilter: { type: 2, status: 1 },
      style: 'white-space: normal;',
      sortable: true,
      width: 100,
    },
    { name: 'name', title: 'Наименование', type: 'string', style: 'white-space: normal;', sortable: true, width: 250 },
    { name: 'vendor', title: 'Источник', type: 'string', sortable: true, width: 150 },
    { name: 'article', title: 'Артикул', type: 'string', style: 'white-space: normal;', sortable: true, width: 150 },
    { name: 'amount', title: 'Кол-во', type: 'number', sortable: true, width: 60 },
    { name: 'price', title: 'Цена', type: 'number', sortable: true, width: 70 },
    { name: 'date_buy', title: 'Дата закупа', type: 'date', sortable: true, width: 90 },
    {
      name: 'status',
      title: 'Статус',
      type: 'select', 
      options: [
        { value: -3, text: 'Ошибка задачи', color: 'red' },
        { value: -2, text: 'Черновик', color: 'red' },
        { value: -1, text: 'Настройка', color: 'yellow' },
        { value: 0, text: 'В задачах', color: 'green' },
      ],
      style: 'white-space: normal;',
      sortable: true,
      width: 80,
    },
    { name: 'tasks_status', title: 'Статус задачи', type: 'select', options: 'taskStatuses', style: 'white-space: normal;', sortable: true, width: 100 },
  ],
  list_role10: [
    {
      name: 'id',
      title: '#',
      type: 'badge',
      sortable: true,
      width: 50,
      masterField: 'status',
      options: [
        { value: -2, color: 'red' },
        { value: -1, color: 'yellow' },
        { value: 0, color: 'green' },
      ],
    },
    { name: 'room_type', title: 'Помещение', type: 'string', style: 'white-space: normal;', sortable: true, width: 120 },
    { name: 'photo', title: 'Фото', type: 'image', width: 40, target: 'data', sizeImg: 150 },
    {
      name: 'category_id',
      title: 'Категория',
      type: 'select',
      dir: 'category',
      dirFilter: { type: 2, status: 1 },
      style: 'white-space: normal;',
      sortable: true,
      width: 100,
    },
    { name: 'name', title: 'Наименование', type: 'string', style: 'white-space: normal;', sortable: true, width: 250 },
    { name: 'amount', title: 'Кол-во', type: 'number', sortable: true, width: 60 },
    { name: 'date_buy', title: 'Дата закупа', type: 'date', sortable: true, width: 90 },
    {
      name: 'status',
      title: 'Статус',
      type: 'select',
      options: [
        { value: -3, text: 'Ошибка задачи', color: 'red' },
        { value: -2, text: 'Черновик', color: 'red' },
        { value: -1, text: 'Настройка', color: 'yellow' },
        { value: 0, text: 'В задачах', color: 'green' },
      ],
      style: 'white-space: normal;',
      sortable: true,
      width: 80,
    },
    { name: 'tasks_status', title: 'Статус задачи', type: 'select', options: 'taskStatuses', style: 'white-space: normal;', sortable: true, width: 100 },
  ],
  form: [
    { name: 'data.rooms', title: 'Помещение', type: 'selectroom', chips: true, validator: ['req'], check: true },
    { name: 'data.room_class', title: 'Класс помещения', type: 'select', options: 'rooms_classes', readonly: true, validator: ['req'], check: true },
    { name: 'room_type', title: 'Помещение', validator: ['req'], type: 'comboboxapi', api: '/mechti/construction-goods/tags/room_type', check: true },
    { name: 'goods_type_id', title: 'Тип товара', type: 'selectapi', api: '/mechti/goods_type', itemField: { text: 'name', value: 'id' }, check: true },
    { name: 'data.price_comfort', title: 'Стоп-цена(комфорт)', type: 'number', readonly: true },
    { name: 'data.price_business', title: 'Стоп-цена(бизнес)', type: 'number', readonly: true },
    { name: 'data.price_premium', title: 'Стоп-цена(премиум)', type: 'number', readonly: true },
    {
      name: 'category_id',
      title: 'Категория товара',
      type: 'selectapi',
      api: 'accounting/dir/service_category/select',
      filters: {
        type: 2,
        OR: [
          { field: 'status', condition: '<>', value: 2 },
          { field: 'status', condition: 'IS', value: null },
        ],
      },
      validator1: ['req'],
      readonly: true,
      autocomplete: true,
      check: true,
    },
    { name: 'name', title: 'Наименование', validator: ['req'], type: 'comboboxapi', api: '/mechti/construction-goods/tags/name' },
    {
      name: 'vendor',
      title: 'Источник',
      type: 'comboboxapi',
      api: '/mechti/construction-goods/tags/vendor',
      check: (f) => {
        return f || 0 == false;
      },
    },
    {
      name: 'article',
      title: 'Артикул',
      type: 'string',
      check: (f) => {
        return f || 0 == false;
      },
    },
    { name: 'amount', title: 'Кол-во', type: 'number', viewAddField: ['meassure'], validator: ['req', 'min'], min: 0 },
    {
      name: 'price',
      title: 'Общая стоимость',
      type: 'number',
      check: (f) => {
        return f || 0 == false;
      },
      disabled: true,
    },
    { name: 'date_buy', title: 'Дата закупки', type: 'date', validator: ['req'] },
    { name: 'photo', title: 'Изображение', type: 'images', json: true },
    {
      name: 'link',
      title: 'Ссылка',
      check: (f) => {
        return f || 0 == false;
      },
      isMenu: true,
      json: true,
    },
    { name: 'isFurniture', title: 'Отдел мебели', type: 'switcher', json: true, default: 0 },
    { name: 'description', title: 'Описание', type: 'text', json: true },
    { name: 'status', title: 'Статус', default: -2 },
    { name: 'measure', title: 'ед.измер.', type: 'select', options: 'measure', validator: ['req'] },
  ],

  formBuy: [
    { name: 'type', title: 'Тип', validator: ['req'], default: 1 },
    { name: 'name', title: 'Заголовок', validator: ['req'] },
    { name: 'date_doc', title: 'Дата и время', validator: ['req'] },
    { name: 'value', title: 'Сумма покупки', type: 'number', validator: ['req'] },
    { name: 'status', title: 'Статус', default: 1, validator: ['req'] },
    { name: 'description', title: 'Описание', type: 'text' },
    { name: 'files', title: 'Прикрепить файлы для бухгалтерии', type: 'files' },
    { name: 'object_id', title: 'Объект', validator: ['req'] },
  ],
};

export default goods;
