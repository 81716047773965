<template>
  <div>
    <a-form-modelCols
      v-model="data"
      class="pb-1 mx-1 mt-1"
      :model="getFormModel(['level#2', 'name#5', 'room_type#5', 'room_class#3', 'room_square#3', 'wall_height#3', 'wall_length#3'])"
      :values="data"
      :errors="errors"
      :config="{ dense: true, readonly, hideDetails: true }"
      @validate="
        $emit('change');
        submit(true);
      "
    />
  </div>
</template> 

<script>
import { getForm, getAccess, libChecks, submitForm } from "@/components/mixings";
export default {
  mixins: [getForm, libChecks, getAccess, submitForm],
  props: {
    value: Object,
    config: {
      type: Object,
      default: () => {
        return { dense: true, hideDetails: "auto" };
      },
    },
    readonly: { type: Boolean, default: false },
    object: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      model1: [
        { name: "name", title: "Название", type: "string", validator: ["req"] },
        { name: "room_type", title: "Тип комнаты", type: "select", options: "room_type", validator: ["req"] },
        { name: "room_class", title: "Класс комнаты", type: "select", options: "rooms_classes", validator: ["req"] },
        { name: "room_square", title: "м2", type: "string" },
        { name: "wall_height", title: "H-стен", type: "string" },
        { name: "wall_length", title: "L-стен", type: "string" },
      ],
    };
  },
  computed: {
    model() {
      const level = this.object?.ap_level || 1;
      const list = [];
      for (let i = 0; i < level; i++) {
        list.push({ value: i + 1, text: i + 1 });
      }
      let res = [
        {
          name: "level",
          title: "Уровень",
          type: "select",
          options: list,
          default: 1,
          validator: ["req"],
        },
        { name: "name", title: "Название", type: "string", validator: ["req"] },
        { name: "room_type", title: "Тип комнаты", type: "select", options: "room_type", validator: ["req"] },
        { name: "room_class", title: "Класс комнаты", type: "select", options: "rooms_classes", validator: ["req"] },
        { name: "room_square", title: "S-комнаты", type: "string" },
        { name: "wall_height", title: "H-стен", type: "string" },
        { name: "wall_length", title: "L-стен", type: "string" },
      ];
      return res;
    },
  },
  created() {
    this.updateData();
  },
  watch: {
    value(v) {
      this.updateData();
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      let data = this.value;
      if (!data) this.isChanged = true;
      for (const name in data) {
        this.$set(this.data, name, data[name]);
        this.$set(this.errors, name, null);
      }
      this.loading = false;

      this.afterFetchData(data);
    },
    async post(data, api, silent = false) {
      this.loading = true;
      try {
        this.loading = false;
        this.$emit("commit", data);
        this.afterSave(data);
      } catch (error) {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Ошибка при сохранении",
        });
        this.loading = false;
        return;
      }
    },
    commit() {
      this.$emit("input", this.list);
    },
    onClickDel(key) {
      let e = this.list;
      e.splice(key, 1);
      e.forEach((el, i) => {
        el.numb = i + 1;
      });
      this.list = e;
      this.commit();
      this.reset();
      if (this.curError?.id == key) {
        this.curError = {};
      }
    },
  },
};
</script>
