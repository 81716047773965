var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex",staticStyle:{"position":"relative"}},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.date,"persistent":"","width":"580px","disabled":_vm.readonly},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',{attrs:{"outlined":"","value":_vm.text,"label":_vm.model.title,"error-messages":_vm.error,"disabled":_vm.disabled,"hide-details":_vm.config.hideDetails || false,"readonly":"","persistent-placeholder":"","placeholder":"Не указано","clearable":!!_vm.value && !_vm.readonly,"dense":_vm.config.dense || false,"append-icon":!_vm.value ? 'mdi-calendar' : ''},on:{"click":function($event){_vm.showPicker = true},"click:append":function($event){_vm.showPicker = true},"click:clear":function($event){_vm.$emit('input', null);
          _vm.initValue = null;}}})]}}]),model:{value:(_vm.showPicker),callback:function ($$v) {_vm.showPicker=$$v},expression:"showPicker"}},[(_vm.showPicker)?_c('v-card',[_c('v-card-title'),_c('div',{staticClass:"d-flex"},[_c('v-date-picker',{attrs:{"disabled":_vm.readonly,"first-day-of-week":"1","no-title":""},on:{"dblclick:date":function($event){_vm.setValue(_vm.dt);
            _vm.initValue = _vm.dt;
            _vm.$refs.dialog.save();}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('v-time-picker',{attrs:{"disabled":_vm.readonly,"format":"24hr","no-title":""},on:{"click:minute":function($event){_vm.initValue = _vm.dt}},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}})],1),_c('v-card-actions',[_vm._v(" "+_vm._s(_vm.text)+" "),_c('v-spacer'),_c('v-btn',{on:{"click":function($event){_vm.setValue(_vm.initValue);
            _vm.showPicker = false;}}},[_vm._v("Закрыть")]),(!_vm.readonly)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.setValue(_vm.text);
            _vm.initValue = _vm.dt;
            _vm.$refs.dialog.save();}}},[_vm._v("Записать")]):_vm._e()],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }