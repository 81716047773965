<template>
  <div class="py-1 mb-4">
    <v-card :class="{ 'error--text': showError }">
      <v-card-title class="py-2">
        {{ model.title }}
        <v-spacer></v-spacer>
        <v-btn class="ma-1" @click="add()" color="primary">+ {{ model.title }}</v-btn>
      </v-card-title>
      <v-card-text>
        <div v-if="loaded">
          <v-row style="min-height: 150px; max-height: 350px; overflow: auto">
            <v-card
              v-for="(el, key) in items"
              elevation="0"
              :key="key"
              width="100%"
              class="ma-4"
              draggable="true"
              @dragover="onDragOver(el, key, $event)"
              @dragend="finishDrag(el, key, $event)"
              @dragstart="startDrag(el, key, $event)"
              @dragleave="onDragLeave(el, key, $event)"
              :class="{ over: el === over.item && el !== dragFrom, [over.dir]: el === over.item && el !== dragFrom }"
            >
              <a-input-base-info :q="getQuestionById(el)" trumb />
              <v-btn
                v-if="!readonly"
                class="mx-0"
                fab
                dark
                x-small
                color="error"
                style="position: absolute; right: 8px; top: 8px"
                title="Удалить"
                @click.prevent="remove(key)"
              >
                <v-icon dark> mdi-close </v-icon>
              </v-btn></v-card
            >
          </v-row>
        </div>
      </v-card-text>
    </v-card>
    <div class="v-text-field__details" v-if="showError">
      <div class="v-messages theme--light" role="alert">
        <div class="v-messages__wrapper error--text">{{ err }}</div>
      </div>
    </div>
    <v-dialog v-model="dialogAdd" hide-overlay persistent max-width="1200">
      <v-card class="">
        <v-card-title>Выбор элемента</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-8">
          <div no-gutters class="mb-2 d-flex flex-wrap align-content-start">
            <div v-for="(q, i) in questions" :key="i" cols="0" class="mx-2">
              <s-tests-question v-if="!items.includes(q.q_id)" :q="q" trumb @clickTrumb="addQuestion(q.q_id)" />
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogAdd = false">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: Array,
    model: Object,
    values: {
      type: Object,
      default: () => {
        return {};
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    config: { type: Object, default: {} },
  },
  data() {
    return {
      loaded: false,
      questions: [],
      dialogAdd: false,
      showError: false,
      err: null,
      over: {},
      startLoc: 0,
      dragging: false,
      dragFrom: {},
    };
  },
  created() {
    this.getQuestions();
  },
  watch: {
    error(e) {
      this.err = e;
      if (e) {
        this.showError = true;
        setTimeout(() => {
          this.showError = false;
          this.err = "";
        }, 2000);
      }
    },
  },
  computed: {
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
    items: {
      get() {
        let d;
        try {
          // d = JSON.parse(this.value);
          d = this.value;
          if (!d) d = [];
        } catch (error) {
          d = [];
        }
        return d;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  methods: {
    getQuestionById(id) {
      let res =
        this.questions.find(q => {
          return q.q_id == id;
        }) || {};
      return res;
    },
    async getQuestions() {
      let res = [];
      let info = [];
      let resp = await this.$axios.post("/report_sql", { name: "getQuestions" });
      if (resp.data.status == "ok") {
        info = resp.data.data;
      }
      for (let i = 0; i < info.length; i++) {
        const el = info[i];
        let q_list = JSON.parse(el.test_data);
        for (let ii = 0; ii < q_list.length; ii++) {
          const q = q_list[ii];

          //res.push({ id: el.id, q_id: `${el.id}#${ii}`, t: el.name, q: q.text });
          res.push({ id: el.id, q_id: `${el.id}#${ii}`, t: el.name, question: q });
        }
      }
      this.questions = res;
      this.loaded = !false;
    },
    startDrag(item, i, e) {
      e.dataTransfer.setData("text", item);
      e.dataTransfer.setData("from", JSON.stringify(this.model));
      e.dataTransfer.effectAllowed = "move";
      // this.startLoc = e.clientY;
      this.dragging = true;
      this.dragFrom = this.model.name;
    },
    finishDrag(item, pos) {
      if (this.readonly) return;
      let el = this.items;
      el.splice(pos, 1);
      el.splice(this.over.pos, 0, item);
      this.items = el;
      setTimeout(() => {
        this.over = {};
      }, 50);
    },

    onDragOver(item, pos, e) {
      if (this.over.pos == pos) return;
      if (this.dragFrom != this.model.name) return;
      if (this.readonly) return;
      e.preventDefault();
      let dir;
      this.over = { item, pos, dir };
    },
    onDragLeave(item, pos, e) {
      if (this.over.pos == pos) this.over = {};
    },
    remove(key) {
      let e = this.items;
      e.splice(key, 1);
      this.items = e;
    },
    add() {
      this.dialogAdd = true;
    },
    addQuestion(el) {
      let items = this.items;
      items = [...items, el];
      this.items = items;
    },
  },
};
</script>
<style lang="scss">
.over {
  opacity: 0.6;
}
</style>
