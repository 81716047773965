<template>
  <v-row row wrap>
    <v-col cols="12" sm="6">
      Выберите месяц(ы)
      <v-row no-gutters>
        <v-col
          v-for="m in listMonth"
          :key="m.value"
          cols="3"
          class="ma-0 pa-1 custom7cols1"
        >
          <v-card
            class="ma-1 py-3"
            @click="onMonthClick($event, m)"
            :color="month.includes(m.value) ? 'primary' : ''"
          >
            {{ m.text }}
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="6">
      Выберите день(дни)
      <v-row no-gutters>
        <v-col v-for="(n, index) in 31" :key="index" cols="2" class="ma-0 pa-1">
          <v-card
            class="ma-0 py-1"
            @click="onDayClick($event, n)"
            :color="day.includes(n) ? 'primary' : ''"
            width="50"
          >
            {{ n }}
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: { value: [Object] },

  data() {
    return {
      listMonth: [
        { value: 1, text: "ЯНВ" },
        { value: 2, text: "ФЕВ" },
        { value: 3, text: "МАР" },
        { value: 4, text: "АПР" },
        { value: 5, text: "МАЙ" },
        { value: 6, text: "ИЮН" },
        { value: 7, text: "ИЮЛ" },
        { value: 8, text: "АВГ" },
        { value: 9, text: "СЕН" },
        { value: 10, text: "ОКТ" },
        { value: 11, text: "НОЯ" },
        { value: 12, text: "ДЕК" },
      ],
    };
  },
  computed: {
    month: {
      get() {
        return this?.value?.mon || [];
      },
      set(v) {
        this.$emit("change-val", {
          name: "mon",
          value: v.sort((a, b) => a - b),
        });
      },
    },
    day: {
      get() {
        return this?.value?.dom || [];
      },
      set(v) {
        this.$emit("change-val", {
          name: "dom",
          value: v.sort((a, b) => a - b),
        });
      },
    },
  },
  methods: {
    onMonthClick(e, m) {
      let mon = this.month;
      const index = mon.indexOf(m.value);
      if (index >= 0) {
        mon.splice(index, 1);
      } else {
        mon.push(m.value);
      }
      this.month = mon;
    },

    onDayClick(e, v) {
      let day = this.day;
      const index = day.indexOf(v);
      if (index >= 0) {
        day.splice(index, 1);
      } else {
        day.push(v);
      }
      this.day = day;
    },
  },
}; //v-input--selection-controls__input
</script>
<style  lang="sass">
.v-input--selection-controls__input
  margin: 0 !important

  .custom7cols
    width: 14%
    max-width: 14%
    flex-basis: 14%
</style>
