<template>
  <div>
    <a-form-model2
      v-model="data"
      class="pb-1 mx-1 mt-1"
      :model="model"
      :modelForm="modelForm"
      :values="data"
      :errors="errors"
      :config="{ dense: true, readonly, hideDetails: true }"
      @validate="
        doWatch($event);
        $emit('change');
        submit(true);
      "
    />
  </div>
</template>

<script>
const types = [
  { value: 1, text: "Выполнено" },
  { value: 2, text: "за N дней до" },
];
import { getForm, getAccess, libChecks, submitForm, genModel } from "@/components/mixings";
export default {
  mixins: [getForm, libChecks, getAccess, submitForm, genModel],
  props: {
    value: Object,
    conf: {
      type: Object,
      default: () => {
        return { dense: true, hideDetails: "auto" };
      },
    },
    readonly: { type: Boolean, default: false },
  },
  data() {
    return {
      model: [],
      m: {
        form: [
          { name: "type", title: "Тип", type: "select", options: types, validator: ["req"] },
          { name: "services", title: "Работы", type: "select", dir: "service", dirFilter: { category_id: [28.29] }, validator: ["req"], chips: true },
          { name: "service_id", title: "Работа", type: "select", dir: "service", dirFilter: { category_id: [28, 29] }, validator: ["req"] },
          { name: "value", title: "Дней", type: "number", validator: ["req"] },
          { name: "note", title: "Заметки", type: "string" },
        ],
        config: {
          watch: "type",
          default: {
            fields: "type",
            fieldsRO: "",
            fieldsForm: "type#4",
          },
          1: {
            fields: "type,services,note",
            fieldsRO: "",
            fieldsForm: "type#4,services#8,note",
          },
          2: {
            fields: "type,service_id,value",
            fieldsRO: "",
            fieldsForm: "type#4,service_id#6,value#2",
          },
        },
      },
    };
  },
  computed: {
    modelq() {
      let config = this.m.config[this.data.type || "default"];
      return this.calcModelConfig(config);
    },
  },
  created() {
    this.updateData();
  },
  watch: {
    value(v) {
      this.updateData();
    },
  },
  methods: {
    async onChange(e) {},
    doWatch(e) {
      if (e == this.m?.config?.watch) this.getModel(this.data?.[e]);
      //console.log("doWatch", e, this.config, this.model);
    },
    getModel(t) {
      let config = this.m.config[this.data.type || "default"];
      let model = this.calcModelConfig(config);

      this.fillFormFromModel(model);
      this.model = model;
    },
    async fetchData() {
      this.loading = true;
      let data = this.value;
      if (!data) this.isChanged = true;
      for (const name in data) {
        this.$set(this.data, name, data[name]);
        this.$set(this.errors, name, null);
      }
      this.getModel();
      this.loading = false;

      this.afterFetchData(data);
    },
    async post(data, api, silent = false) {
      this.loading = true;
      try {
        this.loading = false;
        this.$emit("commit", data);
        this.afterSave(data);
      } catch (error) {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Ошибка при сохранении",
        });
        this.loading = false;
        return;
      }
    },
    commit() {
      this.$emit("input", this.list);
    },
    onClickDel(key) {
      let e = this.list;
      e.splice(key, 1);
      e.forEach((el, i) => {
        el.numb = i + 1;
      });
      this.list = e;
      this.commit();
      this.reset();
      if (this.curError?.id == key) {
        this.curError = {};
      }
    },
  },
};
</script>
