<template>
  <a-table-f-api
    v-if="loaded"
    ref="tableAccounting"
    :api="'/accounting/records'"
    :model="getModelList($store.getters['config/get'].models.accRecords)"
    :searchable="false"
    :useQuery="false"
    :defaults="{
      sort: { key: 'id', order: 'DESC' },
      filters: {
        OR: [{ base_name: name, base_id: id }, ...orWhere, ...joinDoc],
      },
      paramName: 'accRecords',
    }"
  >
  </a-table-f-api>
</template>

<script>
import { genModel } from "@/components/mixings";

export default {
  components: {},
  mixins: [genModel],
  props: {
    id: Number,
    name: String,
    orWhere: { type: Array, default: () => [] },
    join: { type: Object, default: () => {} },
  },
  data() {
    return {
      joinDoc: [],
      loaded: false,
    };
  },
  computed: {
    filters() {
      let res = [{ base_name: this.name, base_id: this.id }, ...this.orWhere, ...this.joinDoc];
      console.log("res", res, this.join);
      return res;
    },
  },
  created() {
    this.getJoinDoc();
  },
  watch: {},
  methods: {
    async getJoinDoc() {
      for (const key in this.join) {
        if (Object.hasOwnProperty.call(this.join, key)) {
          const elem = this.join[key];
          let api;
          if (key == "AccountingDocOrderWithdrawalModel") api = "/accounting/doc/order_withdrawal";
          if (key == "AccountingDocOrderReceiptModel") api = "/accounting/doc/order_receipt";
          if (api) {
            let arr = [];
            try {
              let resp = await this.$axios.post(api + "/report", {
                select: " id",
                where: elem,
              });
              //let resp = await this.$axios.get(api, { params: { filters: el } });
              console.log("resp", resp.data);
              if (resp.data.data.length > 0) {
                resp.data.data.forEach((el) => {
                  arr.push(el.id);
                });
                console.log("add join", { base_name: key, base_id: arr }, elem);
                this.joinDoc = [...this.joinDoc, { base_name: key, base_id: arr }];
              }
            } catch (error) {
              console.log("error", error);
            }
          }
        }
      }
      // if (this.joinDoc.length) this.refresh();
      this.loaded = true;
    },
    refresh() {
      this.$refs.tableAccounting.reloadData();
    },
  },
};
</script>
