<template>
  <div style="text-align: left">
    <div v-if="loaded" class="mb-2">Сумма кредита: {{ getLoansDetail(1) }}</div>
    <v-row v-for="(el, i) in schedule" :key="i" no-gutters class="mb-2 .flex-nowrap .flex-md-nowrap">
      <v-col cols="1" class="flex-grow-0 flex-shrink-0" min-width="50">
        <a-input-string class="pr-2" :model="{ name: 'numb', title: '№', type: 'string', readonly: true }" :disabled="false" :value="el.numb" :config="config" />
      </v-col>
      <v-col cols="0" class="flex-grow-1 flex-shrink-0">
        <a-input-datestring
          :model="{ name: 'date_plan', title: 'Дата (план)', readonly: (hasError && curError.id != i && (curError.field != 'value' || !curError.field)) || el.doc_id }"
          :disabled="false"
          :value="el.date_plan"
          :config="config"
          :readonly="true"
          @input="el.date_plan = $event"
          :error="getError(i, 'date_plan')"
        />
      </v-col>
      <v-col cols="0" class="flex-grow-1 flex-shrink-1">
        <a-input-number
          class="px-2"
          :model="{ name: 'value', title: 'Сумма (тело)', readonly: (hasError && curError.id != i && (curError.field != 'value' || !curError.field)) || el.doc_id }"
          :values="el"
          :disabled="false"
          :value="el.value"
          :config="config"
          @input="onInput($event, i, 'value', el)"
          :error="getError(i, 'value')"
        />
      </v-col>
      <v-col cols="0" class="flex-grow-1 flex-shrink-1">
        <a-input-number
          class="pr-2"
          :model="{
            name: 'value_percent',
            title: 'Сумма (%)',
            type: 'number',
            readonly: (hasError && curError.id != i && (curError.field != 'value_percent' || !curError.field)) || el.doc_id,
          }"
          :values="el"
          :disabled="false"
          v-model="el.value_percent"
          :config="config"
          @input="onInput($event, i, 'value_percent', el)"
          :error="getError(i, 'value_percent')"
        />
      </v-col>
      <v-col cols="0" class="flex-grow-1 flex-shrink-0">
        <a-input-number
          :model="{
            name: 'value_commission',
            title: 'Комиссия',
            type: 'number',
            readonly: (hasError && curError.id != i && (curError.field != 'value_commission' || !curError.field)) || el.doc_id,
          }"
          :values="el"
          :disabled="false"
          v-model="el.value_commission"
          :config="config"
          @input="onInput($event, i, 'value_commission', el)"
          :error="getError(i, 'value_commission')"
        />
      </v-col>
      <v-col cols="1" class="flex-grow-0 flex-shrink-1">
        <v-icon v-if="el.doc_id" color="success" large> mdi-check-circle </v-icon>
        <v-btn v-else-if="!hasError || curError.id == i" elevation="5" fab small color="red" dark top right @click="onClickDel(i)">
          <v-icon>mdi-minus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <div class="text-left">
      <v-btn v-if="!hasError" elevation="2" fab x-small dark color="green" top left @click="onClickAdd">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
    <span> Итого тело: {{ total_value }}, Итого процент: {{ total_percent }} </span>
  </div>
</template>

<script>
export default {
  props: {
    value: Array,
    object: {
      type: Object,
      default: () => {
        id: 0;
      },
    },
    config: {
      type: Object,
      default: () => {
        return { dense: true, hideDetails: "auto" };
      },
    },
    readonly: { type: Boolean, default: false },
    type: { type: Number, default: 0 },
  },
  data() {
    return {
      model: [
        { name: "numb", title: "Номер", type: "number", readonly: true, validator: ["req"] },
        { name: "date_plan", title: "Дата (план)", type: "date" },
        { name: "value", title: "Cумма", type: "number", validator: ["req"] },
        { name: "value_percent", title: "Cумма", type: "number", validator: ["req"] },
        { name: "value_commission", title: "Комиссия", type: "number" },
      ],
      schedule: [],
      removeDialogShow: false,
      removeTitle: "Вы действительно хотите удалить этот объект?",
      submitKey: false,
      hasError: false,
      curError: null,
      errors: {},
      loans: [],
      loaded: false,
    };
  },
  computed: {
    data: {
      set(v) {
        this.$emit("input", v);
      },
      get() {
        let res = [];
        if (this.value) res = this.value;
        if (!Array.isArray(res)) res = [];
        return res;
      },
    },
    total_value() {
      let res = this.schedule.reduce((total, curr) => total + (parseFloat(curr.value) || 0), 0);
      return res.toLocaleString();
    },
    total_percent() {
      return this.schedule.reduce((total, curr) => total + (parseFloat(curr.value_percent) || 0), 0).toLocaleString();
    },
  },
  created() {
    this.reset();
    this.getLoans(this.object.id);
  },
  watch: {
    value(v) {
      this.reset();
    },
    "object.id"(v) {
      console.log("watch");
      this.getLoans(v);
    },
  },
  methods: {
    async reset() {
      let res = [];
      this.errors = {};
      this.hasError = false;
      this.curError = null;
      if (this.value) res = this.value;
      if (!Array.isArray(res)) res = [];
      this.schedule = res;
    },

    commit() {
      this.$emit("input", this.schedule);
    },
    onClickDel(key) {
      let e = this.schedule;
      e.splice(key, 1);
      e.forEach((el, i) => {
        el.numb = i + 1;
      });
      this.schedule = e;
      this.commit();
      this.reset();
      if (this.curError?.id == key) {
        this.curError = {};
      }
    },
    onClickAdd() {
      let d = this.schedule;
      d = [...d, { numb: this.schedule.length + 1, date_plan: null, value: null, value_percent: null, value_commission: null }];
      this.schedule = d;
      this.curError = { id: this.schedule.length - 1 };
      this.hasError = true;
    },
    onInput(e, key, field, row) {
      let err = "";
      row[field] = e.toLocaleString();
      ["value", "value_percent"].forEach((f) => {
        if (!err) {
          if (row?.[f] === undefined || row[f] < 0 || (row.value == 0 && row.value_percent == 0)) {
            err = "Не верно заполнено";
          }
          this.hasError = err != "";
          this.$set(this.errors, `${key}-${f}`, err);
          if (err) this.curError = { id: key, f };
        }
      });
      if (!this.hasError) this.commit();
    },
    getError(key, field) {
      return this.errors[`${key}-${field}`];
    },
    getLoansDetail(type) {
      return (this.loans.find((l) => l.type == type)?.value || 0).toLocaleString();
    },
    async getLoans(id) {
      if (!id) return;
      let resp;
      let q = `SELECT * from accounting_record WHERE (credit_account='67.1.1' AND credit_subconto_value_3=${id}) OR (debit_account='67.1.1' AND debit_subconto_value_3=${id})`;
      try {
        resp = await this.$axios.post("/accounting/records/report-sql", { q });
        if (resp.data.status !== "ok") {
          this.$root.$emit("show-info", {
            type: "error",
            text: "Ошибка сбора данных",
          });
          return;
        }
      } catch (error) {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Ошибка сбора данных",
        });
        return;
      }
      let loans = [];
      resp.data.data.forEach((a) => {
        if (a.credit_account == "67.1.1") {
          loans.push({ type: 1, value: a.value, doc_name: a.base_name, doc_id: a.base_id, investor_id: a.credit_subconto_value_1 });
        }
        if (a.debit_account == "67.1.1") {
          loans.push({ type: 0, value: a.value, doc_name: a.base_name, doc_id: a.base_id, investor_id: a.debit_subconto_value_1 });
        }
      });
      this.loans = loans;
      this.loaded = true;
    },
  },
};
</script>
