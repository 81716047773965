<template>
  <div>
    <dialog-edit2 :value="value" @input="$emit('input')">
      <template v-slot:title>
        {{ id ? "Редактирование" : "Создание" }}
      </template>
      <v-card-text class="pt-8">
        {{ data }}
        <a-form-model v-model="data" :model="model" :errors="errors" :config="{ dense: true, hideDetails: false }" class="mb-3" />
        <a-loader v-if="loading" />
        <v-row v-if="propSelected">
          <v-col v-for="(el, i) in modelForm" :key="i" cols="12" :sm="el.size" class="py-0">
            <div v-for="name in el.name.split('+')" :key="name">
              <a-form-model
                :ref="'field_' + name"
                v-model="data"
                :model="getFormModel([name], modelProp)"
                :errors="errors"
                :config="{ dense: true }"
                @validate="validate($event)"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>

      <template v-slot:actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="submit()">Записать </v-btn>

        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit2>
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, getAccess, genModel } from "@/components/mixings";
export default {
  mixins: [getForm, submitForm, removeEl, getAccess, genModel],
  props: {
    value: Boolean,
    object_id: Number,
    id: Number,
  },
  data() {
    return {
      title: "",
      loading: false,
      api: "mechti/object_props_value",
      model: [{ name: "object_props_id", title: "Свойство", type: "select", dir: "objectProps", itemField: { text: "name", value: "id" } }],
      editProps: { object_props_id: null, value: "", data: null },
      props_: [],
      modelProp: null,
      modelForm: null,
      propSelected: null,
      config: {
        1: {
          form: [
            { name: "object_id", validator: ["req", "min"], min: 0, default: this.object_id },
            { name: "value", title: "Вид описания", type: "select", validator: ["req"] },
            { name: "data", title: "Текст описания", type: "text", validator: ["req"] },
          ],
          title: "Текст",
          fields: "object_id,value,data",
          fieldsRO: "",
          fieldsForm: "value,data",
        },
      },
    };
  },
  computed: {
    modelProp1: {
      get() {
        console.log("calc modelprop");
        let model = this.calcModel(this.propSelected);

        return model;
      },
    },
    readonly() {
      return false;
    },

    props() {
      if (!this.props_) this.getProps();
      return this.props_;
    },
  },
  watch: {
    propSelected(v) {
      console.log("watch  propSelected", v);
    },
    "data.object_props_id"(v) {
      console.log("watch data.object_props_id", v);
      if (v) this.calcModel(v);
    },
    value(v) {
      this.propSelected = null;
      if (v) {
        //  this.modelProp = null;

        this.updateData(this.id);
      }
      console.log("watch  value", v);
    },
  },
  created() {
    this.updateData(this.id);
    this.getProps();
  },
  methods: {
    async getProps() {
      console.log("get props");
      let api = "mechti/object_props";
      let params = {};
      this.loading = true;
      let response = await this.$axios.get(api, { params });
      this.loading = false;
      this.props_ = response.data.data;
    },

    remove(key) {
      let e = this.items;
      //e.splice(key, 1);
      e[key].type = "del";
      this.items = e;
    },
    add() {
      this.editProps = { object_props_id: null, value: "", data: null };

      this.dialogProps = true;
    },
    save() {},
    async upload() {
      this.fileLoading = true;
      this.dialogProps = false;
    },
    getValuesList(id) {
      let res =
        this.props.find((p) => {
          return p.id == id;
        })?.options || [];
      console.log("getValuesList", res);

      return res;
    },
    calcModel(t) {
      //переназначить функцию, потому что немного отличается от того что в библиотеке genModel
      this.propSelected = null;
      if (!t) return;
      let type = this.props.find((el) => {
        return el.id == t;
      })?.type;
      if (!type) return;

      const config = this.config[type];
      let model = JSON.parse(JSON.stringify(config.form));
      model.forEach((el) => {
        if (el.name == "value") el.options = this.getValuesList(t);
      });
      console.log("config", config, type, model);
      const fields = config.fields ? config.fields.split(",") : [];
      let fieldsRO = config.fieldsRO ? config.fieldsRO.split(",") : [];
      if (this.data.status == 2) fieldsRO = fields;
      const fieldsForm = config.fieldsForm ? config.fieldsForm.split(",") : [];
      model = model.filter((el) => {
        return fields.includes(el.name);
      });
      model.forEach((el) => {
        if (fieldsRO.includes(el.name)) {
          el.readonly = true;
        }
      });
      let mForm = [];
      fieldsForm.forEach((field) => {
        let f = field.split("#");
        mForm.push({ name: f[0], size: f[1] || 12 });
      });

      this.modelForm = mForm;
      this.propSelected = t;
      this.fillFormFromModel(model);
      if (t == this.config?.type) {
        for (const name in this.config) {
          this.$set(this.data, name, this.config[name]);
        }
      }
      this.modelProp = model;
      return model;
    },
    afterSave() {
      this.$emit("input");
    },
    async beforeSave() {
      console.log("before save");
      return true;
    },
  },
};
</script>
