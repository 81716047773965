<template>
  <v-row>
    <v-simple-table style="width: 100%" class="mt-1">
      <tbody>
        <tr
          v-for="(el, key) in items.filter((f) => {
            return f.deleted === undefined;
          })"
          :key="key"
        >
          <td class="text-left">
            <a :href="$root.config.fileUrl + el.file" target="_blank">
              {{ el.name }}
            </a>
          </td>
          <td class="text-left">
            {{ el.owner }}
          </td>
          <td width="10px" style="height: 38px">
            <v-btn :href="$root.config.fileUrl + el.file" target="_blank" class="mx-2" fab dark x-small color="error" title="Скачать">
              <v-icon dark> mdi-download </v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-row>
</template>

<script>
export default {
  props: {
    name: String,
    id: Number,
  },
  data() {
    return {
      data: [],

      loaded: false,
      loading: false,
    };
  },
  computed: {
    items: {
      get() {
        if (this.data) {
          try {
            return this.data;
          } catch (error) {}
        }
        return [];
      },
    },
  },
  created() {
    this.fitchData();
    console.log("created files2");
  },
  methods: {
    async fitchData() {
      if (this.loading) return;
      this.loading = true;
      let response = await this.$axios.post("/report_sql", { name: "getAllFilesInDocument", params: { name: this.name, id: this.id } });
      const data = response?.data?.data || [];
      this.data = data;
      this.loading = !true;
    },
  },
};
</script>
