<template>
  <div :class="getClass" :style="style">
    <span>
      {{ value }}
    </span>
    <span><a-view-action-link v-if="model.link" :data="model.link" :values="values" /></span>
  </div>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    values: {
      type: Object,
      default: () => {
        return {};
      },
    },
    model: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      badge: false,
    };
  },
  computed: {
    link() {
      return this.model?.link;
    },
    color() {
      if (!this.model?.textColor) return "";
      //this.badge = true;
      return this.model?.textColor(this.values);
    },
    style() {
      let style = "";
      if (this.model?.style) style = this.model?.style;
      return style;
    },

    getClass() {
      let classStr = "";
      if (this.model?.class) classStr = this.model?.class;
      if (this.color) classStr += " red--text";
      return classStr;
    },
  },
};
</script>
