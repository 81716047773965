<template>
  <v-autocomplete
    outlined
    :clearable="!readonly"
    :disabled="disabled"
    :items="data"
    :value="value"
    :label="model.title"
    :error-messages="error"
    :hide-details="config.hideDetails || false"
    :dense="config.dense || false"
    :readonly="readonly"
    item-text="text"
    item-value="value"
    @input="onInput($event)"
    :small-chips="model.chips || false"
    :multiple="model.chips || false"
    :deletable-chips="model.chips && !readonly"
    :hide-selected="model.chips || false"
  >
    <template v-slot:selection="data" v-if="model.chips || false">
      <v-chip v-bind="data.attrs" :input-value="data.selected" close small @click="data.select" @click:close="remove(data.item)" class="mt-1">
        {{ data.item.text }}
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script>
import modelOptions from "./../../libs/modelOptions";
export default {
  mixins: [modelOptions],
  props: {
    value: [Array, Number, Object],
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    model: Object,
    error: {
      type: String,
      default: "",
    },
    config: { type: Object, default: {} },
  },
  data() {
    return {
      data: [],
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
  },
  methods: {
    remove(item) {
      if (this.readonly) return;
      const index = this.value.findIndex((el) => {
        return el == item.value;
      });
      if (index >= 0) this.value.splice(index, 1);
    },
    onInput(e) {
      console.log("emit input", e);
      this.$emit("input", e);
    },
    fetchData() {
      this.loading = true;
      this.data = this.getOptions(this.model);
      this.loading = false;
    },
  },
};
</script>
<style lang="scss"></style>
