<template>
  <div class="d-flex" style="position: relative">
    <v-text-field
      outlined
      :value="value"
      :label="model.title"
      :error-messages="error"
      :disabled="model.disabled || config.disabled || false"
      :hide-details="config.hideDetails || false"
      :dense="config.dense || false"
      :readonly="readonly"
      type="number"
      @input="$emit('input', $event)"
      @keypress="filter($event)"
    ></v-text-field>
    <btn-rating v-if="(model.rating && config.rating) || false" v-model="rating"> </btn-rating>
  </div>
</template>

<script> 
export default {
  props: {
    value: [String, Number],
    model: Object,
    config: { type: Object, default: {} },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    values: Object,
  },
  computed: {
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
    isDisable() {
      return this.model?.disabled || this.disabled;
    },
    rating: {
      get() {
        let res = this.values.data?.rating?.[this.model.name] || "";
        return res;
      },
      set(v) {
        this.$emit("rating", { name: this.model.name, value: v });
      },
    },
  },
  methods: {
    filter: function (evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      if (!/^[0-9]*\.?\,?[0-9]*$/.test(expect)) {
        // evt.preventDefault();
        evt = "";
        return true;
      } else {
        return true;
      }
    },
  },
};
</script>
