<template>
  <div style="display: inline">
    <span v-if="isEdited">
      <span v-if="typeof data === 'string'">
        <input ref="inputEl" @change="onChangeInput" @blur="onBlurInput" v-model="data" />
      </span>
      <span v-if="typeof data === 'number'">
        <input ref="inputEl" @change="onChangeInput" @blur="onBlurInput" v-model="data" type="number" />
      </span>
      <span v-if="typeof data === 'boolean'">
        <select ref="inputEl" @change="onChangeInput" @blur="onBlurInput" v-model="data">
          <option value="true">True</option>
          <option value="false">False</option>
        </select>
      </span>
      <span v-if="typeof data === 'object'">
        <input ref="inputEl" @change="onChangeInput" @blur="onBlurInput" v-model="data" />
      </span>
    </span>
    <span v-else>
      <span class="tree-value" @click="onClickEdit">{{ data }}</span>
      <span v-if="canEdit && data !== undefined" title="edit" @click="onClickEdit" style="padding-left: 5px">
        <i class="fas fa-pencil-alt"></i>
      </span>
      <tree-add-item v-if="canEdit && data == undefined" :el="row" :name="field.name" :type="typeItem" @change="onChangeAddItem"></tree-add-item>
    </span>
  </div>
</template>

<script>
import treeAddItem from "@/views/tree/treeAddItem";

export default {
  name: "tree-table-item",
  components: {
    treeAddItem,
  },
  props: {
    el: [Array, Object, String, Number, Boolean],
    index: [Array, Object, String, Number],
    canEdit: {
      type: Boolean,
      default: true,
    },
    isTable1: {
      type: Boolean,
      default: false,
    },
    // field: { type: String, default: "" },
    row: Object,
    field: [Array, Object, String, Number, Boolean],
    typeItem: String,
  },
  data: function () {
    return {
      open: false,
      isEdited: false,
      value: "",
      drag_src_el: null,
      openTable: false,
    };
  },
  computed: {
    data: {
      get: function () {
        return this.el;
      },
      set: function (v) {
        this.value = v;
      },
    },
    isFolder: function () {
      return Array.isArray(this.el) || typeof this.el === "object";
    },
    isTableView: function () {
      return Array.isArray(this.el);
    },
  },
  methods: {
    onChange: function (e, index) {
      console.log("treeTableItem onChange", this.el, e, index);
      let t = this.el;
      t[index] = e;
      this.$emit("change", t);
      this.$forceUpdate();
    },
    onChangeAddItem: function (e) {
      let el = this.row;
      console.log("onChangeAddItem", { e, el });
      if (Array.isArray(this.data)) {
        el.push(e.v);
      } else {
        el[e.i] = e.v;
      }

      this.$emit("change", e.v);
      // this.$emit("change", el);
      this.$forceUpdate();
    },
    onChangeInput: function (e) {
      // this.onBlurInput(e);
    },
    onBlurInput: function (e) {
      this.isEdited = false;
      if (typeof this.el === "number") {
        this.value = Number(this.value);
        if (typeof this.value === "number") {
          this.$emit("change", this.value);
        }
      }
      if (typeof this.el === "string") {
        this.value = String(this.value);
        if (this.value.length > 0) {
          this.$emit("change", this.value);
        }
      }
      if (typeof this.el === "boolean") {
        this.value == "true" || this.value === true ? (this.value = true) : (this.value = false);
        this.$emit("change", this.value);
      }
      if (typeof this.el === "object") {
        if (Array.isArray(this.el)) this.value = JSON.parse("[" + this.value + "]");

        this.$emit("change", this.value);
      }
      this.$forceUpdate();
    },
    onClickEdit: function () {
      if (this.el === undefined) {
        console.log("not element");
        return;
      }
      this.isEdited = true;

      this.value = this.el;
      this.$nextTick(() => this.$refs.inputEl.focus());
    },

    dragstart: function (e) {},
    dragdrop: function (e) {},
  },
};
</script>

<style lang="scss">
.v-tree-item {
  display: inline;
}
.v-tree-item-li {
}
.v-tree-item-caption {
  margin-left: 5px;
  margin-right: 5px;
  font-weight: bold;
}
</style>
