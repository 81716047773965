<template>
  <div class="d-flex justify-start mb-6">
    <v-checkbox
      v-for="d in list"
      :key="d.value"
      multiple
      v-model="weekDays"
      :value="d.value"
      :label="d.text"
      hide-details
      @change1="onCheck($event, d.value)"
      class="pl-2"
    />
  </div>
</template>
<script>
export default {
  props: { value: [Object] },

  data() {
    return {
      list: [
        { value: 1, text: "ПН" },
        { value: 2, text: "ВТ" },
        { value: 3, text: "СР" },
        { value: 4, text: "ЧТ" },
        { value: 5, text: "ПТ" },
        { value: 6, text: "СБ" },
        { value: 7, text: "ВС" },
      ],
    };
  },
  computed: {
    weekDays: {
      get() {
        return this?.value?.dow || [];
      },
      set(v) {
        this.$emit("change-val", { name: "dow", value: v.sort() });
      },
    },
  },
  methods: {},
}; //v-input--selection-controls__input
</script>
<style  lang="sass">
.v-input--selection-controls__input
  margin: 0 !important
</style>
