<template>
  <v-dialog :value="value" @input="$emit('input')" persistent scrollable @keydown.esc="$emit('input')" open-on-focus :max-width="maxWidth" :retain-focus="makeFocus">
    <v-card height="auto">
      <v-card-title v-if="$slots.title">
        <span><slot name="title"></slot> </span>
        <v-btn style="position: absolute; right: 12px; top: 12px" fab dark small color="blue-grey" title="Закрыть окно" @click="$emit('input')">
          <v-icon dark> mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text
        height="100px"
        :class="{
          'py-1 px-3': $vuetify.breakpoint.smAndDown,
          'py-4 px-6': !$vuetify.breakpoint.smAndDown,
        }"
      >
        <div class="text-center">
          <slot />
        </div>
      </v-card-text>

      <v-card-actions>
        <slot name="actions">
          <v-spacer></v-spacer>
          <v-btn @click="$emit('input')">Закрыть</v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    maxWidth: {
      type: String,
      default: "800px",
    },
    makeFocus: { type: Boolean, default: true },
  },
};
</script>
