<template>
  <v-lazy v-model="lazy" :options="{ threshold: 0.5 }" :key="values.id">
    <div v-if="lazy">
      <v-progress-circular v-if="loading" indeterminate color="green" />
      <span v-else> {{ saldo }} </span>
    </div>
  </v-lazy>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    values: Object,
    model: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      data: [],
      text: null,
      credit: null,
      debit: null,
      loaded: false,
      lazy: false,
      loading: true,
    };
  },
  created() {},
  watch: {
    "values.id"() {
      this.loaded = false;
    },
    "values._updated"() {
      this.loaded = false;
    },
    lazy(v) {
      if (v) this.getBalance();
    },
  },
  computed: {
    saldo() {
      if (!this.needCalc()) return null;
      if (!this.loaded) this.getBalance();
      let res = this.credit - this.debit;
      if (this.model.params && this.model.params?.negative) res = -res;
      if (!res) return null;
      return res.toLocaleString();
      if (this.credit - this.debit == 0) return this.credit - this.debit;
      if (this.credit && !this.debit) return this.credit - this.debit;

      return `${this.credit - this.debit} (${this.credit})`;
      return this.credit - this.debit;
    },
  },
  methods: {
    async fetchData() {
      return;
    },

    needCalc() {
      let res = eval(this.model?.value || "0");
      if (typeof res === "function") res = res(this.values);
      else res = res;
      return res;
    },
    async getBalance() {
      this.loaded = true;
      this.loading = true;
      let debit = 0;
      let params = this.model?.params || {};
      params["doc_id"] = this.values.id;
      let resp = await this.$axios.post("/report_sql", { name: "getBalanceByDocument", params });

      this.loading = false;
      if (resp.data.status == "ok") {
        resp.data.data.forEach((el) => {
          debit += el.value;
        });
      }

      this.debit = debit;
      this.loading = false;
    },
  },
};
</script>
