<template>
  <div>
    <v-btn @click="editForm({})"> Форма </v-btn>
    <s-edit-form
      v-model="showEditDialog"
      v-if="showEditDialog"
      :m="configH.m"
      :configForm="{ head: configH.head, title: configH.title, submitType: 'emit' }"
      :id="configH.id"
      :opt="configH.opt"
      :initData="configH.initData || {}"
      :params="configH"
      @submit="onSubmit($event)"
      @select="$emit('select', $event)"
    />
  </div>
</template>

<script>
import { dataUtils, getAccess, genModel } from "@/components/mixings";
export default {
  mixins: [getAccess, dataUtils, genModel],
  props: {
    value: [Object],
    model: Object,
  },
  data() {
    return {
      showEditDialog: false,
      configH: null,
      typeObject: "leadObjects",
      m: null,
    };
  },
  computed: {},
  methods: {
    onSubmit(e) {
      this.$emit("onEdit", e);
    },
    async editForm({ task, type }) {
      this.configH = null;
      let m = this.getObjectPath(this.$store.getters["config/get"].models, this.model.model);
      this.m = m;
      const config = m?.config?.[this.typeObject] || {};
      let configH = {};
      let model = this.calcModelConfig(config);

      configH.head = config || {};
      configH.title = config.title;
      // config.api = tasks.api;
      configH.id = null;
      configH.opt = { readonly: false, fieldsEditable: "*" };
      // config.initData = { lead_id: this.id, task_id: task.id, type: task.type, status: this.data.status };
      configH.initData = this.value || {};
      configH.m = m;
      this.configH = configH;
      this.showEditDialog = true;
    },
  },
};
</script>
