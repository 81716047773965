<template>
  <v-dialog :value="value" @input="$emit('input')" fullscreen refs="carousel">
    <v-card style="background-color: #222" flat tile class="d-flex justify-center">
      <v-btn style="position: absolute; right: 30px; top: 30px; z-index: 100" fab dark small color="blue-grey" title="Закрыть окно" @click="doClose()">
        <v-icon dark> mdi-close </v-icon>
      </v-btn>
      <v-btn style="position: absolute; left: 30px; top: 30px; z-index: 100" fab dark small id="btn-full-screen" color="blue-grey" title="full screen" @click="toggle()">
        <v-icon dark v-if="!fullscreen"> mdi-arrow-collapse </v-icon>
        <v-icon dark v-else> mdi-arrow-expand </v-icon>
      </v-btn>
      <div class="video-container">
        <video controls playsinline style="display: block; margin: 0 auto" crossorigin="anonymous">
          <source :src="src" type="video/mp4" crossorigin="anonymous" />
        </video>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    src: String,
  },
  data() {
    return { current: 0, fullscreen: true };
  },
  watch: {
    value(v) {
      if (v) {
        this.current = this.curr;
      }
    },
  },
  created() {
    this.current = this.curr;
  },
  computed: {},
  methods: {
    doClose() {
      if (!this.fullscreen) {
        this.toggle();
      }
      this.$emit("input");
    },
    async toggle() {
      await this.$fullscreen.toggle();
      this.fullscreen = this.$fullscreen.isFullscreen;
    },
  },
};
</script>
<style lang="scss" scope>
.video-container {
  width: 100%;
}

.video-container video {
  width: 100%;
  height: auto;
}
</style>
