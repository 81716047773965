<template>
  <div v-if="model && model.buttons" class="d-flex">
    <v-btn
      v-for="(item, i) of model.buttons"
      :key="i"
      elevation="0"
      x-small
      fab
      class="ma-0 ml-1"
      :color="item.color || 'green'"
      :title="item.title || ''"
      @click.stop="openLink(item)"
    >
      <v-icon>{{ item.icon }}</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    model: Object,
  },
  data() {
    return {
      showDialog: false,
      modelForm: null,
    };
  },
  computed: {},
  created() {},
  watch: {
    showDialog(v) {
      if (!v) this.resetFrom();
    },
  },

  methods: {
    openLink(url) {
      window.open(url, "_blank");
    },
  },
};
</script>
