<template>
  <div class="py-1 pb-3">
    <v-card>
      <v-card-title class="py-2">
        {{ model.title }}
        <v-spacer></v-spacer>
        <div class="text-right pa-1">
          <v-btn v-if="!readonly" class="ma-1" @click="createNew()" color="primary"> Добавить </v-btn>
        </div>
      </v-card-title>
      <v-card-text :style="`height: ${detailHeight}px; overflow: auto`">
        <a-table-f-api
          ref="table"
          :api="m_table.api"
          :model="m_table.list"
          :useQuery="false"
          :selectedRows="selectedRows"
          :defaults="{
            filters,
            sort,
          }"
          @click="
            counter += 1;
            onDetectClick($event);
          "
        />
      </v-card-text>
    </v-card>
    <s-edit-form
      v-model="showEditDialog"
      v-if="showEditDialog"
      :api="m_table.api"
      :m="m_table"
      :id="idEdit"
      :initData="initData"
      :configForm="{ type: 'default' }"
      :opt="{ closeAfterSave: !false, delete: !readonly }"
    />
  </div>
</template>

<script>
import { dataUtils, getAccess, doubleClickDetect, genModel } from "@/components/mixings";
export default {
  mixins: [getAccess, doubleClickDetect, dataUtils, genModel],
  props: {
    values: Object,
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },

    config: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      showEditDialog: false,
      configH: null,
      idEdit: null,
      filters: null,
      initData: null,
    };
  },
  watch: {
    showEditDialog(v) {
      if (!v) {
        let id = this.idEdit;
        this.idEdit = null;
        if (id) this.$refs.table.updateRowData(id);
        else this.$refs.table.updateData();
      }
    },
  },
  computed: {
    sort() {
      return this.model?.sort || { key: "id", order: "DESC" };
    },
    detailHeight() {
      return 300;
    },
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
    m_table() {
      let model;
      if (typeof this.model.model === "string") {
        model = this.getObjectPath(this.$store.getters["config/get"].models, this.model.model);
      }
      this.initData = this.getInitData(this.model, { values: this.values });
      this.filters = this.getFilters(this.model, { values: this.values });
      return model;
    },
  },
  methods: {
    createNew() {
      this.showEditDialogFun(0);
    },
    onDoubleClick(d) {
      this.showEditDialogFun(d.row.id);
      return;
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
  },
};
</script>
