var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{class:{ menu__list: true, 'menu__list--level2': !!_vm.level }},_vm._l((_vm.data),function(item,i){return _c('li',{key:i,class:{ menu__item: true, 'menu__item--level2': !!_vm.level },attrs:{"link":"","to":item.route}},[_c('a',{class:{
        menu__link: true,
        'menu__link--level2': !!_vm.level,
        'link--active': _vm.$route.params.id == item.id || _vm.active || (_vm.child.includes(Number(_vm.$route.params.id)) && item.is_parent),
        'menu__link--level2_active': !!_vm.level && _vm.$route.params.id == item.id,
        'menu__link--level2__lock': item.lock,
      },attrs:{"disabled":item.lock,"href":item.is_parent || item.lock ? '#' + _vm.$route.path : `#/education-person/${item.id}`},on:{"click":function($event){return _vm.$emit('active', item.parent_id)}}},[_vm._v(" "+_vm._s(item.name)+" ")]),(item.is_parent)?_c('list-edu-menu-item',{key:i,attrs:{"value":item,"level":_vm.level + 1,"parent":item.id},on:{"active":function($event){return _vm.setActive($event)},"child":function($event){_vm.child = [...$event]}}}):_vm._e()],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }