<template>
  <v-expansion-panels accordion v-model="panelPlan">
    <v-expansion-panel>
      <v-expansion-panel-header>Данные для планирования</v-expansion-panel-header>
    </v-expansion-panel>

    <v-row class="fill-height ma-0 pl-0" align="center" justify="center" style="overflow: auto">
      <v-col
        v-for="(el, key) of items"
        :key="key"
        cols="4"
        sm="5"
        md="4"
        lg="3"
        class="pa-1"
        :class="{
          'pl-0': $vuetify.breakpoint.smAndDown,
        }"
      >
        <v-card v-if="el.includes('video#')" draggable="true" class="ma-0" active-class="" width="100%">
          <a
            :href="$root.config.videoUrl + el.split('#')[1]"
            target="_blank"
            @click.prevent="
              fullScr = el;
              currImage = key;
              fullImage = true;
            "
          >
            <v-img :aspect-ratio="1 / 1" :src="$root.config.imageUrl + el.split('#')[1] + '?width=' + size + '&height=' + size">
              <s-video-icon :id="el.split('#')[2]" />
            </v-img>
          </a>
        </v-card>
        <v-card v-else draggable="true" class="ma-0" active-class="" width="100%">
          <a
            :href="$root.config.imageUrl + el"
            target="_blank"
            @click.prevent="
              fullScr = el;
              currImage = key;
              fullImage = true;
            "
          >
            <v-img :aspect-ratio="1 / 1" :src="$root.config.imageUrl + el + '?width=' + size + '&height=' + size" />
          </a>
        </v-card>
      </v-col>
    </v-row>
  </v-expansion-panels>
</template>

<script>
export default {
  props: {
    value: Array,
    size: { type: Number, default: 150 },
  },
  computed: {
    items: {
      get() {
        if (this.value) {
          try {
            return this.value; //JSON.parse(this.value);
          } catch (error) {}
        }
        return [];
      },
    },
  },
  data() {
    return {
      fullImage: false,
      currImage: 0,
      fullScr: "",
    };
  },
  methods: {},
};
</script>
