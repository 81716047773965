<template>
  <v-autocomplete
    outlined
    :clearable="!readonly"
    :disabled="disabled"
    :items="items"
    :value="value"
    :label="model.title"
    :error-messages="error"
    :hide-details="config.hideDetails || false"
    :dense="config.dense || false"
    :readonly="readonly"
    item-text="text"
    item-value="user_id"
    @input="onInput($event)"
    @keydown="onKeyDown"
    :small-chips="model.chips || false"
    :multiple="model.chips || false"
    :deletable-chips="model.chips && !readonly"
    :hide-selected="model.chips || false"
    :return-object="model.chips || false"
  >
    <template v-slot:selection="data" v-if="model.chips || false">
      <v-chip v-bind="data.attrs" :input-value="data.selected" :close="config.readonly != true" small @click="data.select" @click:close="remove(data.item)" class="mt-1">
        {{ data.item.text }}
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  // mixins: [modelOptions],
  props: {
    value: [Array, Number, Object],
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    error: {
      type: String,
      default: "",
    },
    config: { type: Object, default: {} },
  },
  data() {
    return {
      data: [],
      //roles: this.$store.getters["config/get"].models.users.roles,
      roles1: this.$root.dirs.roles,
      showAllItem: false,
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
    api() {
      return "mechti/tasks/users/select";
    },
    roles() {
      let res = this.$root.dirs.roles;
      return res;
    },
    items() {
      let res = [];
      this.data.forEach((d) => {
        if (d.user_id == this.value) res.push(d);
        else if (!this.checkDisabled(d)) res.push(d);
      });
      return res;
    },
  },
  methods: {
    onKeyDown(e) {
      if (e.key === "*") {
        this.showAllItem = !this.showAllItem;
        e.preventDefault();
      }
    },
    checkDisabled(e) {
      if (this.showAllItem) return false;

      if (e?.isActive === false || e.hidden) return true;
      if (this?.model?.activeFormula) {
        const formula = this.model.activeFormula;
        //console.log("checkDisabled formula", formula, e.role, e);
        let func;
        if (typeof formula == "function") func = formula;
        if (typeof formula == "string") func = eval(formula);
        return !func(e);
      }
      return false;
    },
    remove(item) {
      if (this.readonly) return;
      const index = this.value.findIndex((el) => {
        return el.user_id == item.user_id;
      });
      if (index >= 0) this.value.splice(index, 1);
    },
    onInput(e) {
      this.$emit("input", e);
    },
    async fetchData() {
      this.loading = true;
      let params = {};
      console.log("ffffff", this.model);
      if (this.model?.api?.filters) params.filters = this.model.api.filters;
      params.sort = { key: "name", order: "ASC" };
      let response = await this.$axios.get(this.api, { params });
      this.loading = false;
      //console.log("users", response.data);
      let data = [];
      let curr = [];
      if (this.value)
        curr =
          typeof this.value == "object"
            ? this.value.map((v) => {
                return v.user_id;
              })
            : [this.value];
      for (let el of response.data.data) {
        if (el.status == 1 || (curr && curr.includes(el.value)))
          data.push({
            user_id: el.value,
            role: el.role,
            status: el.status,
            text:
              el.text +
              " (" +
              this.roles.find((r) => {
                return r.value == el?.role;
              })?.text +
              ")",
          });
      }
      //response.data.data
      this.data = data;
    },
  },
};
</script>
<style lang="scss"></style>
