/** @format */

import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import config from './modules/config';
import state from './modules/state';
import comment from './modules/comment';
import dirs from './modules/dirs';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    config,
    state,
    comment,
    // dirs,
  },
});
