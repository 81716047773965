<template>
  <div>
    <v-card-text :style="`height: ${height}px; overflow: auto`" class="pa-0">
      <a-table-f-api
        ref="table"
        :api="api"
        :model="model"
        :searchable="false"
        :useQuery="false"
        :defaults="defaults"
        @click="onClick($event)"
        @loadData="onUpdateData($event)"
      >
        <template v-slot:top>
          <v-fab-transition v-if="canAdd">
            <v-btn fab color="green" absolute bottom small style="bottom: 15px" left class="v-btn--example" @click="onAdd()">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-fab-transition>
        </template>
      </a-table-f-api>
    </v-card-text>
    <portal to="v-main"> </portal>
  </div>
</template>

<script>
import { getAccess, popupMenu, genModel } from "@/components/mixings";
export default {
  mixins: [getAccess, popupMenu, genModel],
  components: {},
  props: {
    parent: String,
    balance: { type: Object, default: () => {} },
    data: { type: Object, default: () => {} },
    height: { type: Number, default: 300 },
    canAdd: Boolean,
  },
  data() {
    return {
      m: this.$store.getters["config/get"].models.payOrder,
      disableAdd: true,
      mistakes: null,
    };
  },
  created() {},
  watch: {},
  computed: {
    defaults() {
      let res = {
        filters: {
          parent_name: this.parent,
          parent_id: this.data.id,
        },
        sort: { key: "id", order: "DESC" },
        paramName: "payOrder",
      };
      return res;
    },

    api() {
      return this.m.api;
    },
    model() {
      const model = this.getModelList(this.m);
      return model;
    },
  },
  methods: {
    refresh() {
      this.$refs.table.updateData();
    },
    onAdd() {
      if (this.mistakes) {
        this.$root.$emit("show-info", { type: "error", text: this.mistakes });
        this.refresh();
        return;
      }
      let val = 0;
      try {
        val = (this.$refs.table?.data || [])
          .filter((d) => [4].includes(d.status))
          .reduce((v, d) => {
            return v + d.amount;
          }, 0);
        val = this.balance.credit - this.balance.debit - val;
      } catch (error) {
        console.error(error);
      }
      this.$emit("add", val);
    },
    checkMistakes() {
      let res = [];
      if (this.disableAdd) res.push("Предыдущие заявки должны быть выполнены");
      if (this.data?.status !== 2) res.push("Документ заказа должен быть проведен");
      if (!((this.balance?.credit || 0) - (this.balance?.debit || 0) > 0)) res.push("Не могу определить сумму для оплаты");
      this.mistakes = res.join("<br>");
    },

    onUpdateData(e) {
      this.disableAdd = !!e.find((d) => [0, 1].includes(d.status));
      this.checkMistakes();
    },
    onClick(e) {
      if (!e.row.id) return;
      const routeData = this.$router.resolve({ name: "payOrder_view", params: { id: e.row.id } });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>
