<template>
  <v-btn :color="color" @click="$emit('click')" class="mx-1" :disabled="disabled">
    <v-icon>{{ icon }}</v-icon>
    <btn-title v-if="$vuetify.breakpoint.smAndUp">{{ title }}</btn-title>
  </v-btn>
</template>

<script>
export default {
  props: {
    title: { type: String, default: "Записать" },
    disabled: { type: Boolean, default: false },
    color: { type: String, default: "primary" },
    icon: { type: String, default: "far fa-save" },
  },
};
</script>
