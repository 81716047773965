<template>
  <div>
    <a-form-view
      :value="balance"
      :model="[
        { name: 'credit', title: 'К оплате по документу', type: 'number' },
        { name: 'debit', title: 'Выплачено по документу', type: 'number' },
        { name: 'saldo', title: 'Не выплачено по документу', value: balance.credit - balance.debit, type: 'number' },
        { name: 'overpay', title: model.overpay || 'Излишек', value: overpay, type: 'number', hide: !(overpay > 0 && model.overpay) },
      ]"
      @click="getBalance()"
      :config="{
        dense: true,
      }"
    />
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    values: { type: Object, default: () => {} },
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      current: null,
      debit: 0,
      credit: 0,
    };
  },
  created() {
    this.getBalance();
  },
  watch: {
    "values.parent_id"() {
      this.getBalance();
    },
  },
  computed: {
    overpay() {
      let res = this.credit - this.debit - (this.values?.value || 0);
      if (this.values?.status == 2) res += this.values?.value || 0;
      if (res < 0) return -res;
      return 0;
    },
    balance() {
      return { debit: this.debit, credit: this.credit };
    },
  },
  methods: {
    getParam(s) {
      let res = -1;
      let param = this.model?.paramSQL || {};
      let sub = eval(param?.[s] || "-1");
      if (typeof sub === "function") res = sub(this.values);
      else res = sub;
      return res;
    },
    async getBalance() {
      //this.loaded = false;
      //this.loading = true;
      let debit = 0;
      let credit = 0;
      let acc = this.getParam("account");
      let doc_name = this.getParam("doc_name");
      let doc_id = this.getParam("doc_id");
      let resp = await this.$axios.post("/report_sql", { name: "getBalance2", params: { acc, doc_name, doc_id } });
      this.loading = false;
      if (resp.data.status == "ok") {
        resp.data.data.forEach((el) => {
          debit += el.value_db;
          credit += el.value_cr;
        });
      }
      this.credit = credit;
      this.debit = debit;
      this.$emit("fieldData", this.balance);
    },
  },
};
</script>
