/** @format */
const projects = {
  statuses: [
    { value: 1, text: 'Не публикуется сайте', next: [2], action: 'Убрать с сайта', field: 'status_site' },
    { value: 2, text: 'Публикуется сайте', next: [1], action: 'На сайт', field: 'status_site' },
    { value: 0, text: 'Публикация не настроена', next: [2], action: '', field: 'status_site' },
    { value: null, text: 'Публикация не настроена', next: [2], action: '', field: 'status_site' },
  ],
  list: [
    { name: 'id', title: '#', type: 'id', sortable: true, width: 50 },
    { name: 'city', title: 'Город', type: 'string', filter: { name: 'city', type: 'select', options: 'city' }, sortable: true, width: 150 },
    { name: 'name', title: 'Название', type: 'string', sortable: true, width: 300 },
    { name: 'class', title: 'Класс', type: 'select', options: 'objectClasses', sortable: true, width: 300 },
    { name: 'ap_area', title: 'Площадь', type: 'number', sortable: true, width: 80 },
    { name: 'ap_rooms', title: 'Количество комнат', type: 'string', sortable: true, width: 80 },
    { name: 'ap_ceiling_height', title: 'Высота потолков', type: 'string', sortable: true, width: 80 },
    { name: 'ap_price_buy', title: 'Стоимость', type: 'string', sortable: true, width: 80 },
  ],
  form: [
    { name: 'name', title: 'Название', type: 'string', validator: ['req'] },
    { name: 'city', title: 'Город', type: 'select', options: 'city', validator: ['req'] },
    { name: 'ap_area', title: 'Площадь', type: 'number' },
    { name: 'ap_rooms', title: 'Количество комнат', type: 'number' },
    { name: 'ap_level', title: 'Уровней', type: 'number' },
    { name: 'ap_layout', title: 'Планировка', type: 'text' },
    { name: 'ap_ceiling_height', title: 'Высота потолков', type: 'number' },
    { name: 'ap_price_buy', title: 'Стоимость квартиры', validator: ['req'], type: 'number' },
    { name: 'class', title: 'Класс', type: 'select', options: 'objectClasses' },
    { name: 'description', title: 'Описание', type: 'text' },
    { name: 'photos_design', title: 'Фото галерея', type: 'images' },
    { name: 'docs', title: 'документация', type: 'filesdata', category: 1, hiddenCreate: true },
    { name: 'filesAdd', default: [], hidden: true },
  ],
  config: {
    form: {
      title: 'Данные объекта',
      fields: 'name,city,ap_area,ap_rooms,ap_ceiling_height,ap_layout,ap_level,ap_price_buy,class,description,photos_design,docs',
      fieldsRO: '',
      fieldsForm: 'name,city,ap_area#6,ap_ceiling_height#6,ap_rooms#6,ap_price_buy#6,ap_level#6,class#6,ap_layout#6,description#6,photos_design,docs',
    },
  },
};
export default projects;
