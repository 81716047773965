/** @format */

import fillForm from './fillForm';

export default {
  mixins: [fillForm],
  data() {
    return {
      loading: false,
      permit: true,
      watchModel: null,
    };
  },
  methods: {
    updateData(id) {
      this.resetFrom();
      this.fillForm();
      this.fetchData(id);
    },
    setData(name, value) {
      let field = '';
      let path = name.split('.');
      if (path.length == 2) {
        field = path[0];
        name = path[1];
      }
      //      let field = el?.jsonField || 'data';
      if (field) {
        let data = JSON.parse(JSON.stringify(this.data[field] || {}));
        data[name] = value;
        this.data[field] = data;
      } else this.data[name] = value;
    },
    setFieldData(e) {
      if (!e) return;
      if (this.watchModel) {
        for (const key in this.watchModel) {
          if (key == e.name) {
            let data = e.value;
            let v = this.watchModel[key];
            for (const field in v) {
              if (this.data.hasOwnProperty(field) || true) {
                if (v[field].type === 'field') {
                  this.setData(field, data?.[v[field].value]);
                } else if (v[field].type === 'value') {
                  this.setData(field, v[field].value);
                } else {
                  console.log('не известный тип сопоставления данных ' + v[field].type, this.watchModel, field);
                }
              } else {
                if (v[field].type === 'model') {
                  this.model.find((m) => m.name == field)[v[field].param] = v[field].value;
                  console.log(
                    'change model',
                    this.model.find((m) => m.name == field)
                  );
                  //this.setData(field, v[field].value);
                } else console.log('не найдено поле для сопоставления данных ' + field);
              }
            }
          }
        }
      }
    },
    async fetchData(id) {
      if (id || (id === 0 && this?.id0 === true)) {
        this.loading = true;
        let response;
        try {
          response = await this.$axios.get(this.api + '/' + id);
          let data = response.data.data;

          for (const name in data) {
            if (name == 'data') {
            }
            if (name == 'data' && this.data?.data && data[name]) {
              for (const nameData in data.data) {
                this.$set(this.data.data, nameData, data.data[nameData]);
                this.$set(this.errors, nameData, null);
              }
            } else if (name == 'data' && typeof this.data.data == 'object') {
            } else {
              this.$set(this.data, name, data[name]);
              this.$set(this.errors, name, null);
            }
          }
        } catch (error) {}
        this.loading = false;

        this.afterFetchData(response);
      } else this.afterFetchData();
    },
    afterFetchData(response) {},
  },
};
