<template>
  <div>
    <dialog-edit2 :value="value" @input="$emit('input')" :maxWidth="'600px'">
      <div style="display: inline; width: 100hv">
        {{ data }} is {{ typeof data }}
        <span v-if="typeof data === 'string'">
          <input ref="inputEl" @change="onChangeInput" v-model="data" />
        </span>
        <span v-if="typeof data === 'number'">
          <input ref="inputEl" @change="onChangeInput" v-model="data" type="number" />
        </span>
        <span v-if="typeof data === 'boolean'">
          <select ref="inputEl" @change="onChangeInput" v-model="data">
            <option value="true">True</option>
            <option value="false">False</option>
          </select>
        </span>
        <span v-if="typeof data === 'object'">
          <input ref="inputEl" @change="onChangeInput" v-model="data" />
        </span>
      </div>

      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save @click="submit()"></a-btn-save>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit2>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    value: Boolean,
    el: [Array, Object, String, Number, Boolean],
    field: [Object],
  }, 
  data: function () {
    return {
      open: false,
      isEdited: false,
      newValue: undefined,
      addType: 0,
      addTypes: ["String", "Number", "Array", "Object"],
      drag_src_el: null,
      openTable: false,
    };
  },
  computed: {
    data: {
      get: function () {
        return this.el;
      },
      set: function (v) {
        this.newValue = v;
      },
    },
  },
  created() {
    //this.newValue=this.el
  },
  methods: {
    submit() {
      this.$emit("change", this.newValue);
      this.$emit("input");
    },
    onChangeInput: function (e) {
      // this.onBlurInput(e);
    },
    onBlurInput: function (e) {
      this.isEdited = false;
      if (typeof this.el === "number") {
        this.value = Number(this.newValue);
        if (typeof this.el === "number") {
          //   this.$emit("change", this.newValue);
        }
      }
      if (typeof this.el === "string") {
        this.value = String(this.newValue);
        if (this.value.length > 0) {
          //   this.$emit("change", this.newValue);
        }
      }
      if (typeof this.el === "boolean") {
        this.value == "true" || this.newValue === true ? (this.newValue = true) : (this.newValue = false);
        // this.$emit("change", this.newValue);
      }
      if (typeof this.el === "object") {
        if (Array.isArray(this.el)) this.newValue = JSON.parse("[" + this.newValue + "]");

        //  this.$emit("change", this.newValue);
      }
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss">
.v-tree-item {
  display: inline;
}
.v-tree-item-li {
}
.v-tree-item-caption {
  margin-left: 5px;
  margin-right: 5px;
  font-weight: bold;
}
</style>