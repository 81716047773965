<template>
  <popup-modal ref="popup">
    <h2 style="margin-top: 0" class="mb-5">{{ title }}</h2>
    <p v-for="el in message">{{ el }}</p>
    <a-form-model v-model="data" :model="model" :errors="errors" :config="{ dense: true }" @selected="onSelect($event)" @validate="validate($event)" />
    <div class="btns">
      <v-btn @click="_confirm" color="error">{{ okButton }}</v-btn>
      <v-btn @click="_cancel">{{ cancelButton }}</v-btn>
    </div>
  </popup-modal>
</template>
<script>
import { getForm, submitForm, removeEl } from "./mixings";
export default {
  mixins: [getForm, submitForm, removeEl],
  components: {},

  data: () => ({
    // Parameters that change depending on the type of dialogue
    title: undefined,
    message: undefined, // Main text content
    okButton: undefined, // Text for confirm button; leave it empty because we don't know what we're using it for
    cancelButton: "Закрыть", // text for cancel button
    model: [],
    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined,
    selected: null,
  }),

  methods: {
    show(opts = {}) {
      this.resetForm();
      this.title = opts.title;
      this.message = opts.message;
      this.selected = null;
      this.okButton = opts.okButton;
      this.model = opts.model;
      this.updateData(0);
      if (opts.cancelButton) {
        this.cancelButton = opts.cancelButton;
      }
      // Once we set our config, we tell the popup modal to open
      this.$refs.popup.open();
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    onSelect(e) {
      this.selected = e;
    },
    async _confirm() {
      if (await this.validateAll(this.data)) {
        //console.log('save', this.data);
        this.$refs.popup.close();

        this.resolvePromise({ status: true, data: this.data, selected: this.selected });
        return;
      }
      return;
    },

    _cancel() {
      this.$refs.popup.close();
      this.resolvePromise(false);
      // Or you can throw an error
      // this.rejectPromise(new Error('User cancelled the dialogue'))
    },
  },
};
</script>

<style scoped>
.btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ok-btn {
  color: red;
  text-decoration: underline;
  line-height: 2.5rem;
  cursor: pointer;
}

.cancel-btn {
  padding: 0.5em 1em;
  background-color: #d5eae7;
  color: #35907f;
  border: 2px solid #0ec5a4;
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
}
</style>
