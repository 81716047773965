<template>
  <v-dialog v-model="dialog" max-width="800" @keydown.esc="dialog = false">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="amber lighten-3" :disabled="disabled" class="px-2" :title="title" v-bind="attrs" v-on="on">
        <v-icon>{{ icon }}</v-icon>
        <btn-title v-if="$vuetify.breakpoint[`${size}AndUp`] && title && false">{{ title }}</btn-title>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="lighten-2">
        <v-btn style="position: absolute; right: 12px; top: 12px" fab dark small color="blue-grey" title="Закрыть окно" @click="dialog = false"
          ><v-icon dark> mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row class="mt-4">
          <v-col cols="12" class="pb-0">
            <a-input-object :model="model" :value="value" :readonly="true" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Object,
    size: { type: String, default: "sm" },
    title: { type: String, default: "Данные импорта" },
    disabled: { type: Boolean, default: false },
    icon: { type: String, default: "fas fa-clipboard-list" },
    model: { type: Object, default: () => {} },
  },
  data() {
    return {
      dialog: false,
    };
  },
  created() {},
  watch: {},
  methods: {},
};
</script>
