<template>
  <span :style="style">
    <span v-if="expired" class="red--text">! {{ value }} </span>
    <span v-else> {{ value }}</span>
  </span>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    values: Object,
    model: Object,
  },
  computed: {
    expired() {
      if (this.values.status == 2 && new Date(this.values.date_event).getTime() < new Date().getTime()) return true;
      return false;
    },
    style() {
      let style = "";
      if (this.model?.style) style = this.model?.style;
      return style;
    },
  },
};
</script>
