/** @format */

const furnitureReports = {
  title: 'Отчет по мебели',
  routeName: 'actWork_view',
  accessName: 'furnitureReports',
  accDocName_: 'AccountingDocActWork',
  api: '/mechti/furniture-reports',
  statuses: [
    { value: 0, text: 'черновик', next: [], action: 'Вернуть' },
    { value: 1, text: 'На проверке', next: [2], action: 'Распровести' },
    { value: 2, text: 'проведен', next: [1], action: 'Провести' },
  ],
  list: [
    { name: 'id', title: '#', sortable: true, width: 50 },
    { name: 'createdon', title: 'Дата', type: 'date', sortable: true, width: 100 },
    { name: 'description', title: 'Описание', type: 'text', width: 300 },
    { name: 'status', title: 'Статус', type: 'select', sourceList: 'statuses', sortable: true, width: 150 },

    // { name: 'name', title: 'Наименование', type: 'string', sortable: true, width: 250 },
    { name: 'photos', title: 'Фото', type: 'image', width: 240, count: 5 },
  ],

  form: [
    //  { name: 'name', title: 'Наименование', validator: ['req'], type: 'string' },
    { name: 'description', title: 'Описание', type: 'text', validator: ['req'] },
    { name: 'photos', title: 'Галерея', validator: ['req1'], type: 'images' },
    { name: 'status', title: 'Статус', validator: ['req'], default: 1, hidden: true },
  ],

  config: {
    default: {
      fields: 'vendor_id,work_name,value,photos,description,docs,filesRemove,filesAdd,status,createdby_id,construction_schedule_id',
      fieldsRO: '',
      fieldsForm: 'createdby_id,vendor_id,work_name,value,description,photos,docs',
    },
    1: {
      fields: 'vendor_id,work_name,photos,value,value_penalty,contract_id,status,createdby_id,construction_schedule_id,description,docs,filesRemove,filesAdd',
      fieldsRO: 'vendor_id,work_name,value',
      fieldsForm: 'createdby_id,vendor_id,work_name,value,contract_id#6,value_penalty#6,description,photos,docs',
    },
  },
};

export default furnitureReports;
