/** @format */

const investors = {
  title: 'Инвесторы',
  //routeName: 'payOrder_view',
  accessName: 'investor',
  isDocument: false,
  api: '/mechti/investors',
  list: [
    { name: 'id', title: '#', type: 'id', sortable: true, width: 50 },
    { name: 'name', title: 'ФИО', type: 'string', sortable: true, width: 250 },
    { name: 'object_total', title: 'Всего объектов', type: 'string', sortable: true, width: 150 },
    { name: 'object_active', title: 'Активных объектов', type: 'string', sortable: true, width: 150 },
    { name: 'invested', title: 'Инвестиций', type: 'string', sortable: true, width: 150 },
    { name: 'earned', title: 'Заработал', type: 'string', sortable: true, width: 150 },
  ],
  form: [
    { name: 'name', title: 'ФИО', type: 'fio', validator: ['req'] },
    { name: 'passport', title: 'Паспортная информация', type: 'string' },
    { name: 'phone1', title: 'Телефон', type: 'phone' },
    { name: 'phone2', title: 'Телефон 2', type: 'phone' },
    { name: 'whatsapp', title: 'WhatsApp', type: 'string' },
    { name: 'email', title: 'Email', type: 'string', validator: ['email'] },
    { name: 'notes', title: 'Заметки', type: 'text' },
    { name: 'photo', title: 'Фото', type: 'images' },
    { name: 'docs', title: 'Файлы', type: 'files' },
  ],
  config: {
    default: {
      title: 'Инвестор',
      fields: 'name,passport,phone1,phone2,whatsapp,email,notes,photo,docs',
      fieldsRO: '',
      fieldsForm: 'name,phone1#6,phone2#6,whatsapp#6,email#6,notes,photo,docs',
    },
  },
};

export default investors;
