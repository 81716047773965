<template>
  <div>
    <v-simple-table dense>
      <thead>
        <tr>
          <th class="text-left pa-0" width="150">Name</th>
          <th class="text-left pa-0">value</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="text-left pa-0">
            <a-input-string
              dense
              hide-details
              :model="model[0]"
              :config="conf"
              v-model="data['name']"
            />
          </td>
          <td class="text-left pa-0">
            <v-select
              dense
              outlined
              hide-details
              v-model="data.type"
              :label="model[1].title"
              :items="model[1].options"
            />

            <a-input-string
              v-if="data.type == 'string'"
              dense
              hide-details
              :config="conf"
              :model="model[2]"
              :value="data['value']"
              @input="onInput($event)"
            />

            <component
              v-else-if="data.type == 'array'"
              :is="'s-element'"
              :model="model"
              v-model="data.value"
              @validate="onInput($event)"
            >
            </component>
            <component
              v-else-if="data.type == 'object'"
              :is="'s-element'"
              :model="model"
              v-model="data.value"
              @validate="onInput($event)"
            >
            </component>
            <div v-else>укажите тип</div>
          </td>
        </tr>
      </tbody>
    </v-simple-table>

    <v-row v-if="false">
      <v-col cols="2">
        <a-input-string
          dense
          :model="model[0]"
          :config="conf"
          v-model="data['name']"
        />
      </v-col>
      <v-col cols="3">
        <v-select
          dense
          outlined
          v-model="data.type"
          :label="model[1].title"
          :items="model[1].options"
        />
      </v-col>
      <v-col cols="7">
        <a-input-string
          v-if="data.type == 'string'"
          dense
          :model="model[2]"
          :config="conf"
          v-model="data['value']"
        />

        <component
          v-else-if="data.type == 'array'"
          :is="'s-element'"
          :model="model"
          v-model="data.value"
          @validate="onInput($event)"
        >
        </component>
        <component
          v-else-if="data.type == 'object'"
          :is="'s-element'"
          :model="model"
          v-model="data.value"
          @validate="onInput($event)"
        >
        </component>
        <div v-else>укажите тип</div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { getForm } from "../mixings";

export default {
  mixins: [getForm],

  props: {
    value: [Object, String],
    model: Array,
  },
  data() {
    return {
      conf: { dense: true, hideDetails: false },
    };
  },
  computed: {},
  methods: {
    async onInput(e, el) {
      console.log("onInput", e);
      let data;
      if (this.data.type == "string") {
        data = JSON.stringify({ [this.data.name]: e });
      }
      if (this.data.type == "object") {
        data = JSON.stringify({ [this.data.name]: JSON.parse(e) });
      }
      this.$emit("validate", data);
    },
  },
};
</script>