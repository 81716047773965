/** @format */

const analytics = [
  {
    path: '/analysisBuying',
    name: 'analysisBuying',
    meta: { auth: 1, title: 'Анализ задач по покупкам' },
    component: () => {
      return import('@/views/analysis/buyings/index.vue');
    },
  },
  {
    path: '/analysisWork',
    name: 'analysisWork',
    meta: { auth: 1, title: 'Анализ задач по покупкам' },
    component: () => {
      return import('@/views/analysis/works/index.vue');
    },
  },
];

export default analytics;
