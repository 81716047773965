/** @format */

const accRecords = {
  list: [
    { name: 'id', title: '#', type: 'id', sortable: true, width: 30 },
    { name: 'createdon', title: 'Дата', type: 'datetime', sortable: true, width: 80 },
    { name: 'operation_id', title: '#', type: 'number', sortable: true, width: 50 },
    { name: 'operation_name', aliace: 'operation_id', title: 'Операция', type: 'select', dir: 'operationTable', width: 250, style: 'white-space: normal;' },
    { name: 'base_doc_name', title: 'Документ', type: 'string', width: 150, style: 'white-space: normal;', isMenu: true, sortable: true },
    { name: 'base_name', title: 'base_name', type: 'string', hidden: true, style: 'white-space: normal;' },
    { name: 'base_id', title: 'base_id', type: 'string', width: 50, hidden1: true },
    { name: 'status', title: 'status', type: 'string', width: 50, hidden: true },
    { name: 'credit_account', title: 'Кредит счет', type: 'string' },
    { name: 'credit_subconto_value_1', title: 'crs_1', type: 'string', width: 50, hidden: true },
    { name: 'credit_subconto_value_2', title: 'crs_2', type: 'string', width: 50, hidden: true },
    { name: 'credit_subconto_value_3', title: 'crs_3', type: 'string', width: 50, hidden: true },
    { name: 'credit_subconto_value_4', title: 'crs_4', type: 'string', width: 50, hidden: true },
    { name: 'credit_subconto_value_1_name', title: 'Кредит субконто 1', type: 'string', width: 150, style: 'white-space: normal;', sortable: true },
    { name: 'credit_subconto_value_2_name', title: 'Кредит субконто 2', type: 'string', width: 150, style: 'white-space: normal;', sortable: true },
    { name: 'credit_subconto_value_3_name', title: 'Кредит субконто 3', type: 'string', width: 150, style: 'white-space: normal;', sortable: true },
    { name: 'credit_subconto_value_4_name', title: 'crsn_4', type: 'string', width: 150, hidden: true },
    { name: 'debit_account', title: 'Дебет счет', type: 'string' },
    { name: 'debit_subconto_value_1', title: 'des_1', type: 'string', width: 50, hidden: true },
    { name: 'debit_subconto_value_2', title: 'des_2', type: 'string', width: 50, hidden: true },
    { name: 'debit_subconto_value_3', title: 'des_3', type: 'string', width: 50, hidden: true },
    { name: 'debit_subconto_value_4', title: 'des_4', type: 'string', width: 50, hidden: true },
    { name: 'debit_subconto_value_1_name', title: 'Дебет субконто 1', type: 'string', width: 150, style: 'white-space: normal;', sortable: true },
    { name: 'debit_subconto_value_2_name', title: 'Дебет субконто 2', type: 'string', width: 150, style: 'white-space: normal;', sortable: true },
    { name: 'debit_subconto_value_3_name', title: 'Дебет субконто 3', type: 'string', width: 150, style: 'white-space: normal;', sortable: true },
    { name: 'debit_subconto_value_4_name', title: 'Дебет субконто 4', type: 'string', width: 150, hidden: true },
    { name: 'value', title: 'Сумма', type: 'number', sortable: true },
    { name: 'amount', title: 'Кол-во', type: 'string' },
    { name: 'comments', title: 'Примечание', type: 'string', width: 150, style: 'white-space: normal;' },
  ],
};
export default accRecords;
