<template>
  <div class="d-flex" style="position: relative">
    <v-select
      outlined
      :clearable="!readonly"
      :items="getOptions(model)"
      :item-text="textField"
      :item-value="valueField"
      :item-disabled="hiddenField"
      :value="value"
      :label="$t(model.title)"
      :error-messages="error"
      :disabled="disabled"
      :hide-details="config.hideDetails || false"
      :readonly="readonly"
      :dense="config.dense || false"
      @input="$emit('input', $event)"
      :small-chips="model.chips || false"
      :multiple="model.chips || false"
      :deletable-chips="model.chips || false"
      :hide-selected="model.chips || false"
    >
    </v-select>
    <btn-rating
      v-if="(model.rating && config.rating) || false"
      v-model="rating"
    >
    </btn-rating>
  </div>
</template>

<script>
import modelOptions from "./../../libs/modelOptions";

/*
<!--       @focus="toggleSelect = true"
      @blur="toggleSelect = false"
      @click="toggleSelect = true"
      :menu-props="{
        value: toggleSelect,
        'offset-y': true,
        closeOnContentClick: true,
      }" -->

*/
export default {
  mixins: [modelOptions],
  props: {
    value: [String, Number],
    values: {
      type: Object,
      default: () => {
        return {};
      },
    },

    model: Object,
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    config: { type: Object, default: {} },
  },
  data() {
    return {
      cc: 0,
      toggleSelect: false,
    };
  },
  computed: {
    textField() {
      return "id";
    },
    valueField() {
      return "id";
    },
    hiddenField() {
      return "hidden";
    },

    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },

    rating: {
      get() {
        let res = this.values.data?.rating?.[this.model.name] || "";
        return res;
      },
      set(v) {
        this.$emit("rating", { name: this.model.name, value: v });
      },
    },
  },
  methods: {},
};
</script>