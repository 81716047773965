/** @format */
const hidden = true;
const documentLoanGet = {
  title: 'Взятие займа',
  routeName: 'loanGet_view',
  accessName: 'loanGet',
  accDocName: 'AccountingDocGettingLoan',
  operations1111: { 1: [1, 2], 2: [10] },
  operationsCash: { 34: [51, 42, 37] }, 
  statuses: [
    { value: 1, text: 'не проведен', next: [2], action: 'Распровести' },
    { value: 2, text: 'проведен', next: [1], action: 'Провести' },
  ], 
  list: [
    { name: 'id', title: 'id', type: 'number', sortable: true, width: 30, hidden: true },
    { name: 'code_doc', title: 'Номер', type: 'string', sortable: true, width: 50 },
    { name: 'date_doc', title: 'Дата', type: 'datetime', sortable: true, width: 120 },
    { name: 'operation_type', title: 'Операция', type: 'select', dir: 'operationTable', dirFilter: { id: [34] }, style: 'white-space: normal;', width: 160 },
    {
      name: 'object_id',
      title: 'Назначение',
      type: 'select',
      dir: 'object',
      filter1: { dir: 'object', type: 'select', dirFilter: { status: 0 } },
      sortable: true,
      width: 150,
      style: 'white-space: normal;',
    },
    { name: 'borrower_id', title: 'Заемщик', type: 'select', dir: 'investor' },
    { name: 'investor_id', title: 'Инвестор', type: 'select', dir: 'investor' },
    { name: 'status', title: 'Статус', type: 'select', sourceList: 'statuses', sortable: true, width: 100 },
    { name: 'value', title: 'Сумма ', type: 'number', sortable: true, width: 80 },
    { name: 'value_first', title: 'Сумма первого взноса', type: 'number', hidden },
    {
      name: 'first_payment_type',
      title: 'Оплата первого взноса',
      type: 'select',
      options: [
        { value: 0, text: 'Инвестор' },
        { value: 1, text: 'Компания' },
      ],
      hidden,
    },
    { name: 'createdby_id', title: 'Автор', type: 'select', dir: 'user', filter: { type: 'select', name: 'createdby_id', dir: 'user' }, sortable: true, width: 150 },
    { name: 'balance', title: 'Долг', type: 'balanceApi', account: '67.1.1', value: 1, subconto3: '((d) => {return d.object_id;})', sortable: false, width: 150 },
  ],

  form: [
    { name: 'date_doc', title: 'Дата документа', type: 'datetime', hiddenCreate: true, card: 1 },
    { name: 'operation_type', title: 'Операция', type: 'select', dir: 'operationTable', dirFilter: { id: [34] }, validator: ['req'], card: 1 },
    { name: 'object_id', title: 'Объект', type: 'select', dir: 'object', validator: ['req'], card: 1, size: 6, autocomplete: true },
    { name: 'investor_id', title: 'Инвестор', type: 'selectapi', api: 'mechti/investors/select', validator: ['req'], card: 1 },
    { name: 'borrower_id', title: 'Заемщик', type: 'selectapi', api: 'mechti/investors/select', validator: ['req'], card: 1 },
    { name: 'credit_org_id', title: 'Кредитная организация', type: 'selectapi', api: 'accounting/dir/credit_org/select', validator: ['req'], card: 1 },
    { name: 'value', title: 'Cумма кредита', type: 'number', validator: ['req'], card: 2 },
    { name: 'value_first', title: 'Сумма первого взноса', type: 'number', validator: ['req'], card: -2 },
    { name: 'value_body', title: 'Тело кредита', type: 'number', calculated: true, formula: `((d) => {return (d?.value || 0) - (d?.value_first || 0);})` },

    { name: 'comments', title: 'Примечание', type: 'text', hiddenCreate: true, card: 1 },
    { name: 'doc_all', title: 'Документы', type: 'filesdata', hiddenCreate: true },
    { name: 'status', title: 'Статус', validator: ['req'], default: 1 },
    { name: 'filesRemove', default: [], hidden: true },
    { name: 'filesAdd', default: [], hidden: true },
    {
      name: 'first_payment_type',
      title: 'Оплата первого взноса',
      type: 'select',
      options: [
        { value: 0, text: 'Инвестор' },
        { value: 1, text: 'Компания' },
      ],
      validator: ['req'],
      card: 2,
      size: 6,
      default: 0,
      showNull: true,
    },
    { name: 'credit_payment_schedule_id', default: null },
  ],
  viewForm1: {
    fields: 'code_doc,date_doc,createdby_id,borrower_id,investor_id,credit_org_id,object_id',
  },
  viewForm2: {
    fields: 'value',
  },
  config: {
    watch: 'operation_type',
    default: {
      fields: 'operation_type',
      fieldsRO: '',
      fieldsForm: 'operation_type',
    },
    34: {
      note: 'Покупка недвижимости в кредит',
      fields: 'date_doc,operation_type,value_body,object_id,borrower_id,investor_id,credit_org_id,value,comments,doc_all,status,filesRemove,filesAdd',
      fieldsRO: '',
      fieldsForm: 'date_doc,operation_type,object_id,borrower_id#6,investor_id#6,credit_org_id#6,value#6,comments',
    },
  },
};
export default documentLoanGet;
