<template>
  <div class="d-flex" style="position: relative">
    <v-autocomplete
      color="red"
      outlined
      :clearable="!readonly"
      :items="data"
      :item-text="textField"
      :item-value="valueField"
      :value="value"
      :label="$t(titleValue)"
      :error-messages="error"
      :disabled="disabled"
      :hide-details="config.hideDetails || false"
      :dense="config.dense || false"
      :readonly="readonly"
      :prefix="preText"
      :no-data-text="'Нет вариантов для выбора'"
      @input="$emit('input', $event)"
      class="list-hide-disabled"
    ></v-autocomplete>
  </div>
</template>

<script>
import modelOptions from "../../libs/modelOptions";

export default {
  mixins: [modelOptions],
  props: {
    value: [String, Number, Object],
    values: Object,
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    error: {
      type: String,
      default: "",
    },
    config: { type: Object, default: {} },
  },
  data() {
    return {
      data: [],
      title: null,
      preText: null,
    };
  },
  created() {
    this.fetchData();
  },
  watch: {
    values(v) {
      if (v) this.fetchData();
    },
  },
  computed: {
    titleValue() {
      return this.title || this.model.title;
    },
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
    textField() {
      return this.model?.itemField?.text || "text";
    },
    valueField() {
      return this.model?.itemField?.value || "value";
    },
  },
  methods: {
    getApi(model) {
      let api = "";
      if (model?.apiVar) {
        const key = model.apiVar.key;
        if (this.values) {
          const v = model?.json ? this.values?.data?.[key] : this.values[key];
          const name = model.apiVar.apiName.find((el) => {
            return el.value == v;
          });
          api = name?.name || "";
          this.title = name?.title || null;
          this.preText = name?.preText || null;
        }
        return api;
      }
      return model.api;
    },

    async fetchData() {
      let api = await this.getApi(this.model);
      if (api) {
        let params = {};
        if (this.model.sort) {
          params.sort = this.model.sort;
        }
        if (this.model.filters) {
          params.filters = this.model.filters;
        }
        this.loading = true;

        let response = await this.$axios.get(api, { params });
        this.loading = false;
        let data = this.filterItems(response.data.data);
        this.data = data.multiSort(["disabled", "text"]);
      } else if (this.model?.options || this.model?.dir) {
        let data = this.filterItems(JSON.parse(JSON.stringify(this.getOptions(this.model) || [])));
        this.data = data.multiSort(["disabled", "text"]);
      }
    },
  },
};
</script>
<style lang="scss">
.v-list-item.v-list-item--disabled11 {
  display: none !important;
}
</style>
