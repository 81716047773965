<template>
  <div class="pb-2">
    <v-autocomplete
      v-if="model.autocomplete"
      outlined
      :items="getSortOptions(model)"
      :item-text="textField"
      item-value="value"
      :item-disabled="checkDisabled"
      menu-props="offset-y"
      :value="value"
      :label="$t(titleValue)"
      :error-messages="error"
      :disabled="disabled"
      :hide-details="config.hideDetails || false"
      :clearable="!readonly"
      :readonly="readonly"
      :dense="config.dense || false"
      @input="$emit('input', $event)"
      @keydown="onKeyDown"
      :small-chips="model.chips || false"
      :multiple="model.chips || false"
      :deletable-chips="model.chips || false"
      :hide-selected="model.chips || false"
    />
    <v-select
      v-else
      outlined
      :clearable="!readonly"
      :items="getSortOptions(model)"
      :item-text="textField"
      item-value="value"
      :item-disabled="checkDisabled"
      item-disabled1="hidden"
      :value="value"
      menu-props="offset-y"
      :label="$t(titleValue)"
      :error-messages="error"
      :disabled="disabled"
      :hide-details="config.hideDetails || false"
      :readonly="readonly"
      :dense="config.dense || false"
      @input="onInput($event)"
      @keydown="onKeyDown"
      :small-chips="model.chips || false"
      :multiple="model.chips || false"
      :deletable-chips="model.chips || false"
      :hide-selected="model.chips || false"
    />

    <a-form-view
      v-if="balance || true"
      :value="balance"
      :model="[
        { name: 'date_doc', title: 'Начало периода', type: 'datetime' },
        { name: 'value_start', title: 'Суммна на начало', type: 'number' },
        { name: 'value_in', title: 'Приход', type: 'number' },
        { name: 'value_out', title: 'Расход', type: 'number' },
        { name: 'value_end', title: 'Остаток в кассе', type: 'number' },
        { name: 'value_doc', title: 'После проводки', type: 'number' },
      ]"
      @click.native="getBalance()"
      :config="{
        dense: true,
        hideNull: true,
        size: 28,
      }"
    />
  </div>
</template>

<script>
import modelOptions from "./../../libs/modelOptions";

export default {
  mixins: [modelOptions],
  props: {
    value: [String, Number, Array],
    values: {
      type: Object,
      default: () => {
        return {};
      },
    },

    model: Object,
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    config: { type: Object, default: {}, disabled: false },
  },
  data() {
    return {
      cc: 0,
      showAllItem: false,
      title: null,
      preText: null,
      toggleSelect: false,
      balance: {},
    };
  },
  created() {
    this.getBalance();
  },
  computed: {
    textField() {
      return this.model.textField || "text";
    },
    options() {
      return this.getOptions(this.model) || [];
    },
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
    titleValue() {
      return this.title || this.model.title;
    },
  },

  watch: {
    "values.value"(v) {
      this.balance.value_doc = this.balance.value_end - (v || 0);
    },
    value(v) {
      this.getBalance();
    },
  },
  methods: {
    onKeyDown(e) {
      if (e.key === "*") {
        this.showAllItem = !this.showAllItem;
        e.preventDefault();
      }
    },
    checkDisabled(e) {
      if (this.showAllItem) return false;
      if (e?.isActive === false || e.hidden) return true;
      return false;
    },

    onInput(e) {
      this.$emit("input", e);
      if (this.model.cascade) {
        for (const key in this.model.cascade) {
          const el = this.model.cascade[key];
          if (el.type == "field") {
            let value = this.options.find((f) => f["value"] == e)?.[el.value];
            if (value !== undefined) {
              this.$emit("cascade", { field: el.value, value });
            }
          }
        }
      }
    },
    getSortOptions(model) {
      let list = JSON.parse(JSON.stringify(this.options));
      if (model?.dirVar) {
        const key = model.dirVar.key;
        if (this.values) {
          const v = model?.json ? this.values?.data?.[key] : this.values[key];
          const name = model.dirVar.dirName.find((el) => {
            return el.value == v;
          });
          this.title = name?.title || null;
          this.preText = name?.preText || null;
        }
      }
      if (this.value && !list.some((list) => list.value === this.value) && !Array.isArray(this.value)) {
        list = [{ text: this.value, value: this.value }, ...list];
      }
      return list.sort((a, b) => {
        return (a.text > b.text) - (a.text < b.text);
      });
    },
    async getBalance() {
      console.log("getBalance", this.value, this.values);
      this.loading = true;
      let valueIn = 0;
      let valueOut = 0;
      let resp;
      let balance = null;
      if (this.value && this.values?.status !== 2) {
        resp = await this.$axios.post("/report_sql", { name: "getBalanceCashBox", params: { id: this.value } });
        if (resp.data.status == "ok") {
          balance = resp.data.data[0];
          balance.value_doc = (balance?.value_end || 0) - this.values?.value;
        }
      }
      this.loading = false;
      this.balance = Object.assign({}, balance);
      console.log(this.balance);
      this.$emit("fieldData", this.balance);
    },
  },
};
</script>
<style lang="scss">
.v-list-item.v-list-item--disabled {
  display: none !important;
}
</style>
