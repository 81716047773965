/** @format */

const accounts = {
  operations: [
    { code: '10', name: 'income', title: 'Приход' },
    { code: '20', name: 'expense', title: 'Расход' },
  ],
  accounts: [
    { code: '11', operation: '10', name: 'investement', title: 'Инвестирование', subconto: { title: 'Объект', type: 'selectapi', api: 'mechti/objects/select' } },
    { code: '10', operation: '10', name: 'other', title: 'Прочее' },
    { code: '21', operation: '20', name: 'goods', title: 'Товары', subconto: { type: 'list', source: 'typeGoods' } },
    { code: '22', operation: '20', name: 'services', title: 'Услуги', subconto: { type: 'list', source: 'typeServices' } },
    { code: '23', operation: '20', name: 'salary', title: 'Зарплата' },
    { code: '20', operation: '20', name: 'other', title: 'Прочее' },
  ],
  typeServices: [{ value: 'Прочие услуги' }, { value: 'Услуги подрядчика' }, { value: 'Услуги дизайнера' }],
  typeGoods: [{ value: 'Мебель' }, { value: 'Черновые материалы' }, { value: 'Декор' }, { value: 'Декор' }, { value: 'Офис' }, { value: 'Прочие товары' }],
};

export default accounts;
