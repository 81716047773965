<template>
  <v-btn color="primary" @click="table2xls" :disabled="disabled" class="px-2">
    <v-icon>el-icon-document</v-icon>
    <btn-title v-if="$vuetify.breakpoint.smAndUp">{{ title }}</btn-title>
  </v-btn>
</template>

<script>
export default {
  props: {
    data: Array,
    model: Array,
    title: { type: String, default: "Экспорт" },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      filename: null,
      downloadLoading: false,
    };
  },
  methods: {
    table2xls() {
      const formatJson = (filterVal, jsonData) => {
        return jsonData.map((v) =>
          filterVal.map((j) => {
            if (j === "timestamp") {
              return parseTime(v[j]);
            } else {
              return v[j];
            }
          })
        );
      };
      this.downloadLoading = true;
      let headerTitle = this.model.map((m) => {
        return m.title;
      });
      let headerName = this.model.map((m) => {
        return m.name;
      });
      import("@/libs/Export2Excel").then((excel) => {
        const tHeader = headerTitle;
        const filterVal = headerName;
        const data = formatJson(filterVal, this.data);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: this.filename,
          autoWidth: true,
          bookType: "xlsx",
        });
      });

      this.downloadLoading = !true;
    },
  },
};
</script>
