<template>
  <div>
    <v-autocomplete
      outlined
      :clearable="!readonly"
      :disabled="disabled"
      :items="data"
      :value="value"
      :label="model.title"
      :error-messages="error"
      :hide-details="config.hideDetails || false"
      :dense="config.dense || false"
      :readonly="readonly"
      item-text="name"
      item-value="id"
      item-color="red"
      :item-disabled="checkRoom"
      @input="onInput($event)"
      :small-chips="model.chips || false"
      :multiple="model.chips || false"
      :deletable-chips="model.chips && !readonly"
      :hide-selected="model.chips || false"
      :return-object="model.chips || false"
    >
      <template v-slot:selection="data" v-if="model.chips || false">
        <v-chip
          v-bind="data.attrs"
          :input-value="data.selected"
          :close="config.readonly != true"
          :color="data.item.color"
          small
          @click="data.select"
          @click:close="remove(data.item)"
          class="mt-1"
        >
          {{ data.item.name }}
        </v-chip>
      </template>
      <template v-slot:item="data">
        <template>
          <v-list-item-title>{{ data.item.name }} [{{ data.item.class_name }}]</v-list-item-title>
        </template>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import modelOptions from "./../../libs/modelOptions";
export default {
  mixins: [modelOptions],
  props: {
    value: [Array, Number],
    values: {
      type: Object,
      default: () => {
        return {};
      },
    },
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    error: {
      type: String,
      default: "",
    },
    items: Array,
    config: { type: Object, default: {} },
  },
  data() {
    return {
      localData: null,
      valueField: "id",
    };
  },
  created() {},
  computed: {
    rooms_classes() {
      return this.getOptions({ options: "rooms_classes" }) || [];
    },

    room_class() {
      let res = false;
      if (this.value) res = this.value[0]?.room_class;
      return res;
    },
    data: {
      set(v) {},
      get() {
        let res = [];
        if (this.localData) return this.localData;
        if (this.items && Array.isArray(this.items)) {
          res = [...this.items];
        } else if (this.model.api && typeof this.model.api === "object") {
          let api = this.model.api;
          let type = api.type || "GET";
          let data = {};
          if (api.data) {
            data.name = api.data?.name;
            data.params = {};
            for (const key in api.data.params) {
              if (typeof api.data.params[key] === "string" && api.data.params[key].includes("function(d)")) {
                let func = eval(api.data.params[key]);
                data.params[key] = func(this.values) || 0;
              } else data.params[key] = api.data.params[key];
            }
          }

          //data = api.data || null;
          api = api.api;
          this.getDataFromProc(api, data, type);
        }
        /*     res.forEach((r) => {
          r.disabled = r.room_class !== this.room_class && this.room_class;
        }) */

        return res;
      },
    },
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
  },
  methods: {
    async getDataFromProc(api, data, type) {
      let response = type == "get" ? await this.$axios.get(api, { params }) : await this.$axios.post(api, data);
      this.loading = false;
      let res = response.data.data;
      res.forEach((r) => {
        const c = this.rooms_classes.find((f) => {
          return f.value == r.room_class;
        });
        r.color = c.color;
        r.class_name = c.text || null;
      });
      this.localData = res;
    },
    checkRoom(room) {
      return false;
      return room.room_class !== this.room_class && this.room_class;
    },

    remove(item) {
      if (this.readonly) return;
      const index = this.value.findIndex((el) => {
        return el.name == item.name;
      });
      if (index >= 0) this.value.splice(index, 1);
      this.$emit("input", this.value);
    },
    onInput(e) {
      this.$emit("input", e);
      let selected = this.data.find((d) => d?.[this.valueField] == e);
      this.$emit("selected", selected);
    },
  },
};
</script>
<style lang="scss"></style>
