/** @format */

const documentGoodsIncome = {
  title: 'Приход товаров',
  accessName: 'goodsIncome',
  routeName: 'goodsIncome_view',
  api: '/accounting/doc/goods_income',
  apiStatus: '/accounting/doc/goods_income',
  statuses: [
    { value: 1, text: 'не проведен', next: [2], action: 'Распровести', role: [1000] },
    { value: 2, text: 'проведен', next: [1], action: 'Провести' },
    { value: 4, text: 'проведен', next: [1], action: 'Провести4' },
    { value: 6, text: 'проведен', next: [1], action: 'Провести6' },
  ],

  list: [
    { name: 'id', title: 'id', type: 'number', sortable: true, width: 30, hidden: true },
    { name: 'code_doc', title: 'Номер', type: 'string', sortable: true, width: 50 },
    { name: 'date_doc', title: 'Дата', type: 'datetime', sortable: true, width: 120 },
    { name: 'status', title: 'Статус', type: 'select', sourceList: 'statuses' },
    { name: 'store_id', title: 'Склад', type: 'select', dir: 'storage', sortable: true, width: 150 },
    {
      name: 'parent_id',
      title: 'Принято по документу',
      type: 'namedoc',
      doc_name: { key: 'parent_name' },
      doc_id: { key: 'parent_id' },

      sortable: true,
      width: 150,
    },
    {
      name: 'createdby_id',
      title: 'Автор',
      type: 'select',
      dir: 'user',
      filter: { name: 'createdby_id', type: 'selectapi', api: '/manager/users/select' },
      sortable: true,
      width: 150,
    },
  ],

  form: [
    { name: 'date_doc', title: 'Дата документа', type: 'datetime' },
    { name: 'code_doc', title: 'Номер Документа', type: 'string' },
    { name: 'store_id', title: 'Склад', type: 'select', dir: 'storage', validator: ['req'], autocomplete: true },
    { name: 'status', title: 'Статус', validator: ['req'], default: 1 },
    { name: 'createdby_id', title: 'Автор', type: 'select', dir: 'user', filter: { name: 'createdby_id', type: 'selectapi', api: '/manager/users/select' } },
    { name: 'comments', title: 'Заметки', type: 'text', rows: 2 },
    { name: 'parent_name', title: 'parent_name' },
    {
      name: 'parent_id',
      title: 'Принято по документу',
      type: 'selectapi',
      itemField: { value: 'id', text: 'code_doc' },
      preText: 'Заказ товаров №',
      apiVar: {
        key: 'parent_name',
        apiName: [{ value: 'AccountingDocOrderGoodModel', name: 'accounting/doc/order_good' }],
      },
    },
  ],
  createForm: {
    fields: 'store_id,status,comments',
    fieldsRO: '',
    fieldsForm: 'store_id,status,comments',
  },
  viewForm1: {
    fields: 'code_doc,date_doc,parent_id,store_id',
    fieldsRO: '',
    fieldsForm: 'code_doc,date_doc,parent_id,store_id',
  },
  viewForm2: {
    fields: 'createdby_id,comments',
    fieldsRO: '',
    fieldsForm: 'createdby_id,comments',
  },
  editForm: {
    fields: 'code_doc,date_doc,store_id,status,parent_id,comments,parent_name',
    fieldsRO: '',
    fieldsForm: 'code_doc,date_doc,store_id,parent_id,status,comments',
  },
  form2: [
    { name: 'store_id', title: 'Куда', type: 'select', dir: 'storage', validator: ['req'], autocomplete: true },
    { name: 'status', title: 'Статус', validator: ['req'], default: 1 },
    { name: 'comments', title: 'Заметки', type: 'text', rows: 2 },
  ],
  listDetail: [
    { name: 'actions', width: 40 },
    { name: 'id', title: 'id', type: 'number', sortable: true, width: 30 },
    { name: 'good_id', type: 'number', hidden: true, sortable: true, width: 30 },
    { name: 'buying_id', type: 'number', hidden: !true, sortable: true, width: 30 },
    {
      name: '_jm_accounting_doc_order_goods_table_1_category_id',
      title: 'Категория',
      type: 'select',
      dir: 'category',
      typeEdit1: 'selectapi',
      validator: ['req'],
      sortable: true,
      width: 100,
    },
    { name: '_jm_accounting_doc_order_goods_table_1_name', title: 'Наименование', type: 'string', sortable: true, style: 'white-space: normal;', width: 300 },
    { name: 'amount', title: 'Кол-во', type: 'number', validator: ['req'], sortable: true, width: 50 },
    { name: '_jm_accounting_doc_order_goods_table_1_measure', title: 'ед.изм.', type: 'select', options: 'measure', width: 30 },
    { name: 'value', title: 'Стоимость', type: 'number', sortable: true, width: 100 },
    { name: 'comments', title: 'Заметки', type: 'text', style: 'white-space: normal;', width: 250 },
  ],
  formDetail: [
    { name: 'accounting_doc_order_goods_table_id', type: 'number', validator: ['req'], hidden: true },
    { name: 'amount', title: 'Кол-во', type: 'number', validator: ['req'], default: 1 },
    { name: 'value', title: 'Cтоимость', type: 'number', validator: ['req'], readonly: true },
    { name: 'comments', title: 'Заметки', type: 'text', rows: 2 },
  ],
};
export default documentGoodsIncome;
