/** @format */

const education = {
  chain: {
    title: '',
    isDocument: false,
    api: '/mechti/education/chain',
    routeName: 'education-admin',
    types: [
      { value: 1, text: 'Тема' },
      { value: 2, text: 'Ответ' },
    ],
    list: [
      { name: 'id', title: '#', type: 'id', sortable: true, width: 150 },
      { name: 'name', title: 'Наименование', type: 'string', sortable: true, width: 450 },
      { name: 'type', title: 'Тип', type: 'select', sourceList: 'types', sortable: !true },
      { name: 'is_parent', title: '', type: 'folder', sortable: !true, width: 40 },
      { name: 'actions', title: '', width: 40 },
    ],
    form: [
      { name: 'is_parent', title: 'Раздел', type: 'switcher', validator: ['req'], default: 0 },
      { name: 'name', title: 'Наименование', type: 'string', validator: ['req'] },
      { name: 'type', title: 'Тип', type: 'select', sourceList: 'types' },
      { name: 'status', title: 'Статус', validator: ['req'], type: 'number', default: 1 },
      { name: 'data.description', title: 'Описание', type: 'text' },
      { name: 'data.rules', title: 'Должен быть пройден урок...', type: 'string' },
      { name: 'parent_id', default: 0 },
      { name: 'accessRoles', title: 'для роли..', type: 'selectAccess2', accessType: 'role', validator1: ['req'], default: [] },
      { name: 'accessUsers', title: 'для пользователя..', type: 'selectAccess2', accessType: 'user', validator1: ['req'], default: [] },
    ],
    viewForm2: {
      fields: 'data.description',
    },
    viewForm1: {
      fields: 'name,accessRoles,accessUsers',
    },
    config: {
      watch: 'is_parent',
      default: { fields: 'is_parent,name,status,parent_id', fieldsForm: 'is_parent,name', fieldsRO: '' },
      0: {
        title: 'Урок',
        fields: 'is_parent,name,type,status,parent_id,accessRoles,accessUsers,data.description,data.rules',
        fieldsForm: 'is_parent,name,data.rules,accessRoles,accessUsers,data.description',
        fieldsRO: 'is_parent',
      },
      1: { title: 'Раздел', fields: 'is_parent,name,status,parent_id,accessRoles,accessUsers', fieldsForm: 'is_parent,name,accessRoles,accessUsers', fieldsRO: '' },
    },
  },
  message: {
    title: '',
    isDocument: false,
    api: '/mechti/education/message',
    types: [
      { value: 1, text: 'Текст' },
      { value: 2, text: 'Медиа' },
      { value: 3, text: 'Вопрос' },
      { value: 4, text: 'Тест' },
    ],
    side: [
      { value: 0, text: 'без автора' },
      { value: 1, text: 'Преподаватель' },
      { value: 2, text: 'Студент' },
    ],
    list: [
      { name: 'actions', title: '', width: 40 },
      { name: 'id', title: '#', type: 'id', sortable: true, width: 150 },
      { name: 'type', title: 'Тип', type: 'select', sourceList: 'types', sortable: !true },
      { name: 'content', title: 'Содержимое', type: 'string', sortable: true, width: 450 },
      { name: 'from_id', title: 'Предыдущий', type: 'number' },
      { name: 'next_id', title: 'Следующий', type: 'number' },
    ],
    form: [
      { name: 'type', title: 'Тип', type: 'select', sourceList: 'types' },
      { name: 'content', title: 'Содержимое', type: 'text', validator: ['req'] },
      { name: 'status', title: 'Статус', type: 'number', default: 1, validator: ['req'] },
      { name: 'education_chain_id', default: 0 },
      { name: 'next_id' },
      { name: 'from_id', type: 'number' },
      { name: 'images', title: 'Изображения', type: 'images', video: false },
      { name: 'data.buttons', type: 'education-message-buttons' },
      { name: 'data.question', type: 'education-message-question' },
      { name: 'data.testing', type: 'education-message-testing' },
      { name: 'data.gallery', type: 'education-message-gallery' },
      //{ name: 'content', title: 'Содержимое', type: 'ckeditor5', validator: ['req'], rows: -1 },

      { name: 'data.side', title: 'Сторона', type: 'select', sourceList: 'side', default: 1, validator: ['req'] },
    ],
    config: {
      watch: 'type',
      default: { fields: 'type,status,education_chain_id,content,from_id', fieldsForm: 'type', fieldsRO: '' },
      1: {
        title: 'Текст',
        fields: 'type,status,education_chain_id,content,next_id,from_id,data.buttons,from_id,data.side',
        fieldsForm: 'type#6,data.side#6,content',
        fieldsRO: '',
      },
      2: {
        title: 'Медиа',
        fields: 'name,type,status,education_chain_id,content,next_id,from_id,data.gallery,from_id,data.side,images',
        fieldsForm: 'type#6,data.side#6,content,images,data.gallery',
        fieldsRO: 'data.side',
        fieldsReplace: {
          content: { name: 'content', title: 'Содержимое', type: 'text', rows: 2 },
        },
      },
      3: {
        title: 'Вопрос',
        fields: 'name,type,status,education_chain_id,content,next_id,from_id,data.question,from_id,data.side',
        fieldsForm: 'type#6,data.side#6,content,data.question',
        fieldsRO: 'data.side',
        fieldsReplace: {
          content: { name: 'content', title: 'Содержимое', type: 'text', rows: 2 },
        },
      },
      4: {
        title: 'Тест',
        fields: 'name,type,status,education_chain_id,content,next_id,from_id,data.testing,from_id,data.side',
        fieldsForm: 'type#6,data.side#6,content,data.testing',
        fieldsRO: 'data.side',
        fieldsReplace: {
          content: { name: 'content', title: 'Содержимое', type: 'text', rows: 2 },
        },
      },
    },
  },
};
export default education;
