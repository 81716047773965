<template>
  <div>
    <v-card-text v-if="getType() == 1" class="pt-1 px-0">
      <v-card-subtitle class="py-1 text-left"> {{ getText(prop) }}<v-icon @click="$emit('onClick', prop.id)">mdi-pencil</v-icon> </v-card-subtitle>
      <v-textarea outlined auto-grow readonly hide-details="auto" rows="1" :value="JSON.parse(prop.data)"> </v-textarea>
    </v-card-text>
    <v-card-text v-else-if="getType() == 2" class="pt-1">
      <v-card-subtitle class="py-1 text-left"> {{ getText(prop) }} <v-icon @click="$emit('onClick', prop.id)">mdi-pencil</v-icon> </v-card-subtitle>
      <a-view-images
        :value="JSON.parse(prop.data)"
        @focus="focused_id = prop.id"
        @blur="focused_id = null"
        :append-icon="focused_id == prop.id ? 'mdi-pencil' : ''"
        @click:append="$emit('onClick', prop.id)"
      />
    </v-card-text>
    <v-card-text v-else-if="getType() == 3" class="pt-1">
      <v-card-subtitle class="py-1 text-left"> {{ getText(prop) }} <v-icon @click="$emit('onClick', prop.id)">mdi-pencil</v-icon> </v-card-subtitle>
      <div>
        <v-row>
          <v-simple-table style="width: 100%">
            <tbody>
              <tr v-for="(el, key) in JSON.parse(prop.data)" :key="key">
                <td class="text-left">{{ el.text }}</td>
                <td class="text-left">{{ el.value }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-row>
      </div>
    </v-card-text>
    <v-card-text v-else class="pt-1" @click="$emit('onClick', prop.id)"> Не известное свойство{{ prop }} </v-card-text>
    <v-divider />
  </div>
</template>

<script>
import { getForm, getAccess, genModel } from "@/components/mixings";
export default {
  mixins: [getForm, getAccess, genModel],
  props: {
    prop: Object,
  },
  data() {
    return {
      title: "",
      focused_id: null,
      loading: false,
      api: "mechti/object_props_value",
      model: [{ name: "object_props_id", title: "Свойство", type: "select", dir: "objectProps", itemField: { text: "name", value: "id" } }],
      editProps: { object_props_id: null, value: "", data: null },
      props_: [],
      modelProp: null,
      modelForm: null,
      propSelected: null,
      config: {
        1: {
          form: [
            { name: "object_id", validator: ["req", "min"], min: 0, default: this.object_id, hidden: true },
            { name: "object_type", validator: ["req"], hidden: true },
            { name: "value", title: "Вид описания", type: "select", validator: ["req"] },
            { name: "data", title: "Текст описания", type: "text", validator: ["req"] },
          ],
          title: "Текст",
          fields: "object_id,value,data",
          fieldsRO: "",
          fieldsForm: "value,data",
        },
      },
    };
  },
  computed: {
    modelProp1: {
      get() {
        console.log("calc modelprop");
        let model = this.calcModel(this.propSelected);

        return model;
      },
    },
    readonly() {
      return false;
    },

    props() {
      if (!this.props_) this.getProps();
      return this.props_;
    },
  },
  watch: {},
  created() {},
  methods: {
    getType() {
      let prop = this.prop;
      let type = prop.type;
      if (type == 4) {
        type = JSON.parse(prop.options).find((o) => o.value == prop.value).type;
      }
      return type;
    },
    getText(prop) {
      let options = JSON.parse(prop.options);
      let value;
      if (options) {
        value = options.find((x) => x.value == prop.value) || {};
      }
      return value?.text ? value.text : prop.value;
    },

    async getProps() {
      console.log("get props");
      let api = "mechti/object_props";
      let params = {};
      this.loading = true;
      let response = await this.$axios.get(api, { params });
      this.loading = false;
      this.props_ = response.data.data;
    },

    remove_(key) {
      let e = this.items;
      //e.splice(key, 1);
      e[key].type = "del";
      this.items = e;
    },
    add_() {
      this.editProps = { object_props_id: null, value: "", data: null };

      this.dialogProps = true;
    },
    save_() {},
    async upload() {
      this.fileLoading = true;
      this.dialogProps = false;
    },
    getValuesList(id) {
      let res =
        this.props.find((p) => {
          return p.id == id;
        })?.options || [];
      console.log("getValuesList", res);

      return res;
    },
    calcModel_(t) {
      //переназначить функцию, потому что немного отличается от того что в библиотеке genModel
      this.propSelected = null;
      if (!t) return;
      let type = this.props.find((el) => {
        return el.id == t;
      })?.type;
      if (!type) return;

      const config = this.config[type];
      let model = JSON.parse(JSON.stringify(config.form));
      model.forEach((el) => {
        if (el.name == "value") el.options = this.getValuesList(t);
      });
      console.log("config", config, type, model);
      const fields = config.fields ? config.fields.split(",") : [];
      let fieldsRO = config.fieldsRO ? config.fieldsRO.split(",") : [];
      if (this.data.status == 2) fieldsRO = fields;
      const fieldsForm = config.fieldsForm ? config.fieldsForm.split(",") : [];
      model = model.filter((el) => {
        return fields.includes(el.name);
      });
      model.forEach((el) => {
        if (fieldsRO.includes(el.name)) {
          el.readonly = true;
        }
      });
      let mForm = [];
      fieldsForm.forEach((field) => {
        let f = field.split("#");
        mForm.push({ name: f[0], size: f[1] || 12 });
      });

      this.modelForm = mForm;
      this.propSelected = t;
      this.fillFormFromModel(model);
      if (t == this.config?.type) {
        for (const name in this.config) {
          this.$set(this.data, name, this.config[name]);
        }
      }
      this.modelProp = model;
      return model;
    },
    afterSave() {
      this.$emit("input");
    },
    async beforeSave() {
      console.log("before save");
      return true;
    },
  },
};
</script>
