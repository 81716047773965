<template>
  <div class="py-1 pb-3">
    <v-card>
      <v-card-title class="py-2">
        {{ model.title }}
        <v-spacer></v-spacer>
        <div class="text-right pa-1">
          <v-btn v-if="!readonly" class="ma-1" @click="add()" color="primary"> Добавить </v-btn>
        </div>
      </v-card-title>
      <v-card-text :style="`height: ${detailHeight}px; overflow: auto`">
        <a-table-f-data2
          ref="tableData"
          :dataTable="data"
          :useQuery="false"
          :model="m_table.form"
          :searchable="false"
          :defaults="{
            filters: {},
            sort: { key: 'id', order: 'DESC' },
          }"
          @click="onClickRow($event)"
        >
          <template v-slot:top> </template>
          <template v-slot:item.actions="{ row }">
            <div class="d-flex">
              <v-btn x-small fab class="ma-0 ml-0" color="red" title="Отменить" @click.stop="remove(row)">
                <v-icon small>fas fa-times</v-icon>
              </v-btn>
            </div>
          </template>
        </a-table-f-data2>
      </v-card-text>
    </v-card>
    <s-edit-form-data
      v-model="showEditDialog"
      v-if="showEditDialog"
      :dataTable="value"
      :m="configH.m"
      :configForm="{ head: configH.head, title: configH.title, api: configH.api }"
      :id="configH.id"
      :opt="configH.opt"
      @save="editRows($event)"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: Array,
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },

    config: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      showEditDialog: false,
      configH: null,
    };
  },
  computed: {
    data: {
      get() {
        return this.value || [];
      },
      set(v) {
        this.$emit("input", v);
      },
    },
    detailHeight() {
      return 300;
    },
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
    m_table() {
      let model;
      if (this.model.modelConfig) {
        model = JSON.parse(JSON.stringify(this.$store.getters["config/get"].models));
        const path = this.model.modelConfig.split(".");
        for (let i = 0; i < path.length; i++) {
          const el = path[i];
          model = JSON.parse(JSON.stringify(model?.[el] || {}));
        }
      } else {
        model = {};
        model.list = [...(this.model?.model?.form || [])];
        model.form = [...(this.model?.model?.form || [])];
        model.title = this.model?.model?.title;
        const fields = model.form
          .map((m) => {
            return m.name;
          })
          .join(",");
        model.config = {
          default: {
            fields: fields,
            fieldsRO: "",
            fieldsForm: this.model?.model?.fieldsForm || fields,
          },
        };
      }
      if (!model.form.find((m) => m.name === "actions")) model.form.push({ name: "actions", width: 40 });
      return model;
    },
  },
  methods: {
    add(id = null) {
      const config = {};
      this.configH = null;
      config.head = this.m_table.config.default || {};
      config.id = id;
      config.api = null;
      config.opt = "delete";
      config.m = this.m_table;
      this.configH = config;
      this.showEditDialog = true;
    },
    remove(r) {
      let id = r["_id"];
      let key = this.data.findIndex((d) => d["_id"] === id);
      let e = this.data;
      e.splice(key, 1);
      this.data = e;
    },
    onClickRow(e) {
      if (e.field.name == "actions") {
      } else {
        this.add(e.key);
      }
    },
    async editRows(r) {
      if (!r) r = this.data;
      r.forEach((rr, idx) => {
        rr["_id"] = idx;
      });
      this.data = [...r];
    },
  },
};
</script>
