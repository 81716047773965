<template>
  <div class="d-flex" style="position: relative">
    <v-autocomplete
      outlined
      :clearable="!readonly"
      :readonly="readonly"
      hide-selected
      :items="data"
      :value="parseInt(value)"
      :label="model.title"
      :error-messages="error"
      item-text="name"
      item-value="id"
      :disabled="disabled"
      :hide-details="config.hideDetails || false"
      :dense="config.dense || false"
      @input="
        $emit('input', $event);
        $emit('commit', getItem($event));
      "
    >
    </v-autocomplete>
  </div>
</template>

<script>
import modelOptions from "../../libs/modelOptions";

export default {
  mixins: [modelOptions],
  props: {
    value: [String, Number, Object],
    values: Object,
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    error: {
      type: String,
      default: "",
    },
    config: { type: Object, default: {} },
  },
  data() {
    return {
      data: [],
      api: "accounting/dir/service",
      title: null,
      category: { Дизайнер: 27, Стройка: 28, Мебель: 29, Комплектация: 55 },
    };
  },
  created() {
    this.fetchData();
  },

  computed: {
    titleValue() {
      return this.title || this.model.title;
    },
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
  },
  watch: {
    "values.department"(v) {
      this.fetchData();
    },
    value(v) {
      //  if (v) this.fetchData();
    },
  },
  methods: {
    getApi(model) {
      return this.api;
    },
    getItem(e) {
      return this.data.find((el) => el.id == e);
    },

    async fetchData() {
      this.data = [];
      if (!this.values.department) return;

      let api = await this.getApi(this.model);
      let category_id = this.category?.[this.values.department] || 0;
      if (api) {
        let params = {};
        //params.sort = { name: "ASC" };

        params.filters = {
          category_id,
          OR: [
            { field: "status", condition: "<>", value: 2 },
            { field: "status", condition: "IS", value: null },
          ],
        };
        this.loading = true;
        let response = await this.$axios.get(api, { params });
        this.loading = false;
        let data = response.data.data;
        this.data = data;
        if (!data.find((el) => el.id == this.value)) this.$emit("input", null);
      }
    },
  },
};
</script>
<style lang="scss"></style>
