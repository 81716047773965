/** @format */

export default {
  props: {},
  data() {
    return {
      needUpdateComments: 0,
    };
  },
  watch: {
    'data.status'() {
      this.needUpdateComments++;
    },
  },
  methods: {},
};
