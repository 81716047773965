<template>
  <v-dialog ref="dialog" v-model="showPicker" persistent width="290px">
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <slot> {{ valueDisplay }} </slot>
      </div>
    </template>
    <v-date-picker v-if="showPicker" v-model="dates" full-width range :first-day-of-week="1">
      <v-btn @click="onClear()" v-if="1" :title="$t(`clear`)"> <v-icon>fas fa-trash-alt </v-icon></v-btn>
      <v-spacer></v-spacer>
      <v-btn @click="showPicker = false" title="Закрыть"><v-icon>fas fa-times </v-icon></v-btn>
      <v-btn color="primary" @click="onSave()">Ok</v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: String,
    datesV: String,
  },
  data() {
    return {
      showPicker: false,
      start: null,
      end: null,
    };
  },
  watch: {
    showPicker(v) {
      if (v) {
      }
    },
  },
  created() {},
  methods: {
    onSave() {
      this.showPicker = false;
      this.$emit("input", [this.start, this.end || this.start].join(" - "));
    },
    onClear() {
      this.showPicker = false;
      this.$emit("input", "");
    },
  },
  computed: {
    dates: {
      get: function () {
        if (!this.start) this.start = new Date().toISOString().slice(0, 10);
        if (this.end) return [this.start, this.end];
        return [this.start];
      },
      set: function (v) {
        v.sort();

        this.start = v[0];
        this.end = v[1];
      },
    },
    valueDisplay() {
      if (!this.showPicker) {
        let dates;
        if (!Array.isArray(this.value)) {
          dates = this.value.split(" - ");
        } else {
          dates = this.value;
        }
        //закоментировал, что бы при пустом значении показывало что ничего нет, а не подставляло текущюю дату
        //  if (!dates[0]) dates[0] = new Date().date;
        // if (!dates[1]) dates[1] = new Date().date;
        this.start = dates[0];
        this.end = dates[1];
      }
      let res = [
        this.start ? new Date(this.start).toLocaleDateString("ru-RU") : "не задано",
        this.end ? new Date(this.end).toLocaleDateString("ru-RU") : "не задано",
      ].join(" - ");
      return res;
    },
  },
};
</script>

<style lang="scss">
.a-filter-input-block {
  width: 100;
  border: 1px solid;
}
</style>
