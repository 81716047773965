/** @format */

const documentExpenses = {
  title: 'Распределение расходов',
  routeName: 'expensesDistribution_view',
  accessName: 'expensesDistribution',
  types: [
    { value: 1, text: 'по стоимости объектов' },
    { value: 2, text: 'по количеству объектов' },
  ],
  statuses: [
    { value: 1, text: 'не проведен', next: [2], action: 'Распровести' },
    { value: 2, text: 'проведен', next: [1], action: 'Провести' },
  ],
  list: [
    { name: 'id', title: 'id', type: 'number', sortable: true, width: 30, hidden: true },
    { name: 'code_doc', title: 'Номер', type: 'string', sortable: true, width: 50 },
    { name: 'date_doc', title: 'Дата', type: 'datetime', sortable: true, width: 120 },
    { name: 'city', title: 'Город', type: 'string', filter: { name: 'city', type: 'select', options: 'city' }, sortable: true, width: 150 },
    { name: 'period_start', title: 'Начало', type: 'datetime', sortable: true, width: 120 },
    { name: 'period_end', title: 'Конец', type: 'datetime', sortable: true, width: 120 },
    { name: 'distribution_type', title: 'Тип', type: 'select', sourceList: 'types', sortable: true, width: 120 },
    { name: 'createdby_id', title: 'Автор', type: 'select', dir: 'user', filter: { type: 'select', name: 'createdby_id', dir: 'user' }, sortable: true, width: 150 },
    { name: 'status', title: 'Статус', type: 'select', sourceList: 'statuses', sortable: true, width: 150 },
    { name: 'total_price', title: 'Сумма', type: 'number', sortable: true, width: 80 },
  ],

  viewForm1: {
    fields: 'code_doc,date_doc,createdby_id,distribution_type,city',
  },
  viewForm2: {
    fields: 'period_start,period_end',
  },
  form: [
    { name: 'code_doc', title: 'Номер Документа', type: 'string', roleShow: [1000], hiddenCreate: true },
    { name: 'date_doc', title: 'Дата документа', type: 'datetime', hiddenCreate: true },
    { name: 'distribution_type', title: 'Тип распределения', type: 'select', sourceList: 'types', validator: ['req'] },
    { name: 'city', title: 'Город', type: 'select', options: 'city', autocomplete: true },
    { name: 'period_start', title: 'Начало', type: 'datestring', validator: ['req'] },
    { name: 'period_end', title: 'Конец', type: 'datestring', validator: ['req'] },
    { name: 'status', title: 'Статус', validator: ['req'], default: 1, hidden: true },
    { name: 'createdby_id', title: 'Автор', type: 'select', dir: 'user' },
  ],
  config: {
    default: {
      fields: 'period_start,period_end,city,distribution_type,status',
      fieldsRO: '',
      fieldsForm: 'period_start,period_end,city,distribution_type',
    },
  },
  listObjects: [
    { name: 'actions', width: 40, statusHide: 2 },
    { name: 'id', title: 'id', type: 'number', sortable: true, width: 55 },
    { name: 'object_id', title: 'Объект', type: 'select', dir: 'object', style: 'white-space: normal;', sortable: true, width: 150 },
    { name: 'percent', title: '%', type: 'number', sortable: true, width: 50 },
    { name: 'amount', title: 'Сумма', type: 'number', sortable: true, width: 120 },
    { name: 'comments', title: 'Коментарий', type: 'text', sortable: true, width: 200 },
  ],
  formObjects: [
    { name: 'object_id', title: 'Объект', type: 'select', dir: 'object', hidden: true },
    { name: 'name', title: 'Наименование', type: 'string', validator: ['req'] },
    { name: 'amount', title: 'Кол-во', type: 'number', validator: ['req'], default: 1 },
    { name: 'amount_real', title: 'Кол-во факт', type: 'number', hidden: true },
    { name: 'measure', title: 'ед.измер.', type: 'select', options: 'measure', validator: ['req'] },
    { name: 'price_plan', title: 'Плановая стоимость', type: 'number', validator: ['req'] },
    { name: 'price_unit', title: 'Цена за ед.', type: 'number' },
    { name: 'price_real', title: 'Фактическая стоимость', type: 'number', validator: ['req'] },
    { name: 'date_before', title: 'Дата доставки', type: 'date' },
    { name: 'status', validator: ['req'], default: 0 },
  ],
  listExpenses: [
    { name: 'actions', width: 40, statusHide: 2 },
    { name: 'id', title: 'id', type: 'number', sortable: true, width: 55 },
    { name: 'amount', title: 'Сумма', type: 'number', sortable: true, width: 80 },
    { name: 'base_doc_name', title: 'Документ', type: 'string', width: 150, style: 'white-space: normal;', isMenu: true, sortable: true },
    { name: 'subconto_value_1_name', title: 'субконто 1', type: 'string', width: 150, style: 'white-space: normal;', sortable: true },
    { name: 'subconto_value_2_name', title: 'субконто 2', type: 'string', width: 150, style: 'white-space: normal;', sortable: true },
    { name: 'subconto_value_3_name', title: 'субконто 3', type: 'string', width: 150, style: 'white-space: normal;', sortable: true },
  ],
  formExpenses: [
    {
      name: 'service_id',
      title: 'Наименование',
      type: 'selectapi',
      api: 'accounting/dir/service/select',
      filters: { category_id: 30, id: { condition: `<>`, value: 33 } },
      dir1: 'service',
      dirFilter1: { value: [29] },
      validator: ['req'],
    },
    { name: 'value', title: 'Стоимость', type: 'number', validator: ['req'] },
  ],
};
export default documentExpenses;
