<template>
  <div>
    <dialog-edit2 :value="value" @input="$emit('input')" :maxWidth1="'500px'">
      <template v-slot:title> Проводки </template>
      <v-card-text style="height: 300px; overflow: auto" class="pa-0">
        <a-table-f-sql ref="table" :query="sql" :model="balanceModel" :useQuery="false" :defaults="{ paramName1: 'goods.goodsMoving', sort: {} }" />
      </v-card-text>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit2>
  </div>
</template>
<script>
import { getAccess } from "@/components/mixings";
export default {
  mixins: [getAccess],

  components: {},
  props: {
    value: Boolean,
    //1-данные приходят извне, 2-необходимо считать внутри
    type: { type: Number, default: 1 },
    data: Array,
    document: Object,
    model: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      title: undefined,
      message: undefined, // Main text content
      data: {},
      show: false,
    };
  },
  computed: {
    balanceData() {
      if (this.type == 1) return this.data || [];
      if (!this.balanceTable.data) this.getBalanceTable();
      return this.balanceTable.data;
    },
    balanceModel() {
      if (this.type == 1) return this.model || [];
      return [
        { name: "id", title: "id", type: "number", width: 30 },
        { name: "account", title: "счет", type: "string", width: 50 },
        { name: "date", title: "Дата", type: "datetime", width: 120 },
        { name: "operation_id", title: "Операция", type: "select", dir: "operationTable", width: 200 },

        { name: "value_cr", title: "Приход", type: "number", width: 70 },
        { name: "value_db", title: "Расход", type: "number", width: 70 },
        {
          name: "name1",
          title: "",
          type: "select",
          dirVar: {
            key: "account",
            dirName: [
              { value: "76.1", name: "investor" },
              { value: "08.1", name: "object" },
              { value: "08.2", name: "object" },
            ],
          },
          width: 200,
        },
        {
          name: "name2",
          title: "",
          type: "select",
          dirVar: {
            key: "account",
            dirName: [
              { value: "76.1", name: "object" },
              { value: "08.1", name: "category" },
              { value: "08.2", name: "category" },
            ],
          },
          width: 200,
        },
        {
          name: "name3",
          title: "",
          type: "select",
          dirVar: {
            key: "account",
            dirName: [
              { value: "76.1", name: null },
              { value: "08.1", name: "good" },
              { value: "08.2", name: "service" },
            ],
          },
          width: 200,
        },
      ];
    },
  },
  created() {
    this.getData();
  },
  destroyed() {},
  watch: {
    value(v) {
      this.show = v === true;
    },
  },
  methods: {
    async getData() {
      let sql = `
select id, credit_account 'account',
  date_doc 'date',
  operation_id,
	credit_subconto_value_1 'name1',
	credit_subconto_value_2 'name2',
	credit_subconto_value_3 'name3',
	value 'value_cr', null 'value_db'
from accounting_record
where credit_account = "76.1" 
	and (credit_subconto_value_2=${this.id}) 
	union all
select id, debit_account 'account',
  date_doc 'date', 
  operation_id,
	debit_subconto_value_1 'name1',
	debit_subconto_value_2 'name2',
	debit_subconto_value_3 'name3',
	null 'value_cr', value 'value_db'
from accounting_record
where debit_account like "08%"
	and debit_subconto_value_1=${this.id}`;
      this.sql = { sql, order: "if(date_before IS null,1,-1), date_before , date_doc, id" };
    },
  },
};
</script>

<style scoped></style>
