<template>
  <div>
    <v-dialog v-model="showAlert" persistent :max-width="started ? 700 : 450">
      <v-card v-if="!started" class="pa-3">
        <v-card-title class="text-h5 text-center pt-1"> Система улучшения знаний</v-card-title>
        <v-card-text>
          <span> Настало время для небольшого тестирования. </span>
          <p>Количество вопросов: {{ items.length }}.</p>
          <p>Пожалуйста, уделите несколько минут вашего времени.</p>
        </v-card-text>
        <v-card-actions>
          <v-btn color="green darken-1" text @click="start()"> Приступить</v-btn>
          <v-spacer></v-spacer>
          <v-btn :disabled="!!interval" color="red darken-1" text @click="postpone(1)"> {{ interval ? interval : "Позже" }} </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else-if="numb" class="pa-3">
        <v-card-title class="text-h5 text-center pt-1"> База знаний</v-card-title>
        <s-tests-question v-if="loaded" :q="question" full @showInfo="showInfo = true" @onAnswer="onAnswer($event)" />
        <v-progress-circular v-else :size="70" :width="7" indeterminate color="primary"></v-progress-circular>
        <span v-if="showError" class="ma-3" style="color: red"> Вы ошиблиcь. Пожалуйста пересмотрите инструкцию</span>
        <v-card-actions class="mt-8">
          <v-btn :disabled="numb < 2" color="primary" text @click="numb--"> Предыдующий</v-btn>
          <v-btn :disabled="numb > items.length - 1" color="primary" text @click="numb++"> Следующий</v-btn>
          <v-spacer></v-spacer>
          <v-btn :disabled="!!interval" color="red darken-1" text @click="postpone(1)"> {{ interval ? interval : "Позже" }} </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else class="pa-3">
        <v-card-title class="text-h5 text-center pt-1"> Система улучшения знаний</v-card-title>
        <v-card-text>
          <span> Спасибо что выделили время. </span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="!!interval" color="red darken-1" text @click="postpone(0)"> Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Info v-if="showInfo" v-model="showInfo" :id="question.id" old />
    <confirm-dialog ref="confirmDialog"></confirm-dialog>
  </div>
</template>

<script>
import { submitForm } from "@/components/mixings";
export default {
  components: {
    Info: () => import("@/views/dialogs/infoViewDialog"),
  },
  data() {
    return {
      showAlert: false,
      showInfo: false,
      checkInterval: null,
      items: [],
      numb: 0,
      timer: null,
      postoneCount: 0,
      interval: 0,
      started: false,
      question: {},
      done: [],
      bilets: [],
      showError: true,

      QB_list: {},
      loaded: false,
    };
  },
  created() {
    this.checkInterval = setInterval(async () => {
      this.check();
    }, 15 * 60 * 1000);
    this.check();
  },
  computed: {},
  watch: {
    numb(v) {
      this.loaded = false;
      this.getQuestion();
    },
  },
  methods: {
    reset() {
      this.items = {};
      this.numb = 0;
      this.started = false;
      this.question = {};
      this.postoneCount = 0;
      this.done = [];
      this.QB_list = {};
      this.loaded = false;
      this.bilets = [];
      this.showError = !true;
    },
    delay(n) {
      this.interval = n;
      this.timer = setInterval(() => {
        if (this.interval) {
          this.interval--;
        } else clearTimeout(this.timer);
      }, 1000);
    },
    async postpone(type = 1) {
      let ok = false;
      let p = this.$root.interface?.testPostoneCount;
      if (type == 1) {
        if (p <= this.postoneCount) {
          ok = await this.$refs.confirmDialog.show({
            title: "Предупреждение",
            message: [
              `Откладывать можно не более ${p} раз${[2, 3, 4].includes(p) ? "а" : ""}.`,
              `Вы откладывали  ${this.postoneCount} раз${[2, 3, 4].includes(this.postoneCount) ? "а" : ""}.`,
              "Откладывать больше нельзя.",
            ],
            cancelButton: "Понятно",
          });
          return;
        }
        ok = await this.$refs.confirmDialog.show({
          title: "Предупреждение",
          message: [
            `Откладывать можно не более ${p} раз${[2, 3, 4].includes(p) ? "а" : ""}.`,
            `Вы откладывали  ${this.postoneCount} раз${[2, 3, 4].includes(this.postoneCount) ? "а" : ""}.`,
            "Вы хотите отложить?",
          ],
          okButton: "Да",
          cancelButton: "Нет",
        });
        if (!ok) return;
      }

      this.bilets.forEach((q) => {
        let biletPostoneCount = this.$store.getters["config/getLocalParams"]?.["testing.postone.count." + q.id] || 0;
        console.log("postone", "testing.postone.count." + q.id, biletPostoneCount);
        this.$store.commit("config/setLocalParams", {
          name: "testing.postone.count." + q.id,
          value: biletPostoneCount + 1,
        });
      });

      this.checkInterval = setInterval(async () => {
        this.check();
      }, 15 * 60 * 1000);
      this.showAlert = false;
    },
    start() {
      this.numb = 1;
      this.started = true;
    },
    close() {
      this.showAlert = false;
    },
    next() {
      let next;

      for (let i = this.numb; i <= this.items.length; i++) {
        if (!this.done.includes(i)) {
          next = i;
        }
      }
      if (!next)
        for (let i = 1; i <= this.items.length; i++) {
          if (!this.done.includes(i)) {
            next = i;
          }
        }
      this.numb = next || 0;
    },
    onAnswer(a) {
      this.done = [...this.done, this.numb];
      if (a.result) {
        const bilet_id = this.QB_list?.[this.question.q_id] || [];
        for (let i = 0; i < bilet_id.length; i++) {
          this.post({ test_id: bilet_id[i], question_number: this.question.q_id, status: a.result }, "/mechti/information_test_result", true);
        }
        this.next();
      } else {
        this.showError = true;
      }
    },
    async getQuestion() {
      if (!this.numb) return;
      let q = this.items[this.numb - 1];
      let id = q.split("#")[0];
      let numb = q.split("#")[1];

      let resp = await this.$axios.get("/mechti/information/" + id);
      let info = resp.data.data;
      // let res = { id: info.id, q_id: `${info.id}#${numb}`, t: info.name, q: info.test_data[numb].text };

      this.question = { id: info.id, q_id: `${info.id}#${numb}`, t: info.name, question: info.test_data[numb], done: this.done.includes(this.numb) };
      this.loaded = true;
    },
    async check() {
      if (this.showAlert) return;
      this.reset();
      //getInformationTests
      const user_id = this.$root.profile.id;
      const role_id = this.$root.profile.role;
      const params = { status: 1, user_id, role_id };
      let response = await this.$axios.post("/report_sql", { name: "getInformationTests", params });
      const test_id = response.data.data.map((t) => {
        return t.id;
      });
      let test_results = await this.$axios.get("/mechti/information_test_result", { params: { filters: { test_id, status: 1, user_id } } });
      test_results = test_results.data.data;
      let list = [];
      for (let i = 0; i < response.data.data.length; i++) {
        const bilet = response.data.data[i];
        let biletPostoneCount = this.$store.getters["config/getLocalParams"]?.["testing.postone.count." + bilet.id] || 0;
        if (this.postoneCount < biletPostoneCount) this.postoneCount = biletPostoneCount;
        const q = JSON.parse(bilet.information_data || "[]") || []; 
        for (let ii = 0; ii < q.length; ii++) {
          const el = q[ii];
          if (
            test_results.findIndex((r) => {
              return r.test_id == bilet.id && r.question_number == el && r.status;
            }) == -1
          ) {
            this.QB_list[el] = [...(this.QB_list?.[el] || []), bilet.id];
            if (!this.bilets.includes(bilet)) this.bilets = [...this.bilets, bilet];
            if (!list.includes(el)) list.push(el);
          }
        }
      }
      this.items = list;
      if (list.length) {
        this.showAlert = !false;
        if (this.checkInterval) clearInterval(this.checkInterval);
      }
    },
    async post(data, api, silent = false) {
      this.loading = true;
      try {
        let response = await this.$axios.post(api, data);
        this.loading = false;
        let status = response.data.status == "ok";
        if (status) {
          if (!silent)
            this.$root.$emit("show-info", {
              text: "Данные записаны",
            });
        }
        //  this.afterSave(data, status);
      } catch (error) {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Ошибка при сохранении",
        });
        this.loading = false;
        return;
      }
    },
  },
};
</script>
<style lang="sass">
.text-center
  justify-content: center
</style>
