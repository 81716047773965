var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loaded)?_c('a-table-f-api',{ref:"tableAccounting",attrs:{"api":'/accounting/records',"model":_vm.getModelList(_vm.$store.getters['config/get'].models.accRecords),"searchable":false,"useQuery":false,"defaults":{
    sort: { key: 'id', order: 'DESC' },
    filters: {
      OR: [{ base_name: _vm.name, base_id: _vm.id }, ..._vm.orWhere, ..._vm.joinDoc],
    },
    paramName: 'accRecords',
  }}}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }