/** @format */

const cashBook = {
  title: 'Кассовая книга',
  accessName: 'cashBook',
  api: '/accounting/report/orders',
  statuses: [
    { value: 1, text: 'не проведен', next: [2], action: 'Распровести' },
    { value: 2, text: 'проведен', next: [1], action: 'Провести' },
  ],
  list: [
    { name: 'id', title: 'id', type: 'number', sortable: true, width: 30, hidden: true },
    { name: 'code_doc', title: 'Номер', type: 'string', sortable: true, width: 50 },
    { name: 'date_doc', title: 'Дата', type: 'datetime', sortable: true, width: 120 },
    { name: 'status', title: 'Статус', type: 'select', sourceList: 'statuses', width: 70, sortable: true },
    { name: 'operation_type', title: 'Операция', type: 'select', dir: 'operationTable', style: 'white-space: normal;', sortable: true },
    { name: 'value_type', title: 'Тип', type: 'string', sortable: false, formula: '(d)=>{return d.model=="ПКО" ? "Приход" : "Расход" }' },
    { name: 'value', title: 'Сумма', type: 'number', sortable: true },
    { name: 'value_in', title: 'Приход', type: 'number', sortable: false, formula: '(d)=>{return d.model=="ПКО" ? d.value : null }', hidden: true },
    { name: 'value_out', title: 'Расход', type: 'number', sortable: false, formula: '(d)=>{return d.model=="РКО" ? d.value : null }', hidden: true },
    { name: 'vendor_id', title: 'Поставщик', type: 'select', dir: 'vendor', sortable: true },
    { name: 'object_id', title: 'Объект', type: 'select', dir: 'object', sortable: true },
    { name: 'cashbox_id', title: 'Касса', type: 'select', dir: 'cashbox' },
    { name: 'employee_id', title: 'Подотчет', type: 'select', dir: 'employee' },
    {
      name: 'createdby_id',
      title: 'Автор',
      type: 'select',
      dir: 'user',
      filter: { name: 'createdby_id', type: 'selectapi', api: '/manager/users/select' },
      sortable: true,
      width: 150,
    },
    {
      name: 'parent_id',
      title: 'Документ для оплаты',
      type: 'namedoc',
      doc_name: { key: 'parent_name' },
      doc_id: { key: 'parent_id' },
      style: 'white-space: normal;',
      hidden: true,
    },
    { name: 'parent_doc_name', title: 'Документ для оплаты', type: 'string', sortable: true, width: 120 },
    { name: 'count_id', title: 'Показано', hidden: true },
    { name: 'sum_value', title: 'Сумма', hidden: true },
  ],
};
export default cashBook;
